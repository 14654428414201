import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Days from "./Days/Days"
import Timeslots from "./Timeslots/Timeslots"
import { Translation } from 'react-i18next';
import * as actionCreators from "../../../redux/store/actions/index"
import { connect } from "react-redux"
import { ReactComponent as DeliveryOption } from '../../../images/delivery-option.svg';
import { withRouter } from "react-router-dom"
import { storesService } from "../../../services/basis/StoresService";

import "../Checkout.scss"

class Disponibility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      enabledDeliveryRider: false,
      atHomeFees: null,
      riderFees: null
    };
  }

  componentDidMount() {
    const { storeId, cart } = this.props;
    storesService.storeDetails(storeId)
      .then(response => {
        console.log(response);
        this.setState({
          enabledDeliveryRider: response.data.enabledDeliveryRider,
          selected: (response.data.enabledDeliveryRider &&
            (cart?.deliveryMode === "AT_HOME" || cart?.deliveryMode === "RIDER")) ? false : true
        })
      }).catch(error => {
        console.log(error)
      })
    storesService.storeFees(storeId)
      .then(response => {
        if (response && response.length > 0) {
          response.forEach(fee => {
            if (fee.code === "AT_HOME") {
              this.setState({ atHomeFees: fee.value })
            }
            if (fee.code === "RIDER") {
              this.setState({ riderFees: fee.value })
            }
          });
        }
        this.setState({ storeFees: response })
      }).catch(error => {
        console.log(error)
      })
  }

  cartUpdate = (deliveryMode) => {
    const { cart } = this.props;
    this.props.updateCart(deliveryMode, cart.id);
    this.setState({ selected: true })
  }

  changePage = () => {
    this.props.saveCheckoutInfo({
      day: this.props.day,
      timeSlotId: this.props.timeSlotId
    })
    this.props.history.push("/checkout/data");
  }

  render() {
    const { cart, day, selectTimeslot, timeSlotId, serviceTimeSlotId, deliveryMode, storeId } = this.props;
    const { selected, enabledDeliveryRider, atHomeFees, riderFees } = this.state;
    return (
      <Translation>
        {t =>
          <>
            {cart && (cart.deliveryMode === "AT_HOME" || cart.deliveryMode === "RIDER") && enabledDeliveryRider &&
              <>
                <div className="container p-0 mt-1 d-flex flex-cloumn justify-content-center choose-delivery">
                  Seleziona la modalità di consegna:
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <div className="Rectangle">
                    <input
                      onClick={() => this.cartUpdate("AT_HOME")}
                      type="radio"
                      value="Consegna standard"
                      name="deliveryOptions"
                    />
                    <span className="Consegna-standard">
                      Consegna-standard
                    </span>
                    <p>Costo di {atHomeFees} €</p>
                  </div>
                  <div className="Rectangle">
                    <input
                      onClick={() => this.cartUpdate("RIDER")}
                      type="radio"
                      value="Consegna con rider"
                      name="deliveryOptions"
                    />
                    <span className="Consegna-standard">
                      Consegna con rider
                  </span>
                    <p>Costo extra di {riderFees} €</p>
                  </div>
                </div>
              </>
            }
            {selected &&
              <div className="mt-3 mb-5">
                <div className="container-fluid">
                  <Col className="text-center disponibilityInfo">
                    <div className="Selezione-il-giorno mb-4">
                      Seleziona il giorno e la fascia oraria per la consegna:
                    </div>
                    {/*<span>{t("disponibility.title")}</span>*/}
                  </Col>
                </div>
                <div className="container-fluid">
                  <Row className="justify-content-center">
                    <Days
                      selectDay={this.props.selectDay}
                      deliveryMode={deliveryMode}
                      storeId={cart ? cart.storeId : storeId}
                      serviceTimeSlotId={serviceTimeSlotId}
                    />
                  </Row>
                </div>
                {day &&
                  <Timeslots
                    day={day}
                    selectTimeslot={selectTimeslot}
                    deliveryMode={deliveryMode}
                    storeId={cart ? cart.storeId : storeId}
                  />
                }
                <div className="justify-content-center d-flex">
                  <button style={{ marginBottom: "unset" }}
                    onClick={this.changePage}
                    className={`cta ${day && timeSlotId ? "" : "custom-button-disabled"} `}>
                    <span>{t("next")}</span>
                  </button>
                </div>
              </div>
            }
            {!selected &&
              <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                <div>
                  <DeliveryOption />
                </div>
                <div className="Per-visulaizzare-le">
                  <span>Per visualizzare le fascia orarie disponibili seleziona la modalità di consegna</span>
                </div>
                <div>
                  <button className="Rectangle-Copy-2"
                    onClick={this.goToCheckout}>
                    <span className="PROSEGUI">
                      PROSEGUI
                      </span>
                  </button>
                </div>
              </div>
            }
          </>
        }
      </Translation>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveCheckoutInfo: (data) => dispatch(actionCreators.saveCheckoutInfo(data)),
    updateCart: (deliveryMode, cartId) => dispatch(actionCreators.updateCart(deliveryMode, cartId)),
  };
}
const mapStateToProps = state => {
  return {
    storeId: state.user.storeId,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Disponibility));