import React from "react";
import HomeCarousel from "../Home/HomeCarousel/HomeCarousel"
import FeaturedProducts from "./FeaturedProducts/FeaturedProducts";
import CategorySlider from "../Categories/CategorySlider";
import AssistedPopup from "../../components/UI/Popup/AssistedPopup/AssistedPopup";
import config from "../../config/config";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"
import { storesService } from "../../services/basis/StoresService";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aidedFee: null
        };
    }

    componentDidMount() {
        if (this.props.user) {
            this.props.current();
            if (config.ENABLED_SLIST) {
                this.props.getSlists();
                this.props.getFavorites();
            }
        }
        storesService.aidedShopFees(this.props.storeId)
            .then(response => {
                console.log(response);
                this.setState({
                    aidedFee: response.value
                })
            }).catch(error => {
                console.log(error);
            })
    }

    popupAction = () => {
        this.props.fullAidedShop();
        this.props.history.push("/checkout/create")
    }

    render() {
        const { user } = this.props;
        const { aidedFee } = this.state;
        const enabledAidedShop = this.props?.location?.state?.enabledAidedShop
        return (
            <div className="mobile-view-search">
                <HomeCarousel />
                <CategorySlider />
                <div className="search-stores-products">
                    <div className="text-center">
                        <span className="title">
                            I NOSTRI PRODOTTI CONSIGLIATI
                        </span>
                    </div>
                    <FeaturedProducts />
                </div>
                {user && enabledAidedShop &&
                    <AssistedPopup
                        action={this.popupAction}
                        title="Prova la spesa assistita completa!"
                        content={"Ti offriamo un nuovo servizio dove avrai a disposizione un commesso che ti guiderà tra gli scaffali del supermercato. Tu vedrai con i suoi occhi i prodotti, che potrai scegliere e mettere direttamente nel carrello. Il commesso non potrà vedere te, ma solo sentirti."}
                        subContent={`Il costo del servizio è ${aidedFee}€`}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        storeId: state.user.storeId
    };
}

const mapDispatchToProps = dispatch => {
    return {
        current: () => dispatch(actionCreators.current()),
        getFavorites: () => dispatch(actionCreators.getFavorites()),
        getSlists: () => dispatch(actionCreators.getSlists()),
        fullAidedShop: () => dispatch(actionCreators.fullAidedShop(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
