import React from "react"

import "./operator-cart-item.styles.scss";

const OperatorCartItem = ({ item }) => {
    console.log(item);
    return (
        <div className="operator-cart-item">
            <div className="info">
                <img src={item.imageUrl} alt="" />
                <div>{item.description}</div>
            </div>
            <div className="amount">
                {item?.purchaseUm === "PZ" ? item?.amount : item?.weight?.toFixed(1)} {item.purchaseUm}
            </div>
            <div className="total">
                {`${item.rowNetPrice} \u20AC`}
            </div>
        </div>
    );
}

export default OperatorCartItem;