import config from "../../../../config/config"
import { handleResponse } from "../../../../utils/handleResponse";
import { checkResponse } from "../../../../utils/checkResponse";

export default function timeslots(storeId, day) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/serviceslots?weekDay=${day}&storeId=${storeId}&serviceType=AIDED_SHOP`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
        .then(handleResponse)
        .then(checkResponse)
    );
}