import config from "../../../../config/config"
import { handleResponse } from "../../../../utils/handleResponse";
import { checkResponse } from "../../../../utils/checkResponse";

export default function disponibility(storeId, deliveryMode, serviceTimeSlotId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/slots/days?storeId=${storeId}&deliveryMode=${deliveryMode}${serviceTimeSlotId ? `&serviceTimeslotId=${serviceTimeSlotId}` : ""}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}