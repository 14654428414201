import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Popup from "../../../components/UI/Popup/Popup"
import config from "../../../config/config";
import dayOfWeek from "../../../utils/dateUtils";
import { Link } from "react-router-dom"
import { Translation } from 'react-i18next';
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import toaster from "../../../utils/toaster";
import { ecommerceCartsService } from "../../../services/ecommerce/EcommerceCartsService";
import FreezeCheckPopup from "./FreezeCheck/FreezeCheckPopup";
import renderPrice from "../../../utils/renderPrice";
import { checkout } from "../../../components/GoogleTagManager"

class CartMenu extends React.Component {

    state = {
        forItemReplacement: this.props.forItemReplacement,
        forFreshService: this.props.forFreshService
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    renderTotal = (cart) => {
        if (cart) {
            if (cart.netTotal) {
                return `${renderPrice(cart.netTotal.toFixed(2))} \u20AC`;
            } else {
                return `0,00 \u20AC`;
            }
        }
    }

    renderMinNetTotal = (cart) => {
        if (cart && cart.minNetTotal && cart.minNetTotal > 0) {
            return (
                <Row>
                    <Col sm={6}>
                        <span className="cart-message">
                            Importo minimo per prenotare
                    </span>
                    </Col>
                    <Col sm={6} className="cart-menu-message">
                        {`${renderPrice(cart.minNetTotal.toFixed(2))} \u20AC`}
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    }

    goToCheckout = () => {
        const { cart, user } = this.props;
        const { forItemReplacement, forFreshService } = this.state;
        if (cart && cart.netTotal >= cart.minNetTotal) {
            if (user.mobilePhone && user.email) {
                ecommerceCartsService.freezeCheck(cart.id, false)
                    .then(response => {
                        if (response && response.responseCode === 4105) {
                            this.props.freezeCheck(response.data, true);
                        } else if (response && response.responseCode === 0) {
                            checkout(cart);
                            this.props.aidedShop(forItemReplacement, forFreshService);
                            if (forItemReplacement || forFreshService) {
                                this.props.history.push({
                                    pathname: `/checkout/assisted`,
                                })
                            } else {
                                this.props.history.push("/checkout")
                            }
                        }
                    })
            } else {
                this.props.history.push("/profile")
            }
        } else {
            toaster.standard(`l'importo minimo per prenotare è: ${cart && cart.minNetTotal}€`);
        }
    }

    render() {
        const { user, cart, availability, abort, updatedItems, removedItems, showFreezePopup, closeFreezePopup, enabledAidedShop } = this.props;
        const { forItemReplacement, forFreshService } = this.state;
        const availableDay = availability && dayOfWeek(availability.weekDay)
        return (
            <Translation>
                {t =>
                    <>
                        <div className="cart-menu-container">
                            <div className="pl-xl-4 pl-sm-3">
                                <div className="cart-menu-title mb-5">{t("cart.menu.title")}</div>
                                <Row className="mb-4">
                                    <Col sm={6}>
                                        <span className="cart-message">
                                            {t("cart.totalAmount")}
                                        </span>
                                    </Col>
                                    <Col style={{ textTransform: "unset" }} sm={6} className="cart-menu-message">
                                        {cart.itemsCount} pz
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={6}>
                                        <span className="cart-message">{t("cart.total")}</span>
                                    </Col>
                                    <Col sm={6} className="cart-menu-message">
                                        {this.renderTotal(cart)}
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={6}>
                                        <span className="cart-message">{t("cart.deliveryMode")}</span>
                                    </Col>
                                    <Col sm={6} className="cart-menu-message">
                                        {cart && t(`cart.deliveryMode.${cart.deliveryMode}`)}
                                    </Col>
                                </Row>
                                {cart.deliveryMode &&
                                    (cart.deliveryMode === "IN_STORE" ||
                                        cart.deliveryMode === "DRIVE") &&
                                    <Row className="mb-4">
                                        <Col sm={6}>
                                            <span className="cart-message">{t("store")}</span>
                                        </Col>
                                        <Col sm={6} className="cart-menu-message">
                                            {cart && cart.storeDescription}
                                        </Col>
                                    </Row>
                                }
                                {availability &&
                                    <Row className="mb-4">
                                        <Col sm={6}>
                                            <span className="cart-message">{t("firstAvailability")}</span>
                                        </Col>
                                        <Col sm={6} className="cart-menu-message">
                                            <div>
                                                <span className="mr-2">{t(`daysOfWeek.${availableDay.name}`)}</span>
                                                {/* {availableDay.dayNumber} {t(`months.${availableDay.month}`)} */}
                                                <nobr><span>({availability.validFrom} - {availability.validTo})</span></nobr>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                {this.renderMinNetTotal(cart)}
                                {enabledAidedShop &&
                                    <div className="partial-assisted-shop d-none d-lg-block">
                                        <span>Spesa assistita?</span>
                                        <div className="partial-assisted-shop-title">Vuoi provare ad acquistare i prodotti freschi o gli articoli sostitutivi tramite un commesso che ti guiderà tra i reparti con i suoi occhi?</div>
                                        <div className="partial-assisted-shop-checkboxes">
                                            <div style={{ marginRight: "40px" }} className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="forFreshService"
                                                    name="forFreshService"
                                                    checked={forFreshService}
                                                    onClick={this.onChange}
                                                />
                                                <label className="custom-control-label" htmlFor="forFreshService">
                                                    PRODOTTI FRESCHI
                                                </label>
                                                <div className="departments">(Verdura, pesce, macelleria)</div>
                                            </div>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    id="forItemReplacement"
                                                    name="forItemReplacement"
                                                    checked={forItemReplacement}
                                                    onClick={this.onChange}
                                                />
                                                <label className="custom-control-label" htmlFor="forItemReplacement">
                                                    ARTICOLI SOSTITUTIVI
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="cart-actions-spacer">
                                    <Row>
                                        <Col>
                                            {user &&
                                                <button className={`cta ${cart && cart.itemsCount > 0 ? "" : "custom-button-disabled"}`}
                                                    style={{ width: "318px" }}
                                                    onClick={this.goToCheckout}>
                                                    <span>
                                                        {(forItemReplacement || forFreshService) ? t("cart.book") : "Procedi con l'ordine"}
                                                    </span>
                                                </button>
                                            }
                                            {!user &&
                                                <button className="custom-button outline"
                                                    style={{ maxWidth: "400px" }}
                                                    onClick={() => { document.getElementById('user').click(); }}>
                                                    {t("access")}
                                                </button>
                                            }
                                        </Col>
                                    </Row>
                                    {config.SHOW_CART_LISTS &&
                                        <Row>
                                            <Col className="pb-2">
                                                <button className="button-clean outline" onClick={this.props.changeView}>
                                                    <span className="cart-actions-link">
                                                        {this.props.label}
                                                    </span>
                                                </button>
                                            </Col>
                                        </Row>
                                    }
                                    {config.SHOW_CART_UPDATE &&
                                        <Row>
                                            <Col className="pb-2">
                                                <Link className="cart-actions-link" to="/cart/update">
                                                    {t("cart.update")}
                                                </Link>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col className="pb-2 pt-2">
                                            <Popup
                                                action={() => abort(cart.id)}
                                                title={t("cart.delete")}
                                                content={t("cart.delete.question")}
                                                button={t("delete")}
                                                trigger={
                                                    <button className="button-clean outline">
                                                        <span className="cart-actions-link">{t("cart.delete")}</span>
                                                    </button>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <FreezeCheckPopup
                            open={showFreezePopup}
                            closePopup={closeFreezePopup}
                            updatedItems={updatedItems}
                            removedItems={removedItems}
                        />
                    </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        availability: state.cart.availability,
        updatedItems: state.cart.updatedItems,
        removedItems: state.cart.removedItems,
        showFreezePopup: state.cart.showFreezePopup,
        storeId: state.user.storeId,
        enabledAidedShop: state.user.enabledAidedShop,
        forItemReplacement: state.user.forItemReplacement,
        forFreshService: state.user.forFreshService,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        abort: (cartId) => dispatch(actionCreators.abort(cartId)),
        freezeCheck: (data, showPopup) => dispatch(actionCreators.freezeCheck(data, showPopup)),
        aidedShop: (forItemReplacement, forFreshService) => dispatch(actionCreators.aidedShop(forItemReplacement, forFreshService))
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartMenu));