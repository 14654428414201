import React from "react";
import { Route, Switch } from 'react-router-dom';
import Row from "react-bootstrap/Row"
import CheckoutHeader from "./CheckoutHeader/CheckoutHeader";
import Disponibility from "./Disponibility/Disponibility";
import UserData from "./UserData/UserData";
import Confirm from "./Confirm/Confirm";
import AssistedDisponibility from "./AssistedDisponibility/AssistedDisponibility";
import AssistedCartCreation from "./AssistedCartCreation/AssistedCartCreation";
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"

class Checkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            day: this.props.checkoutInfo ? this.props.checkoutInfo.day : null,
            timeSlotId: this.props.checkoutInfo ? this.props.checkoutInfo.timeSlotId : null,

            deliveryMode: this.props.cart ? this.props.cart.deliveryMode : null,
            addressId: null,

            profileEmail: this.props?.user?.email,
            profileMobilePhone: this.props?.user?.mobilePhone,
            profileFirstName: this.props?.user?.firstName,
            profileLastName: this.props?.user?.lastName,
            notes: null,
            invoice: null,
            billingAddressId: null,
            billingAddress: null,
            enableProductsReplacement: this.props.isFullAssistedShop ? true : null,
            paymentMethod: null,

            forItemReplacement: this.props.forItemReplacement,
            forFreshService: this.props.forFreshService,
            partialServiceMode: this.props.partialServiceMode,
            serviceType: this.props.serviceType,

            serviceDay: null,
            serviceTimeSlotId: null
        };
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.checkoutInfo && nextProps.checkoutInfo === null) return false
        else return true
    }

    componentDidMount() {
        if (this.props.checkoutInfo) this.props.saveCheckoutInfo(null)
    }

    selectDay = (day) => {
        const el = document.getElementById(this.state.day);
        if (this.state.day && el) {
            el.classList.remove("disponibility-button-selected");
        }
        this.setState({ day: day, timeSlotId: null });
        const newEl = document.getElementById(day);
        if (newEl) {
            newEl.classList.add("disponibility-button-selected");
        }
    }

    selectServiceDay = (day) => {
        this.setState({ serviceDay: day, serviceTimeSlotId: null });
    }

    selectInvoice = (value) => {
        this.setState({ invoice: value })
    }

    enableReplacement = (value) => {
        this.setState({ enableProductsReplacement: value })
    }

    selectTimeslot = (timeSlotId) => {
        if (this.state.timeSlotId) {
            if (document.getElementById(this.state.timeSlotId)) {
                document.getElementById(this.state.timeSlotId).classList.remove("pill-active");
            }
        }
        this.setState({ timeSlotId: timeSlotId })
        document.getElementById(timeSlotId).classList.add("pill-active");
    }

    selectServiceTimeslot = (timeSlotId) => {
        this.setState({ serviceTimeSlotId: timeSlotId })
    }

    selectAddress = (address) => {
        this.setState({
            billingAddressId: address.id,
            billingAddress: address
        })
    }

    selectProfileInfo = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    setPaymentMethod = (value) => {
        this.setState({ paymentMethod: value })
    }

    setCartInfo = (deliveryMode, addressId) => {
        this.setState({
            deliveryMode: deliveryMode,
            addressId: addressId
        })
    }

    freeze = (cartId) => {
        const { overlay } = this.props;
        this.props.freeze(this.state, cartId, this.state.paymentMethod);
        overlay(true);
        setTimeout(() => {
            overlay(false)
        }, 3000);
    }

    componentWillUnmount() {
        this.props.resetAidedShop();
    }

    render() {
        const { cart, isFullAssistedShop, storeId } = this.props;
        const { forFreshService, forItemReplacement, serviceDay, serviceTimeSlotId, deliveryMode, addressId } = this.state;
        const isAssistedShop = (forFreshService || forItemReplacement) ? true : false
        return (
            <Translation>
                {t =>
                    <div className="checkout-container">
                        <div className="container-fluid">
                            <CheckoutHeader
                                data={this.state}
                                isAssistedShop={isAssistedShop}
                                isFullAssistedShop={isFullAssistedShop}
                            />
                            <Row className="g-padding">
                                <div className="checkout-view mx-auto">
                                    <Switch>
                                        <Route exact path='/checkout/create'
                                            render={() =>
                                                <AssistedCartCreation setCartInfo={this.setCartInfo} />
                                            } />
                                        <Route exact path='/checkout/assisted'
                                            render={() =>
                                                <AssistedDisponibility
                                                    storeId={storeId}
                                                    serviceDay={serviceDay}
                                                    serviceTimeSlotId={serviceTimeSlotId}
                                                    selectServiceDay={this.selectServiceDay}
                                                    selectServiceTimeslot={this.selectServiceTimeslot}
                                                />} />
                                        <Route exact path='/checkout'
                                            render={() =>
                                                <Disponibility
                                                    selectDay={this.selectDay}
                                                    selectTimeslot={this.selectTimeslot}
                                                    day={this.state.day}
                                                    timeSlotId={this.state.timeSlotId}
                                                    cart={cart}
                                                    deliveryMode={deliveryMode}
                                                    serviceTimeSlotId={serviceTimeSlotId}
                                                />} />
                                        <Route exact path='/checkout/data'
                                            render={() =>
                                                <UserData
                                                    selectProfileInfo={this.selectProfileInfo}
                                                    profileEmail={this.state.profileEmail}
                                                    profileMobilePhone={this.state.profileMobilePhone}
                                                    profileFirstName={this.state.profileFirstName}
                                                    profileLastName={this.state.profileLastName}
                                                    billingAddressId={this.state.billingAddressId}
                                                    selectAddress={this.selectAddress}
                                                    selectInvoice={this.selectInvoice}
                                                    invoice={this.state.invoice}
                                                    timeSlotId={this.state.timeSlotId}
                                                    cart={cart}
                                                    freeze={this.freeze}
                                                    billingAddress={this.state.billingAddress}
                                                    enableProductsReplacement={this.state.enableProductsReplacement}
                                                    enableReplacement={this.enableReplacement}
                                                    paymentMethod={this.state.paymentMethod}
                                                    setPaymentMethod={this.setPaymentMethod}

                                                    deliveryMode={deliveryMode}
                                                    addressId={addressId}
                                                    isFullAssistedShop={isFullAssistedShop}
                                                />} />
                                        <Route exact path='/checkout/confirm'
                                            render={() =>
                                                <Confirm cart={cart} />}
                                        />
                                    </Switch>
                                </div>
                            </Row>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        checkoutInfo: state.cart.checkoutInfo,
        forItemReplacement: state.user.forItemReplacement,
        forFreshService: state.user.forFreshService,
        partialServiceMode: state.user.partialServiceMode,
        serviceType: state.user.serviceType,
        isFullAssistedShop: state.user.isFullAssistedShop,
        storeId: state.user.storeId
    };
}

const mapDispatchToProps = dispatch => {
    return {
        freeze: (data, cartId, paymentMode) => dispatch(actionCreators.freeze(data, cartId, paymentMode)),
        overlay: (isActive) => dispatch(actionCreators.overlay(isActive)),
        saveCheckoutInfo: (data) => dispatch(actionCreators.saveCheckoutInfo(data)),
        resetAidedShop: (data) => dispatch(actionCreators.resetAidedShop())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);