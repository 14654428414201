import React from "react"
import { Translation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import * as actionCreators from "../../../redux/store/actions/index"
import Dropdown from "../../../components/UI/Dropdown/Dropdown"
import AddressSelect from "../../CartCreate/AddressSelect/AddressSelect"
import { storesService } from "../../../services/basis/StoresService"
import toaster from "../../../utils/toaster"

class AssistedCartCreation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryModes: [],
            selectedDeliveryMode: "",
            addressId: null,
            address: null
        }
    }

    componentDidMount() {
        ecommerceService.deliveryModeByStore()
            .then(response => {
                this.setState({
                    deliveryModes: response.data
                })
            });
    }

    selectDeliveryMode = (item) => {
        this.setState({ selectedDeliveryMode: item, addressId: null });
    }

    selectAddress = (item) => {
        this.setState({ addressId: item.id, address: item });
    }

    submit = () => {
        if (this.state.selectedDeliveryMode === "AT_HOME") {
            let isAddressAvailable = false;
            storesService.storesByZipcodeAndDelivery(this.state.address?.zip, this.state.selectedDeliveryMode)
                .then(response => {
                    const stores = response.data;
                    if (stores && stores.length > 0) {
                        stores.forEach(element => {
                            if (element.id === this.props.storeId) {
                                isAddressAvailable = true;
                            }
                        });
                    }
                    if (isAddressAvailable) {
                        this.props.setCartInfo(this.state.selectedDeliveryMode, this.state.addressId);
                        this.props.history.push("/checkout/assisted")
                    } else {
                        toaster.error("Il servizio di consegna non è attualmente attivo per l'indirizzo inserito.")
                    }
                }).catch((error) => {
                    console.log(error);
                })
        } else {
            this.props.setCartInfo(this.state.selectedDeliveryMode);
            this.props.history.push("/checkout/assisted")
        }
    }

    render() {
        const { deliveryModes, selectedDeliveryMode, addressId } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="checkout-data checkout-data-assisted">
                        <div className="checkout-data-title text-center">
                            {/* {t('signup.confirmData')} */}
                            Seleziona tipologia consegna della spesa
                        </div>
                        <div className="cart-create-select">
                            <Dropdown
                                items={deliveryModes}
                                select={this.selectDeliveryMode}
                                label={t("create.cart.service")}
                                isDeliveryMode={true}
                            />
                            {(selectedDeliveryMode === "AT_HOME" ||
                                selectedDeliveryMode === "SHIPPED") &&
                                <AddressSelect
                                    select={this.selectAddress}
                                    label="Indirizzo di spedizione"
                                />
                            }
                            <div className="text-center">
                                <button className="cta"
                                    disabled={selectedDeliveryMode === "" ||
                                        ((selectedDeliveryMode === "AT_HOME" || selectedDeliveryMode === "SHIPPED") && !addressId)}
                                    onClick={
                                        // open(selectedDeliveryMode, storeId, addressId)
                                        this.submit
                                    }>
                                    {/* <span>{t("create.cart")}</span> */}
                                    <span>{t("Prosegui")}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        open: (deliveryMode, storeId, addressId) => dispatch(actionCreators.open(deliveryMode, storeId, addressId)),
    };
}

const mapStateToProps = state => {
    return {
        storeId: state.user.storeId
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssistedCartCreation));