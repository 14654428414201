import React from "react";
import Popup from "reactjs-popup";
import { Translation } from "react-i18next";
import FreezeCheckItem from "./FreezeCheckItem";

import "./freeze-check.styles.scss";

function FreezeCheckPopup(props) {
    const { updatedItems, removedItems } = props;
    return (
        <Translation>
            {t =>
                <Popup
                    lockScroll={true}
                    offsetY={25}
                    contentStyle={popupStyle}
                    closeOnDocumentClick
                    open={props.open}
                    onClose={props.closePopup}
                    modal
                >
                    {close => (
                        <div className="freeze-check-popup">
                            <div id="close-popup" className="close" onClick={close}>
                                &times;
                            </div>
                            <div className="freeze-check-popup-header">
                                <div className="header-title">
                                    Attenzione!
                                </div>
                                <div className="sub-header-title">
                                    Alcuni prodotti insertiti a carrello hanno subito una variazione di prezzo/disponibilità !
                                </div>
                                <hr />
                            </div>
                            <div className="freeze-check-popup-content">
                                {updatedItems && updatedItems.map(item => (
                                    <FreezeCheckItem key={item.barcode} item={item} isUpdate={true} />
                                ))}
                                {removedItems && removedItems.map(item => (
                                    <FreezeCheckItem key={item.barcode} item={item} isUpdate={false} />
                                ))}
                            </div>
                            <hr />
                            <div className="freeze-check-popup-actions">
                                <button className="cta" onClick={close}>
                                    <span>Continua</span>
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            }
        </Translation >
    );
}

const popupStyle = {
    borderRadius: "15px"
}

export default FreezeCheckPopup;