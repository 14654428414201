import React from "react";
import { ReactComponent as FruttaVerdura } from '../../images/categories/frutta-verdura.svg';
import { ReactComponent as LatticiniPane } from '../../images/categories/formaggio-pane.svg';
import { ReactComponent as Surgelati } from '../../images/categories/frozen-food.svg';

import { ReactComponent as Alimentari } from '../../images/categories/alimentari.svg';
import { ReactComponent as Animali } from '../../images/categories/animali.svg';
import { ReactComponent as Bevande } from '../../images/categories/bevande.svg';
import { ReactComponent as Alcolici } from '../../images/categories/alcolici.svg';
import { ReactComponent as Persona } from '../../images/categories/persona.svg';
import { ReactComponent as Gastronomia } from '../../images/categories/gastronomia.svg';
import { ReactComponent as PiattiPronti } from '../../images/categories/piatti-pronti.svg';
import { ReactComponent as CarnePesce } from '../../images/categories/carne-pesce.svg';
import { ReactComponent as Baby } from '../../images/categories/baby.svg';
import { ReactComponent as CucinaBagno } from '../../images/categories/cucina-bagno.svg';
import { ReactComponent as Giardino } from '../../images/categories/giardino.svg';
import { ReactComponent as Ricorrenze } from '../../images/categories/Ricorrenze.svg';

export default class CategoryIcon extends React.Component {
    render() {
        var icon = null;
        const { categoryId } = this.props;
        switch (categoryId) {
            case "01": icon = <FruttaVerdura />;
                break;
            case "02": icon = <LatticiniPane />;
                break;
            case "03": icon = <CarnePesce />;
                break;
            case "04": icon = <PiattiPronti />;
                break;
            case "06": icon = <Surgelati />;
                break;
            case "07": icon = <Alimentari />;
                break;
            case "08": icon = <Gastronomia />;
                break;
            case "10": icon = <Baby />;
                break;
            case "12": icon = <Animali />;
                break;
            case "13": icon = <Bevande />;
                break;
            case "15": icon = <Alcolici />;
                break;
            case "16": icon = <CucinaBagno />;
                break;
            case "17": icon = <Persona />;
                break;
            case "18": icon = <Giardino />;
                break;
            case "99": icon = <Ricorrenze />;
                break;
                
            default: return icon;
        }

        return icon;
    }
}




