import React, { Component } from 'react';

import "./footer-attachments.styles.scss"

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="container mb-5 pb-5">
                <div className="terms-condition-header">
                    Privacy Policy di <span style={{ textDecoration: "underline" }}>Supermercato.casa</span>
                </div>
                <div>
                    <p>
                        Supermercato.casa (Il Titolare)raccoglie alcuni Dati Personali dei propri Utenti.
                    </p>
                </div>
                <div className="condition-title-header">
                    Titolare del Trattamento dei Dati
                </div>
                <div>
                    Chiama Srl
                </div>
                <div>
                    via Monte Circeo,12 – 00015 Monterotondo (RM) <br />
                    CF/P.IVA: 15266581006
                </div>
                <div>
                    <span className="privacy-email">Indirizzo email del Titolare:</span>
                    <a className="mail-to-links" href="mailto: info@supermercato.casa">&nbsp;info@supermercato.casa</a>
                </div>
                <br />
                <div>
                    Regolamento Europeo sulla protezione dei dati personali 2016/679
                </div>
                <div>
                    <br />
                    Premessa
                </div>
                <div>
                    Ti informiamo che i dati riguardanti la tua persona li trattiamo con particolare prudenza e
                    attenzione, utilizzando standard di sicurezza implementati rispetto al già elevato livello di misure
                    adottate sino ad ora. <br /><br />
                    L'informativa sarà costantemente aggiornata anche in considerazione di futuri cambiamenti
                    tecnologici, finalità e/o orientamenti delle Autorità Garanti Europee e del Comitato Europeo e sarà
                    sempre pubblicata la data di ultimo aggiornamento.
                </div>
                <br />
                <div>
                    1. Chi tratta i Tuoi dati personali?
                </div>
                <br />
                <div>
                    Il Titolare di ogni trattamento di dati personali operato dalla nostra società è CHIAMA SRL,
                    I tuoi dati saranno trattati da personale interno all’azienda appositamente autorizzato e designato
                    “Incaricato del trattamento”, periodicamente formato e aggiornato sui propri ruoli, responsabilità,
                    compiti e modalità di trattamento e sono posti sotto il diretto controllo del titolare del
                    trattamento.

                </div>
                <br />
                <div>
                    2. DATA PROTECTION OFFICER (DPO)
                </div>
                <br />
                <div>
                    Il Responsabile Protezione Dati l’Amministratore pro-tempore della Chiama srl, domiciliato per la
                    carica in Via Monte Circeo, 12 – 00015 Monterotondo Scalo (RM) ; info@supermercato.casa
                </div>
                <br />
                <div>
                    3. CATEGORIE DI SOGGETTI AI QUALI I DATI POTRANNO ESSERE COMUNICATI O VENIRNE A CONOSCENZA
                </div>
                <br />
                <div>
                    Ferme restando le comunicazioni effettuate in adempimento di un obbligo di legge, di regolamento o
                    di normativa vigente, la comunicazione, anche mediante la semplice consultazione o messa a
                    disposizione dei dati che ti riguardano può intervenire nei confronti delle seguenti categorie di
                    soggetti: persone fisiche o giuridiche che forniscono specifici servizi alla nostra società di
                    logistica e consegne, consulenza legale, amministrativa, tributaria e/o contabile, o preposti al
                    recupero del credito, servizi di manutenzione riparazione, installazione, sostituzioni o
                    aggiornamenti di apparecchi elettronici/informatici. <br /><br />
                    I soggetti sopra indicati saranno nominati responsabili del trattamento e dovranno attenersi alle
                    medesime elevate condizioni di protezione dati così come applicate dalla nostra società nonché la
                    stessa normativa privacy contenuta nel presente modulo. I dati personali e le informazioni per il
                    pagamento, relative quindi a carte di credito o dati bancari per il bonifico non verranno trattati
                    direttamente dal Titolare, in quanto l’utente viene indirizzato su piattaforme di pagamento di
                    soggetti terzi (Stripe / Paypal), con loro diretta responsabilità nella gestione dei
                    dati. <br /><br />
                    Ti rassicuriamo che, in ogni caso, verranno trasferiti/comunicati esclusivamente i dati necessari e
                    pertinenti rispetto alle finalità del trattamento cui sono preposti i singoli incaricati e
                    responsabili del trattamento, i quali ultimi soggetti dovranno rispettare le medesime condizioni di
                    riservatezza e di protezione dei dati indicati alla presente informativa. <br />
                    Al di fuori dei casi sopra indicati, i tuoi dati personali non saranno diffusi né comunicati a
                    terzi, salvo tuo preventivo ed esplicito consenso.

                </div>
                <div><br />
                    4. FONTE DEI DATI PERSONALI
                </div>
                <br />
                <div>
                    Tutti i dati raccolti sono trattati nel rispetto della normativa vigente e con la dovuta
                    riservatezza.
                    I dati in nostro possesso, acquisiti in relazione ai rapporti contrattuali o precontrattuali, sono
                    raccolti direttamente presso l’interessato sia in modalità cartacea sia digitale.
                    I trattamenti dati avvengono in modalità manuale e automatizzata, cartacea e digitale.
                    I dati potranno essere raccolti talora anche presso soggetti terzi solo previo Tuo libero e
                    informato consenso rilasciato al Titolare che effettua o che intenderebbe effettuare il trattamento.

                </div>
                <br />
                <div>
                    5. NATURA DELLA RACCOLTA – RIFIUTO COMUNICAZIONE DATI
                </div>
                <br />
                <div>
                    La richiesta all’Utente di dati personali presente nei form on-line del sito www.supermercato.casa
                    necessari per la richiesta di informazioni e/o finalizzati ad instaurare una relazione commerciale
                    ha natura facoltativa, ma il rifiuto di tale comunicazione da parte dell’utente determina
                    l’impossibilità per la società di prendere in carico la richiesta precontrattuale / contrattuale. La
                    comunicazione di tali dati personali, quindi, è un requisito necessario per la conclusione del
                    contratto di fornitura della richiesta di informazioni e/o la d servizio di download e/o di
                    richiesta di informazioni sui servizi offerti dalla nostra società. <br />
                    La raccolta di eventuali dati personali mediante il contratto (cartaceo o digitale) di fornitura di
                    beni e/o servizi è un obbligo contrattuale nonché necessaria per assolvere gli adempimenti di legge
                    (a titolo esemplificativo in materia fiscale/contabile).
                    La mancata comunicazione dei dati da parte dell’interessato, come richiesti nei contratti di
                    fornitura di beni e/o servizi non rende possibile la conclusione del contratto.
                    La raccolta dati a scopo promozionale ha natura facoltativa ed il rifiuto non pregiudica
                    l’erogazione del servizio richiesto.

                </div>
                <br />
                <div>
                    6. FINALITA’ E BASE GIURIDICA DEL TRATTAMENTO
                </div>
                <br />
                <div>
                    La raccolta ovvero il trattamento dei dati personali ha le seguenti finalità tese a provvedere in
                    modo adeguato all’espletamento dell’attività economica della nostra società ed in particolare per:

                    <div><br />
                        a) scambio di informazioni precontrattuali anche per l’espletamento dei servizi di
                        download;<br />
                        b) gestione amministrativa-fiscale e contabile;<br />
                        c) esecuzione contrattuale;<br />
                        d) conservazione dei dati personali per cinque anni dall’ultima manifestazione di interesse
                        tramite l’interazione con il sito della nostra società www.cicalia.it, mentre i documenti
                        fiscalmente rilevanti la conservazione è legata all’arco temporale imposto dalla legge vigente;
                        al termine dell’arco temporale sopra indicato i dati verranno definitivamente cancellati;<br />
                        e) invio di comunicazioni di promozionali con modalità anche automatizzate;<br />
                        f) elaborazione di statistiche e dati di consumo, elaborate comunque in forma anonima attraverso
                        sistemi di analisi (es. Google Analytics); <br />
                        g) profilazione mediante l’uso di cookies volti a cogliere il comportamento dell’utente (ad
                        esempio
                        individuazione dei click effettuati) senza che da ciò possano essere assunte decisioni
                        automatizzate in grado di determinare conseguenze rilevanti anche giuridicamente per
                        l’interessato; le informazioni ricavate verranno utilizzate per conoscere se gli argomenti
                        trattati dal sito sono stati di gradimento dell’utente. <br />

                    </div>
                </div>
                <div>
                    Per il trattamento dei dati di cui alla precedente lettera e) ed f) la base giuridica del
                    trattamento è l’espresso consenso di natura facoltativa. <br />
                    Il trattamento dei dati fondato su un contratto di cui l’interessato è parte, lettera a), b), c) e
                    d) del presente articolo, e/o su un obbligo di legge, non richiede l’espresso consenso del soggetto.

                </div>
                <div><br />
                    7. LUOGO DI TRATTAMENTO E CONSERVAZIONE
                </div>
                <br />
                <div>
                    I Dati sono trattati presso le sedi ove sono locati i server del Titolare. Per ulteriori
                    informazioni, contatta il Titolare. Qualora abbia luogo un trasferimento di luogo dei dati
                    dell’Utente, il Titolare è obbligato a fornire tale comunicazione all’Utente ed in ogni caso
                    l’Utente può chiedere informazioni al Titolare contattandolo agli estremi riportati nel presente
                    documento.
                </div>
                <div><br />
                    8. PERIODO DI CONSERVAZIONE
                </div>
                <br />
                <div>
                    I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati
                    raccolti. <br />
                    Pertanto: <br />
                    <div className="privacy-list-items">
                        <ul>
                            <li>
                                I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il
                                Titolare
                                e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale
                                contratto.
                            </li>
                            <li>
                                I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del
                                Titolare
                                saranno
                                trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori
                                informazioni
                                in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di
                                questo
                                documento
                                o contattando il Titolare.
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    9. DIRITTI DELL’UTENTE
                </div>
                <br />
                <div>
                    Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali
                    più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere
                    obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di
                    legge o per ordine di una autorità. <br />
                    Al termine del periodo di conservazioni i Dati Personali saranno cancellati. Pertanto, allo spirare
                    di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità
                    dei Dati non potranno più essere esercitati.
                    <div>
                        <div>
                            Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal
                            Titolare.
                            In particolare, l’Utente ha il diritto di:
                        </div>
                        <div className="privacy-list-items">
                            <ul>
                                <li>
                                    revocare il consenso in ogni momento. L’Utente può revocare il consenso al
                                    trattamento
                                    dei propri Dati Personali precedentemente espresso.
                                </li>
                                <li>
                                    opporsi al trattamento dei propri Dati. L’Utente può opporsi al trattamento dei
                                    propri
                                    Dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori
                                    dettagli
                                    sul diritto di opposizione sono indicati nella sezione sottostante.
                                </li>
                                <li>
                                    accedere ai propri Dati. L’Utente ha diritto ad ottenere informazioni sui Dati
                                    trattati
                                    dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei
                                    Dati
                                    trattati.
                                </li>
                                <li>
                                    verificare e chiedere la rettificazione. L’Utente può verificare la correttezza dei
                                    propri Dati e richiederne l’aggiornamento o la correzione.
                                </li>
                                <li>
                                    ottenere la limitazione del trattamento. Quando ricorrono determinate condizioni,
                                    l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso
                                    il
                                    Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.
                                </li>
                                <li>
                                    ottenere la cancellazione o rimozione dei propri Dati Personali. Quando ricorrono
                                    determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da
                                    parte del Titolare, consapevole che tale richiesta potrebbe inficiare sulla corretta
                                    erogazione del Servizio.
                                </li>
                                <li>
                                    ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente ha diritto di
                                    ricevere i propri Dati in formato strutturato, di uso comune e leggibile da
                                    dispositivo
                                    automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza
                                    ostacoli
                                    ad un altro titolare. Questa disposizione è applicabile quando i Dati sono trattati
                                    con
                                    strumenti automatizzati ed il trattamento è basato sul consenso dell’Utente, su un
                                    contratto di cui l’Utente è parte o su misure contrattuali ad esso connesse.
                                </li>
                                <li>
                                    proporre reclamo. L’Utente può proporre un reclamo all’autorità di controllo della
                                    protezione dei dati personali competente o agire in sede giudiziale.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    10. DETTAGLI SUL DIRITTO DI OPPOSIZIONE
                </div>
                <br />
                <div>
                    Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di
                    cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti
                    hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare. <br />
                    Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto,
                    possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti
                    dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni
                    di questo documento. <br />
                    Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di
                    contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e
                    evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese.

                </div>

            </div>
        );
    }
}

export default PrivacyPolicy;