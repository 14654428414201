import React from "react";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import FacebookIcon from "../../../images/facebook-square-brands.svg";
import PlayStore from "../../../images/PlayStore.png";
import AppStore from "../../../images/AppStore.png";
import InstagramIcon from "../../../images/instagram-square-brands.svg";
import { ReactComponent as Logo } from "../../../images/logo-footer.svg";

import "./footer.styles.scss";


export default class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <CookieConsent
                    location="bottom"
                    buttonText="OK"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}>
                    Utilizziamo i cookie per essere sicuri che tu possa avere la migliore esperienza sul nostro sito.
                    Continuando a navigare nel sito si accetta la nostra politica cookie.
                </CookieConsent>
                <div className="footer-content">
                    <div className="row container-fluid">
                        <div className="col-lg-2 col-md-12 col-12 text-center text-lg-left mobile-col logo">
                            <Logo />
                        </div>
                        <div className="col-lg-2 offset-lg-1 col-md-4 col-12 text-center text-md-left mb-3 mb-lg-0 mobile-col">
                            <Link to="/">
                                <div className="footer-link">
                                    Home
                                </div>
                            </Link>
                            <Link to="/cookies">
                                <div className="footer-link">
                                    Cookie policy
                            </div>
                            </Link>
                            <Link to="/privacy">
                                <div className="footer-link">
                                    Privacy policy
                            </div>
                            </Link>
                            <Link to="/conditions">
                                <div className="footer-link">
                                    Termini e condizioni
                            </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-4 col-12 text-center text-md-left mb-3 mb-lg-0 mobile-col">

                            <div className="footer-text">
                                Servizio Clienti:<span className="bold"> 06.877.38808</span>
                            </div>
                            <div className="footer-text">
                                E-mail:<span className="bold"> info@supermercato.casa</span>
                            </div>

                        </div>
                        <div className="col-lg-2 col-md-4 col-12 text-center mobile-col d-none d-lg-block">
                            <div className="footer-text">
                                <span className="bold">Seguici su:</span>
                                <div className="mt-2">
                                    <a href="https://www.facebook.com/supermercato.casa"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-social-icon"
                                    >
                                        <img src={FacebookIcon} alt="facebook" />
                                    </a>
                                    <a href="https://www.instagram.com/supermercato.casa/?hl=it"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-social-icon"
                                    >
                                        <img src={InstagramIcon} alt="facebook" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 text-center text-md-right">
                            <div className="footer-text">
                                <span className="bold">
                                    <nobr>
                                        Scarica la nostra APP:
                                    </nobr>
                                </span>
                            </div>
                            <div className="footer-apps">
                                <div>
                                    <a href="https://apps.apple.com/it/app/supermercato-casa/id1524940195"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <button className="cta">
                                            <img src={AppStore} alt="" />
                                            <span className="bold">App store</span>
                                        </button>
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.google.com/store/apps/details?id=com.retapps.supermercatocasa"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <button className="cta">
                                            <img src={PlayStore} alt="" />
                                            <span className="bold">Play store</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-follow d-lg-none">
                        <div className="footer-text">
                            <span className="bold">Seguici su:</span>
                        </div>
                        <div>
                            <a href=""
                                target="_blank"
                                rel="noopener noreferrer"
                                className="footer-social-icon"
                            >
                                <img src={FacebookIcon} alt="facebook" />
                            </a>
                            <a href=""
                                target="_blank"
                                rel="noopener noreferrer"
                                className="footer-social-icon"
                            >
                                <img src={InstagramIcon} alt="facebook" />
                            </a>
                        </div>

                    </div>
                </div>
                <div className="footer-bottom row no-gutters">
                    <div className="col-12 col-sm-8 text-center text-sm-left" >
                        <span>
                            Supermercato.Casa® CHIAMA Srl  - All rights reserved - p.iva 15266581006
                        </span>
                    </div>
                    <div className="col-12 col-sm-4 powered text-center text-sm-right">
                        <span>
                            Powered by <span className="bold">SmartBip</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}