import React from "react"
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import CustomInput from "../../components/UI/Input/CustomInput"
import Logged from "./Logged/Logged"
import { Translation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.login(this.state);
        this.props.closeAll();
    }

    logout = () => {
        this.props.logout();
        this.props.closeAll();
    }

    render() {
        const { user, closeAll } = this.props;

        // if (user && productToAdd) {
        //     this.props.closeAll();
        //     return <Redirect to='/cart' />;
        // }

        if (user) {
            return (
                <Logged user={user} logout={this.logout} closeAll={closeAll} />
            );
        } else {
            return (
                <Translation>
                    {t =>
                        <div style={{ paddingTop: "unset" }} className="navigation-view modal-mobile-scrolling container-fluid">
                            <Row>
                                <Col sm={6}>
                                    <p className="title">{t("login")}</p>
                                    <h5 className="subtitle">{t("login.subitle")}</h5>
                                    <div className="login-form-paragraph">{t("login.paragraph")}</div>
                                    <Form onSubmit={this.onSubmit}>
                                        <Row className="no-gutters">
                                            <Col className="login-icon text-left" xs={1} style={{ maxWidth: "30px" }}>
                                                <FontAwesomeIcon icon="user" size="1x" />
                                            </Col>
                                            <Col xs={11} md={8}>
                                                <div className="input-group mb-3">
                                                    <div className="w-100">
                                                        <CustomInput
                                                            label={"username"}
                                                            value={this.state.username}
                                                            onChange={this.onChange}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="no-gutters">
                                            <Col className="login-icon text-left" xs={1} style={{ maxWidth: "30px" }}>
                                                <FontAwesomeIcon icon="unlock" size="1x" />
                                            </Col>
                                            <Col xs={11} md={8}>
                                                <div className="input-group mb-3">
                                                    <div className="w-100">
                                                        <CustomInput
                                                            label={"password"}
                                                            value={this.state.password}
                                                            onChange={this.onChange}
                                                            type="password"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="col-12 col-sm-4 col-md-3">
                                                <button type="submit"
                                                    className="smartlist-header-button outline">
                                                    <span className="">{t("access")}</span>
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm-8 col-md-9" style={{ lineHeight: '35px' }}>
                                                <Link to="/reset" onClick={closeAll}>
                                                    {t("passwordReset")}?
                                                </Link>
                                            </div>
                                        </Row>
                                    </Form>
                                </Col>
                                <Col className="login-padding" sm={6}>
                                    <p className="title">{t("new_account")}</p>
                                    <h5 className="subtitle">{t("login_new_account.subitle")}</h5>
                                    <div className="text-center text-md-left">
                                        <Link className="" to="/signup" onClick={this.props.closeAll}>
                                            <button className="smartlist-header-button outline">
                                                <span className="">
                                                    {t("sign_in")}
                                                </span>
                                            </button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                </Translation>
            );
        }
    }
}


const mapStateToProps = state => {
    return {
        productToAdd: state.cart.productToAdd,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        login: (data) => dispatch(actionCreators.login(data)),
        logout: () => dispatch(actionCreators.logout()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

