import React from "react";
import timeslots from "./Service";
import { Col } from 'react-bootstrap'

export default class Timeslots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeslots: [],
    };
  }

  componentDidMount() {
    timeslots(this.props.storeId, this.props.day)
      .then(response => {
        this.setState({
          timeslots: response.data,
        })
      }).catch((error) => {
        console.log(error);
      })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.day !== this.props.day || prevProps.deliveryMode !== this.props.deliveryMode) {
      timeslots(this.props.storeId, this.props.day)
        .then(response => {
          this.setState({
            timeslots: response.data,
          })
        }).catch((error) => {
          console.log(error);
        })
    }
  }

  render() {
    const { timeslots } = this.state;
    const { serviceTimeSlotId } = this.props;
    return (
      <div className="disponibility-ranges row">
        {timeslots && timeslots.map((item) => (
          <Col key={item.id} xs={6} sm={3} md={3} className="text-center disponibility-ranges-item">
            <button id={item.id}
              className={`
              ${item.available ? `pill outline` : `pill-disabled pill outline`}
              ${item.id === serviceTimeSlotId ? `pill-active` : ``}
              `}
              onClick={() => this.props.selectTimeslot(item.id)}
            >
              {item.validFrom} - {item.validTo}
            </button>
          </Col>
        ))}
      </div>
    );
  }
}