import React from "react";
import PaymentsLogo from "../../images/payment_circuits.png"
import { ReactComponent as Card } from '../../images/Card.svg';
import { paymentsService } from "./Service";
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";

export default class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethods: [],
            isBoarded: false
        };
    }

    componentDidMount() {
        paymentsService.paymenthMethods()
            .then(response => {
                console.log(response);
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "STRIPE") {
                            this.setState({ isBoarded: true })
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    removePaymenMethod = () => {
        paymentsService.removePaymentMethod()
            .then(response => {
                this.setState({
                    paymentMethods: response.data,
                    isBoarded: false
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    render() {
        const { isBoarded } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="payments-container w-100">
                        <div className="title">{t('payments')}</div>
                        <div className="payments-card">
                            <div className="subtitle">{t('payments.title')}</div>
                            {!isBoarded &&
                                <div className="text-center">
                                    <div className="pb-4">
                                        <Card />
                                    </div>
                                    <div className="pb-3 text-danger">
                                        Non hai ancora associato la tua carta di credito, puoi farlo ora
                                    </div>
                                    <div>
                                        <Link to="/payments/stripe">
                                            <button className="cta"><span>Associa la carta</span></button>
                                        </Link>
                                    </div>
                                    <img style={{ width: "174px" }} src={PaymentsLogo} alt="" />
                                </div>
                            }
                            {isBoarded &&
                                <div className="text-center">
                                    <div className="card-boarded pb-4">
                                        <Card />
                                    </div>
                                    <div className="pb-3 text-success">
                                        Hai già impostato un metodo di pagamento online
                                    </div>
                                    <div>
                                        <button className="cta" onClick={this.removePaymenMethod}>
                                            <span>Rimuovi</span>
                                        </button>
                                    </div>
                                    <img style={{ width: "174px" }} src={PaymentsLogo} alt="" />
                                </div>
                            }
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}
