import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import PaymentsMode from "./PaymentsMode/PaymentsMode";
import { Redirect } from 'react-router'
import { Translation } from 'react-i18next';
import renderPrice from "../../../utils/renderPrice";
import Coupon from "./Coupon/Coupon";
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions/index"
import { ecommerceCartsService } from "../../../services/ecommerce/EcommerceCartsService";
import toaster from "../../../utils/toaster";
class Summary extends React.Component {
  state = {
    couponCode: ""
  }

  onChange = (e) => this.setState({ couponCode: e.target.value });

  handleCoupon = (action, couponCode) => {
    const { cart, manageCoupon } = this.props;
    manageCoupon(cart && cart.id, couponCode, action)
  }

  openCart = () => {
    const { deliveryMode, addressId, storeId, freeze } = this.props;
    ecommerceCartsService.open(deliveryMode, storeId, addressId)
      .then(response => {
        const cart = response.data.cart;
        freeze(cart.id)
      }).catch((error) => {
        toaster.error(error.message);
      })
  }

  render() {
    const { couponCode } = this.state;
    const { cart, freeze, timeSlotId, enableProductsReplacement,
      enableReplacement, paymentMethod, setPaymentMethod, invoice, isFullAssistedShop, deliveryMode } = this.props;

    if (!timeSlotId) {
      return <Redirect to='/checkout' />;
    }

    return (
      <Translation>
        {t =>
          <div>
            <div className="checkout-data-title">{t("checkout.summary.title")}</div>
            <div className="checkout-summary">
              <div className="container-fluid">
                <Row className="mb-3">
                  <Col className="px-0">
                    {t("subTotal")}
                  </Col>
                  <Col className="px-0 text-right checkout-summary-total-text">
                    {cart && `\u20AC ${renderPrice(cart.netTotal.toFixed(2))}`}
                  </Col>
                </Row>
                {cart && cart.fees && cart.fees.map(fee => (
                  <Row className="mb-3" key={fee.id}>
                    <div className="col-8 px-0">
                      {fee.description}
                    </div>
                    <div className="col-4 px-0 text-right checkout-summary-total-text">
                      {cart && `\u20AC ${renderPrice(fee.value.toFixed(2))}`}
                    </div>
                  </Row>
                ))}
                {cart && cart.coupons && cart.coupons.map(coupon => (
                  <Row className="mb-3" key={coupon.id}>
                    <div className="col-8 px-0">
                      {coupon.description}
                    </div>
                    <div className="col-4 px-0 text-right checkout-summary-total-text">
                      {cart && `- \u20AC ${renderPrice(coupon.value.toFixed(2))}`}
                    </div>
                  </Row>
                ))}
                <Row className="pt-3 mb-3 checkout-summary-total">
                  <Col className="px-0">
                    {t("total")}*
                  </Col>
                  <Col className="px-0 text-right">
                    {cart && cart.totalToPay ? `\u20AC ${renderPrice(cart.totalToPay.toFixed(2))}` :
                      (cart && cart.netTotal ? `\u20AC ${renderPrice(cart.netTotal.toFixed(2))}` : null)}
                  </Col>
                </Row>
              </div>
            </div>
            <div className="checkout-coupons">
              <div className="checkout-coupons-title">
                Applica coupons
              </div>
              <div className="checkout-coupons-list">
                {cart && cart.coupons && cart.coupons.map(item => (
                  <Coupon
                    key={item.id}
                    coupon={item}
                    handleCoupon={this.handleCoupon}
                  />
                ))}
              </div>
              <div className="checkout-coupons-input">
                <form onSubmit={e => {
                  e.preventDefault();
                  this.handleCoupon("ADD", couponCode);
                }}>
                  <input onChange={this.onChange} value={couponCode}
                    placeholder="Inserisci qui il codice coupons"></input>
                  <button type="submit" className="cta">
                    <span>Applica</span>
                  </button>
                </form>

              </div>
            </div>
            <div className="checkout-subtitle">
              Si avvisa che, per ogni fardello aggiuntivo oltre ai 5, è previsto un costo ulteriore di 0,25 euro per ogni fardello aggiunto con un massimo di 10 fardelli ordinabili.
              Tale importo verrà integrato nel totale della spesa una volta preparata per essere consegnata.
            </div>

            <hr />

            {!isFullAssistedShop &&
              <>
                <div style={{ marginBottom: "unset" }} className="checkout-data-title">
                  Desideri far sostituire i prodotti non disponibili con similari della stessa fascia di prezzo?*
            </div>

                <div className="d-flex mt-3">
                  <div className="radio-button mr-3">
                    <div className="custom-control custom-radio">
                      <input type="radio" className="custom-control-input"
                        id="isEnabledReplacement"
                        name="isEnabledReplacement"
                        value={true}
                        checked={enableProductsReplacement === true}
                        onChange={() => enableReplacement(true)}
                      />
                      <label className="custom-control-label" htmlFor="isEnabledReplacement">
                        Si
                  </label>
                    </div>
                  </div>
                  <div className="radio-button">
                    <div className="custom-control custom-radio">
                      <input type="radio" className="custom-control-input"
                        id="isDisableReplacement"
                        name="isDisableReplacement"
                        value={false}
                        checked={enableProductsReplacement === false}
                        onChange={() => enableReplacement(false)}
                      />
                      <label className="custom-control-label" htmlFor="isDisableReplacement">
                        No
                  </label>
                    </div>
                  </div>
                </div>

                <hr />
              </>
            }

            <PaymentsMode
              cart={cart}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              deliveryMode={deliveryMode}
            />

            <div className="text-center">
              <button
                disabled={paymentMethod === null || enableProductsReplacement === null || invoice === null}
                style={{ width: "293px" }}
                className="cta"
                onClick={() => {
                  if (isFullAssistedShop) {
                    this.openCart();
                  } else {
                    freeze(cart.id)
                  }
                }}
              >
                <span>
                  {isFullAssistedShop ? "Prenota il servizio" : "Concludi ordine"}
                </span>
              </button>
            </div>

          </div>
        }
      </Translation>
    )
  }
}

const mapStateToProps = state => {
  return {
    storeId: state.user.storeId,
    isFullAssistedShop: state.user.isFullAssistedShop,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    manageCoupon: (cartId, couponCode, action) => dispatch(actionCreators.manageCoupon(cartId, couponCode, action)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary);


