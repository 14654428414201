import React from "react";
import Modal from "../../UI/Modal/Modal"
import Login from "../../../features/Login/Login"
import Categories from "../../../features/Categories/Categories";
import NewHeader from "./NewHeader/NewHeader";
import MobileHeader from "./MobileHeader/MobileHeader"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions"
import AssistedShopMenu from "../../../features/AssistedShopMenu/AssistedShopMenu";

class ResponsiveHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false,
            showCategories: false,
            showAssisted: false,

            searchInput: "",
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.key !== prevProps.location.key
            && this.props.location.pathname !== "/search") {
            this.setState({ searchInput: "" })
        }
    }

    onChange = (e) => this.setState({ searchInput: e.target.value });

    closeAll = () => this.setState({
        showLogin: false,
        showCategories: false,
        showAssisted: false
    })

    loginModal = () => this.setState({
        showLogin: !this.state.showLogin,
        showCategories: false,
        showAssisted: false,
    })

    categoriesModal = () => this.setState({
        showCategories: !this.state.showCategories,
        showLogin: false,
        showAssisted: false,
    })

    assistedModal = () => this.setState({
        showAssisted: !this.state.showAssisted,
        showLogin: false,
        showCategories: false,
    })

    renderTotal = (cart) => {
        if (cart) {
            if (cart.netTotal) {
                return `${cart.netTotal.toFixed(2)} \u20AC`;
            } else {
                return `0.00 \u20AC`;
            }
        } else {
            return `0.00 \u20AC`;
        }
    }

    render() {
        const { user, cap, showModalLogin, storeId } = this.props;
        return (
            <>
                <div className="d-none d-lg-block">
                    <NewHeader
                        {...this.props}
                        closeAll={this.closeAll}
                        loginModal={this.loginModal}
                        categoriesModal={this.categoriesModal}
                        assistedModal={this.assistedModal}

                        renderTotal={this.renderTotal}

                        showCategories={this.state.showCategories}
                        showLogin={this.state.showLogin}
                        showAssisted={this.state.showAssisted}

                        searchInput={this.state.searchInput}
                        onChange={this.onChange}

                        availability={this.props.availability}

                        cap={cap}
                        showModalLogin={showModalLogin}
                        storeId={storeId}
                    />
                </div>

                <div className="d-lg-none">
                    <MobileHeader
                        {...this.props}
                        closeAll={this.closeAll}
                        loginModal={this.loginModal}
                        categoriesModal={this.categoriesModal}
                        assistedModal={this.assistedModal}

                        renderTotal={this.renderTotal}

                        showCategories={this.state.showCategories}
                        showLogin={this.state.showLogin}
                        showAssisted={this.state.showAssisted}

                        searchInput={this.state.searchInput}
                        onChange={this.onChange}

                        cap={cap}
                        showModalLogin={showModalLogin}
                        storeId={storeId}
                    />
                </div>
                {
                    this.state.showLogin &&
                    <Modal show={this.state.showLogin} close={this.loginModal}>
                        <Login user={user} closeAll={this.closeAll} />
                    </Modal>
                }
                {
                    this.state.showCategories &&
                    <Modal show={this.state.showCategories} close={this.categoriesModal}>
                        <Categories closeAll={this.closeAll} />
                    </Modal>
                }
                {
                    this.state.showAssisted &&
                    <Modal show={this.state.showAssisted} close={this.assistedModal}>
                        <AssistedShopMenu closeAll={this.closeAll} />
                    </Modal>
                }
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        availability: state.cart.availability,
        cap: state.user.cap,
        storeId: state.user.storeId,
        enabledAidedShop: state.user.enabledAidedShop
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showModalLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponsiveHeader));
