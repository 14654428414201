import * as actionTypes from "./actionTypes"
import { authenticationService } from "../../../services/basis/AuthenticationService"
import { history } from "../../../App"
import toaster from "../../../utils/toaster"
import { cartAction, availabilityAction, addProductToAddAction } from "./cart"
import { ecommerceCartsService } from "../../../services/ecommerce/EcommerceCartsService"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService"
import { favorites, slists } from "./slists"
import { smartlistService } from "../../../services/slists/SmartlistService"
import config from "../../../config/config"
import swal from "../../../utils/swal"

export const loginAction = (user, showModal) => {
    return {
        type: actionTypes.LOGIN,
        user: user,
        showLogin: showModal
    }
}

export const login = (data) => {
    return (dispatch, getState) => {
        authenticationService.login(data)
            .then(response => {
                dispatch(loginAction(response.data, false))

                // get current cart 
                ecommerceCartsService.current()
                    .then(response => {
                        dispatch(cartAction(response.data))

                        // get first availability
                        ecommerceService.availability()
                            .then(response => {
                                dispatch(availabilityAction(response.data))
                            }).catch((error) => {
                                console.log(error);
                            })

                        // add unauthenticathed product
                        if (getState().cart.productToAdd) {
                            const item = getState().cart.productToAdd;
                            ecommerceCartsService.store(response.data.id, item.barcode, item.amount, item.um)
                                .then((response) => {
                                    const um = response.data.updatedProduct.um;
                                    dispatch(addProductToAddAction(response.data.cart))
                                    toaster.standard(`Hai ${item.amount} ${um} ${response.data.updatedProduct.description} nel carrello`)
                                }).catch((error) => {
                                    console.log(error);
                                    toaster.error(error.message);
                                })
                        }

                        if (config.ENABLED_SLIST) {
                            // get favorites
                            smartlistService.getFavorites()
                                .then(response => {
                                    dispatch(favorites(response.data))
                                }).catch((error) => {
                                    console.log(error);
                                })
                            // get slists
                            smartlistService.getSlists()
                                .then(response => {
                                    dispatch(slists(response.data))
                                }).catch((error) => {
                                    console.log(error);
                                })
                        }

                    }).catch(error => {
                        console.log(error);
                    })
            }).catch((error) => {
                dispatch(loginAction(null, true))
                toaster.error("Credenziali errate")
            })
    }
};

export const logoutAction = () => {
    return {
        type: actionTypes.LOGOUT,
        user: null
    }
}

export const logout = () => {
    return dispatch => {
        history.push("/")
        dispatch(logoutAction())
        authenticationService.logout()
            .then(response => {
                dispatch(cartAction(null))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const isLoggedAction = (user) => {
    return {
        type: actionTypes.IS_LOGGED,
        user: user
    }
}

export const isLogged = () => {
    return dispatch => {
        authenticationService.isLogged()
            .then(response => {
                dispatch(isLoggedAction(response.data))
            }).catch((error) => {
                console.log(error);
            })
    }
};

export const coordinateAction = (latitude, longitude) => {
    return {
        type: actionTypes.COORDINATE,
        latitude: latitude,
        longitude: longitude
    }
}

export const coordinate = (latitude, longitude) => {
    return dispatch => {
        dispatch(coordinateAction(latitude, longitude))
    }
};

export const updateUserAction = (user) => {
    return {
        type: actionTypes.USER_UPDATE,
        user: user
    }
}

export const updateUser = (data) => {
    return dispatch => {
        authenticationService.updateUser(data)
            .then(response => {
                dispatch(updateUserAction(response.data))
                swal.success('Profilo aggiornato con successo');
            }).catch((error) => {
                swal.error('Errore durante l\'aggiornamento del profilo');
                console.log(error);
            })
    }
};

export const overlayAction = (isActive) => {
    return {
        type: actionTypes.OVERLAY,
        showOverlay: isActive
    }
}

export const overlay = (isActive) => {
    return dispatch => {
        dispatch(overlayAction(isActive))
    }
};

export const showLogin = (show) => {
    return dispatch => {
        dispatch(showLoginAction(show))
    }
};

export const showLoginAction = (show) => {
    return {
        type: actionTypes.SHOW_LOGIN,
        showLogin: show
    }
}

export const setCap = (cap) => {
    return dispatch => {
        dispatch(setCapAction(cap))
    }
};

export const setCapAction = (cap) => {
    return {
        type: actionTypes.SET_CAP,
        cap: cap
    }
}

export const setStore = (storeId, tid, enabledAidedShop) => {
    return dispatch => {
        dispatch(setStoreAction(storeId, tid, enabledAidedShop))
    }
};

export const setStoreAction = (storeId, tid, enabledAidedShop) => {
    return {
        type: actionTypes.SET_STORE,
        storeId: storeId,
        tid: tid,
        enabledAidedShop: enabledAidedShop
    }
}

export const aidedShop = (forItemReplacement, forFreshService) => {
    return dispatch => {
        dispatch(aidedShopAction(forItemReplacement, forFreshService));
    }
};

export const aidedShopAction = (forItemReplacement, forFreshService) => {
    return {
        type: actionTypes.AIDED_SHOP,
        forItemReplacement: forItemReplacement,
        forFreshService: forFreshService,
        partialServiceMode: forItemReplacement || forFreshService,
        serviceType: "AIDED_SHOP"
    }
}

export const fullAidedShop = (isFullAssistedShop) => {
    return dispatch => {
        dispatch(fullAidedShopAction(isFullAssistedShop));
    }
};

export const fullAidedShopAction = (isFullAssistedShop) => {
    return {
        type: actionTypes.FULL_AIDED_SHOP,
        isFullAssistedShop: isFullAssistedShop,
        serviceType: "AIDED_SHOP"
    }
}

export const resetAidedShop = () => {
    return dispatch => {
        dispatch(resetAidedShopAction());
    }
};

export const resetAidedShopAction = () => {
    return {
        type: actionTypes.RESET_AIDED_SHOP,
        forItemReplacement: false,
        forFreshService: false,
        partialServiceMode: false,
        serviceType: null,
        isFullAssistedShop: false
    }
}
