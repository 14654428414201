import React from "react"
import Banner from "../../../images/ImgLanding.jpg"
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions"

import "./landing-banner.styles.scss"

class LandingBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cap: "",
            errors: {
                cap: '',
            }
        }
    }

    onChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        var reg = new RegExp('^[0-9]{0,5}$');
        if (reg.test(value)) {
            this.setState({ [name]: value });
        }
    }

    submit = () => {
        if (this.state.cap && this.state.cap.length === 5) {
            this.props.setCap(this.state.cap);
            this.props.history.push({
                pathname: `/stores/${this.state.cap}`,
            })
        }
    }

    render() {
        const { cap } = this.state;
        const { user } = this.props;
        return (
            <div className="landing-banner">
                <div className="landing-banner-image" alt="">
                    <div className="landing-banner-content">
                        <div className="zip-card">
                            <h1 className="zip-card-title">Inserisci il tuo cap</h1>
                            <h5 className="zip-card-subtitle">
                                Inserendo il tuo cap ti mostreremo
                                i negozi che effettuano consegne
                                nella tua zona
                            </h5>
                            <div className="zip-card-search">
                                <form onSubmit={e => {
                                    e.preventDefault();
                                    this.submit();
                                }}>
                                    <input
                                        type="number"
                                        placeholder="Es. 20100"
                                        name="cap"
                                        maxLength={5}
                                        value={cap}
                                        onChange={this.onChange}
                                    />
                                    <button onClick={this.submit} className="outline"><span>Cerca</span></button>
                                </form>
                            </div>
                            {!user &&
                                <>
                                    <hr />
                                    <span className="zip-card-question">Hai già un account o vuoi registrarti?</span>
                                    <div className="zip-card-actions">
                                        <Link to="/signup">
                                            <button className="cta"><span>Registrati</span></button>
                                        </Link>
                                        <button className="cta" onClick={this.props.showLogin}><span>Login</span></button>
                                    </div>
                                </>
                            }
                        </div>

                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        showLogin: () => dispatch(actionCreators.showLogin(true)),
        setCap: (cap) => dispatch(actionCreators.setCap(cap)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingBanner));
