import React from "react";
import { Link, withRouter } from "react-router-dom"
import { Translation } from 'react-i18next';

import { ReactComponent as Logo } from '../../../../images/supermercato-casa.svg';
import { ReactComponent as BackIcon } from '../../../../images/Back.svg';
import { ReactComponent as UserIcon } from '../../../../images/user-2.svg';
import { ReactComponent as CartIcon } from '../../../../images/landing-cart-sticky.svg';
import { ReactComponent as CategoryIcon } from '../../../../images/category.svg';
import { ReactComponent as AssistedShop } from '../../../../images/occhiali_outline.svg';
import { ReactComponent as AssistedShopSelected } from '../../../../images/occhiali_full.svg';

import HeaderItem from "../HeaderItems/HeaderItem";
import HeaderCartItem from "../HeaderItems/HeaderCartItem";

import "./new-header.styles.scss";

class NewHeader extends React.Component {
    render() {
        const { user, cart, searchInput, onChange, closeAll, cap, showModalLogin, storeId, enabledAidedShop } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="header">
                        <div className="header-wrapper">
                            <div className="container-fluid">
                                <div className="row header-top">
                                    <div className="col go-back" onClick={() => this.props.history.push(`/stores/${cap}`)}>
                                        <BackIcon />
                                        <span className="go-back-link">Torna alla lista dei negozi</span>
                                    </div>
                                    {cap &&
                                        <div className="col cap">
                                            Il tuo cap: <span className="bold">{cap}</span>
                                        </div>
                                    }
                                    {user ?
                                        <div className="col user">
                                            <div className="user-logged-container" onClick={this.props.loginModal}>
                                                <div className="user-logged">
                                                    {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col user text-right">
                                            <UserIcon onClick={showModalLogin} />
                                        </div>
                                    }
                                </div>
                                <div className="row header-bottom">
                                    <div className="col">
                                        <div className="pr-md-4 align-items-center logo">
                                            <Link to={`/store/${storeId}`} onClick={closeAll}>
                                                <Logo />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col search">
                                        <form onSubmit={e => {
                                            e.preventDefault();
                                            this.props.history.push({
                                                pathname: "/search",
                                                state: { searchInput: searchInput }
                                            })
                                            closeAll();
                                        }}>
                                            <input type="search" onChange={onChange} value={searchInput}
                                                placeholder="Ricerca prodotto o categoria"></input>
                                            <button type="submit" className="cta">
                                                <span>Cerca</span>
                                            </button>
                                        </form>

                                    </div>
                                    <div className="col actions">
                                        {user && enabledAidedShop &&
                                            <HeaderItem
                                                onClick={this.props.assistedModal}
                                                actived={this.props.showAssisted}>
                                                {this.props.showAssisted ?
                                                    <AssistedShopSelected style={{ width: "32px" }} />
                                                    :
                                                    <AssistedShop style={{ width: "32px" }} />
                                                }
                                            </HeaderItem>
                                        }
                                        <HeaderItem
                                            onClick={this.props.categoriesModal}
                                            actived={this.props.showCategories}>
                                            <CategoryIcon />
                                        </HeaderItem>
                                        {user &&
                                            <HeaderCartItem
                                                actived={this.props.showCategories}
                                                path="/cart"
                                                total={this.props.renderTotal(cart)}
                                                items={cart ? cart.itemsCount : "0"}
                                                closeAll={this.props.closeAll}>
                                                <CartIcon />
                                            </HeaderCartItem>
                                        }
                                        {!user &&
                                            <HeaderItem
                                                onClick={showModalLogin}>
                                                <CartIcon />
                                            </HeaderItem>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default withRouter(NewHeader);
