import React from "react";
import LandingBanner from "./LandingBanner/LandingBanner";
import Tutorial from "./Tutorial/Tutorial";
import LandingStores from "./LandingStores/LandingStores";
import LandingInfo from "./LandingInfo/LandingInfo";

export default class LandingPage extends React.Component {
    render() {
        return (
            <div>
                <LandingBanner />
                <Tutorial />
                <LandingStores />
                <LandingInfo />
            </div>
        );
    }
}
