import React from "react";
import ContactsImage from '../../../images/contacts.jpg';
import WorkImage from '../../../images/work.jpg';
import { Link } from "react-router-dom";
// import AppImage from '../../../images/app-banner.jpg';
// import PlayStore from "../../../images/PlayStore.png";
// import AppStore from "../../../images/AppStore.png";

import "./landing-info.styles.scss"

export default class LandingInfo extends React.Component {
    render() {
        return (
            <div className="landing-info">
                {/* <div className="landing-info-image-app" style={{ backgroundImage: `url(${AppImage})` }} alt="" >
                    <div className="landing-info-image-app-content">
                        <div className="title">
                            I tuoi negozi sempre con te!
                        </div>
                        <div className="image-description">
                            Scarica la nostra app per avere sempre a portata di mano i tuoi negozi preferiti!
                        </div>
                        <div className="image-subtitle d-none d-lg-block">
                            Disponibile su:
                        </div>
                        <div className="image-cta">
                            <a href=""
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="cta">
                                    <img src={AppStore} alt="" />
                                    <span className="bold">App store</span>
                                </button>
                            </a>
                            <a href=""
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="cta">
                                    <img src={PlayStore} alt="" />
                                    <span className="bold">Play store</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div> */}
                <div className="landing-info-image-contacts" style={{ backgroundImage: `url(${ContactsImage})` }} alt="" >
                    <div className="landing-info-image-contacts-content">
                        <div className="image-title">
                            Hai un negozio?
                            </div>
                        <div className="image-description">
                            Entra a far parte della nostra rete, aumenta il tuo business
                            </div>
                        <div className="image-cta">
                            <Link to="/registration" className="link">
                                <button className="cta">
                                    <span className="bold">Contattaci</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="landing-info-image-work" style={{ backgroundImage: `url(${WorkImage})` }} alt="" >
                    <div className="landing-info-image-work-content">
                        <div className="image-title">
                            Lavora con noi
                            </div>
                        <div className="image-description">
                            Stai cercando un lavoro con orari flessibili e dove puoi dare il tuo valore aggiunto?
                            </div>
                        <div className="image-cta">
                            <Link to="/recruiting" className="link">
                                <button className="cta">
                                    <span className="bold">Contattaci</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            // </div>
        );
    }
}

