import React, { Component } from 'react';

import "./footer-attachments.styles.scss"

class TermsAndCondition extends Component {
    render() {
        return (
            <div className="container mb-5 pb-5">
                <div className="terms-condition-header">
                    CONDIZIONI D’USO E CONDIZIONI GENERALI DI VENDITA
                </div>
                <div>
                    <p>
                        (Le presenti clausole/condizioni generali possono essere stampate ovvero archiviate da parte del
                        cliente)
                    </p>
                </div>
                <div className="condition-title">
                    ESTREMI DEL FORNITORE
                </div>
                <div className="condition-blue-text">
                    Il sito www.supermercato.casa ed i servizi indicati nel sito www.supermercato.casa oggetto delle
                    presenti
                    clausole/condizioni d’uso e condizioni generali di vendita sono gestiti in esclusiva dalla CHIAMA
                    Srl, con sede
                    legale in Monterotondo 00015 (RM), via Monte Circeo,12, iscritta al Registro Imprese di ROMA con
                    Partita
                    Iva / Codice Fiscale 15266581006, di seguito indicata come “Supermercato.casa”.
                </div>
                <div className="condition-title-header">
                    A) CONDIZIONI D’USO
                </div>
                <br />
                <div className="condition-title-sub-header">
                    1) Accettazione delle condizioni d’uso e condizioni generali di vendita
                </div>
                <div>
                    Utilizzando il sito www.supermercato.casa esprimi di fatto il tuo consenso in merito alle presenti
                    Condizioni
                    d’uso e alle condizioni generali di contratto; pertanto, qualora non accettassi in tutto o in parte
                    le presenti
                    condizioni, Supermercato.casa ti invita a non utilizzare il Sito e a non scaricare alcun materiale
                    da esso.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    2) Contenuti del sito e servizi
                </div>
                <div>
                    Supermercato.casa si adopera per garantire la funzionalità del sito www.supermercato.casa (d’ora in
                    poi
                    per brevità anche “sito”) e di tutti i servizi forniti tramite il sito medesimo; fruendo del sito
                    e/o di tali servizi
                    sarai tenuto a rispettare anche le regole d’uso di tali servizi, eventualmente ulteriori e ad
                    integrazione delle
                    seguenti.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    3) Raccolta dati personali
                </div>
                <div>
                    Utilizzando il sito www.supermercato.casa e/o i servizi dallo stesso forniti, o inviandoci e-mail o
                    altro, comunichi con Supermercato.casa e consenti a Supermercato.casa di comunicare con te in vari
                    modi (ad esempio via SMS -messaggi di testo-, e-mail, video chat ecc..). <br /><br />
                    Salva l’applicazione di norme inderogabili, per le finalità di cui al presente contratto, accetti di
                    ricevere da Supermercato.casa comunicazioni in forma elettronica e che le stesse integrano
                    pienamente il requisito della forma scritta dove previsto dalle disposizioni vigenti. <br /><br />
                    I tuoi dati personali, acquisiti da Supermercato.casa durante la navigazione sul sito, mediante
                    l’utilizzo dei cookie ovvero a seguito di compilazione di appositi form per la raccolta dati,
                    verranno trattati in conformità alla normativa vigente che potrai sempre consultare alle pagine di
                    cui all’Informativa sulla privacy e alla politica cookie; ove previsto dalla normativa, ti verrà
                    richiesto il consenso esplicito per finalità specifiche. <br /><br />
                    Il Titolare del Trattamento dei tuoi dati personali è Chiama Srl, in persona del legale
                    rappresentante protempore; scrivendo al seguente indirizzo info@Supermercato.casa potrai, in ogni
                    momento, richiedere informazioni sul trattamento dei tuoi dati personali, ottenere l’aggiornamento,
                    la rettifica o l’integrazione degli stessi, nonché la cancellazione, la trasformazione in forma
                    anonima o il blocco dei dati qualora trattati in violazione di legge, opporti al trattamento secondo
                    quanto previsto dall'art. 7 del D. Lgs. 196/2003, inviando una comunicazione all’indirizzo mail
                    sopra citato. <br /><br />
                    Si ribadisce che la non accettazione delle presenti condizioni d’uso comporta l’impossibilità di
                    utilizzare il sito.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    4) Proprietà del sito
                </div>
                <div>
                    Il sito è concesso in uso esclusivo a CHIAMA Srl, così come i servizi connessi all’utilizzo del
                    sito, pertanto si obbliga il cliente e/o acquirente ad un uso esclusivamente personale; è vietato
                    l’uso per finalità commerciali e/o che possano arrecare danno/pregiudizio o si rivelino in
                    contrasto/illegittime rispetto agli interessi, anche economici, perseguiti da
                    Supermercato.casa. <br /><br />
                    Il trade dress del sito (aspetto grafico), così come il logo ed i pay-off, sono coperti dai diritti
                    di immagine e quindi, salva diversa manifestazione di volontà espressa per iscritto da Chiama Srl,
                    revocabile in ogni momento, nessuno dei contenuti del sito può essere utilizzato se non come parte
                    del sito stesso, né può essere trasmesso, distribuito o diversamente sfruttato in alcun modo;
                    tuttavia, se il Sito è configurato per permettere il download di contenuti particolari, potrai
                    scaricare una copia di tali contenuti su un singolo computer o dispositivo mobile (a seconda del
                    caso), per un esclusivo uso personale e privato e senza fini commerciali, senza: apportare
                    modifiche, affittare, noleggiare, dare in comodato, permuta, vendere, distribuire, copiare (salva la
                    riproduzione di una singola copia dei contenuti a fini di back-up personale), e/o senza realizzare
                    alcuna opera basata, in tutto o in parte, sul sito o sui contenuti. In particolare, l’utilizzatore
                    non potrà copiare, divulgare il programma “Supermercato.casa”, o riproporne o comunque utilizzarne i
                    contenuti (al di fuori delle finalità indicate nel medesimo programma), modificarne i contenuti o
                    compiere qualsiasi altra attività su tale programma in assenza di autorizzazione espressa per
                    iscritto da Chiama Srl. <br /><br />
                    È vietato il trasferimento dei contenuti ad un altro computer o dispositivo mobile al di fuori di
                    quanto stabilito dalla legge (art.71 sexies Legge 22 aprile 1941 n. 633 e s.m.i.) e, evidentemente,
                    l’impiego commerciale degli stessi.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    5) Account e responsabilità dell’utilizzatore del sito
                </div>
                <div>
                    Per utilizzare il sito e/o i relativi contenuti, ti verrà richiesta la creazione di un account con
                    l’obbligo di fornire informazioni veritiere, esatte, attuali e complete nonchè ad aggiornarle in
                    caso di mutamento. <br /><br />
                    L’utilizzo del sito e dei servizi connessi è consentito unicamente all’utente
                    maggiorenne. <br /><br />
                    Utilizzando il sito e i relativi servizi, accetti di non fingere di essere un’altra persona, di non
                    dichiarare il falso su qualsiasi circostanza riguardante la tua identità quale utente del sito, il
                    tuo account e/o password. <br /><br />
                    Sarai tenuto a comunicare tempestivamente tramite email all’indirizzo &nbsp; <a
                        className="mail-to-links"
                        href="mailto: info@supermercato.casa">&nbsp;info@supermercato.casa</a>&nbsp;
                    qualsiasi utilizzo non autorizzato del tuo nome utente, password o altre informazioni relative al
                    tuo account, o qualsiasi altra violazione della sicurezza di cui sei sia venuto a conoscenza, che
                    riguardi il sito o aspetti ad esso collegati. <br /><br />
                    Quando utilizzerai il sito, digitando username e/o password, o qualsiasi altro dato relativo alla
                    tua persona, accetti e dichiari la piena titolarità e disponibilità dei dati delle informazioni e
                    dei contenuti forniti a Chiama Srl e che il loro eventuale utilizzo da parte della società non
                    violerà alcun diritto di terzi, né alcuna normativa vigente. <br /><br />
                    Per tali motivi, ti impegni sin d’ora a tenere indenne Chiama Srl da tutte le richieste derivanti
                    dal/i contenuto/i da te fornito/i; Chiama Srl ha il diritto, ma non ha alcun obbligo, di monitorare,
                    modificare o rimuovere qualsiasi attività o contenuto e non si assume alcuna responsabilità per i
                    contenuti pubblicati da te o da terzi. <br /><br />
                    Oltre a quanto sopra indicato, sarai responsabile di mantenere e custodire la riservatezza del nome
                    utente, password e account, assumendo la responsabilità per qualsiasi contenuto, informazione,
                    commento o attività svolta mediante il tuo/tuoi account sia nei confronti di Chiama Srl , sia verso
                    terzi. <br /><br />
                    I dati e le informazioni pubblicate sul sito sono considerati strettamente riservati e
                    confidenziali. <br /><br />
                    È fatto divieto a chiunque utilizzi il sito di diffondere virus o altro componente dannoso, o
                    manomettere, compromettere, danneggiare in altro modo il sito o le reti collegate, o interferire in
                    altro modo con l’impiego o il godimento del sito da parte di altri utenti. <br /><br />
                    Chiama Srl si riserva sin d’ora la facoltà insindacabile di sospendere o bloccare il tuo account e
                    la possibilità di utilizzare il sito o parte di esso, qualora non venissero rispettate le condizioni
                    d’uso o ai termini speciali relativi ad un particolare servizio offerto attraverso il sito o per
                    aver violato la normativa vigente in materia di copyright, riservatezza, correttezza e buona fede
                    (ovvero altra disposizione civilistica, fiscale o penalistica vigente) o per aver provveduto alla
                    diffusione non autorizzata del materiale (anche in parte) del sito, ovvero per aver posto in essere
                    condotte contrarie all’ordine pubblico, al buon costume o al buon gusto. <br /><br />
                    Nel caso delle “Spesa Assistita” il Cliente si può collegarsi in videochat con un operatore
                    all’interno del punto vendita che lo assisterà durante il processo di acquisto. Il Cliente può
                    richiedere all’operatore quale articolo inserire nel carrello e visionare il prodotto prima di
                    procedere all’acquisto. I tempi di durata della videochat sono comunque definiti all’inizio della
                    stessa ed il Cliente deve completare l’acquisto entro tale tempistica.

                </div>
                <br />
                <div className="condition-title-sub-header">
                    6) Contenuti e limitazione/esclusione di responsabilità
                </div>
                <div>
                    Chiama Srl si uniforma ai canoni di correttezza, buona fede e diligenza richiesti dalle disposizioni
                    vigenti. <br /><br />
                    Ove all’interno del sito www.supermercato.casa vi fosse la presenza di link ad altri siti, Chiama
                    Srl non garantisce che tali dati, indicazioni e/o contenuti siano corretti, completi, attendibili,
                    aggiornati o privi di errori, non essendo i siti, diversi da www.supermercato.casa, controllati da
                    Chiama Srl <br /><br />
                    Si esonera sin d’ora Chiama Srl da ogni responsabilità per la mancata disponibilità di tali siti
                    esterni e/o per i contenuti, le pubblicità, i prodotti o altri materiali presenti o disponibili su
                    tali siti o fonti. <br /><br />
                    I prodotti indicati nel sito www.supermercato.casa e i contenuti ivi presenti nel medesimo sito sono
                    forniti “nello stato in cui si trovano” e senza garanzie di alcun tipo, espresse o
                    implicite. <br /><br />
                    La presenza e/o disponibilità di un prodotto/documento sul sito non significa che le informazioni in
                    esso contenute non siano state modificate o sostituite da eventi successivi o da un documento
                    pubblicato successivamente. <br /><br />
                    Chiama Srl potrà sospendere la connessione al sito in via temporanea o definitiva per cause di forza
                    maggiore (quali ad esempio mancata erogazione di corrente elettrica, calamità naturali, ordine
                    pubblico o altro) o per violazione da parte dell’utente del sito di una qualsiasi condizioni d’uso o
                    condizioni generali di vendita ovvero per violazioni relative alla normativa civilistica e/o
                    penalistica e/o fiscale commesse dall’utilizzatore del sito o, infine, per lavori di manutenzione,
                    miglioramento, implementazione del sito. In tali ipotesi Chiama Srl non corrisponderà alcun rimborso
                    e/o risarcimento diretto e/o indiretto all’utente del sito.

                </div>
                <br />
                <div className="condition-title-sub-header">
                    7) Comunicazioni di modifiche/integrazioni/cancellazioni di contenuti del sito
                </div>
                <div>
                    Chiama Srl si riserva il diritto di modificare e/o integrare in qualsiasi momento le presenti
                    Condizioni d’uso, permanentemente o temporaneamente; verrai tempestivamente informato da
                    Supermercato.casa tramite email, all’ultimo indirizzo mail da te indicato, delle modifiche,
                    aggiunte, variazioni o cancellazioni apportate al sito e ai contenuti dello stesso e/o verrà
                    pubblicata la modifica sul sito (qualora ritenuta opportuna da Supermercato.casa a suo insindacabile
                    giudizio). <br /><br />
                    Ove le variazioni siano dovute ad un mutamento/integrazione della normativa vigente, i cambiamenti
                    avranno effetto a partire dalla data richiesta dalle nuove disposizioni normative e/o regolamentari
                    e/o ministeriali e/o di altra natura. <br /><br />
                    Al di fuori della appena descritta ipotesi, le modifiche avranno effetto, divenendo vincolanti per
                    le parti contrattuali (fornitore e consumatore/utilizzatore), a partire dal giorno della
                    comunicazione di variazione o dalla pubblicazione della variazione sul sito. <br /><br />
                    In caso di mancata accettazione della variazione comunicata o pubblicata, potrai fruire del diritto
                    di annullare il tuo account.

                </div>
                <br />
                <div className="condition-title-sub-header">
                    8) Aggiornamento dati da parte dell’utilizzatore del sito
                </div>
                <div>
                    L’utilizzatore del sito o consumatore o cliente è l’unico responsabile dell’aggiornamento dei propri
                    dati,
                    ivi compreso l’indirizzo email; qualora una comunicazione non potesse giungere alla casella di posta
                    elettronica indicata per ragioni addebitabili all’utilizzatore (quali il mancato aggiornamento
                    dell’indirizzo
                    mail) o per qualsiasi altra ragione che ne impedisca la consegna, l’invio di email effettuato da
                    Chiama Srl
                    all’indirizzo ultimo da te fornito equivarrà alla corretta ed effettiva comunicazione delle
                    variazioni,
                    integrazioni e/o cancellazioni.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    9) Sicurezza nell’ambito del sito
                </div>
                <div>
                    Le procedure e le misure di sicurezza adottate nell’ambito del Sito sono conformi a quanto previsto
                    dalle
                    normative di sicurezza informatica, riguardando in particolare la verifica dell’origine,
                    dell’integrità,
                    dell’univocità e della non modificabilità della provenienza e della riservatezza dei
                    contenuti/documenti
                    pubblicati sul Sito.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    10) Foro competente
                </div>
                <div>
                    Qualsiasi controversia dovesse insorgere in ordine alla interpretazione, esecuzione, efficacia,
                    risoluzione ed
                    in genere alle sorti delle presenti Condizioni, sarà devoluta alla competenza esclusiva del Foro di
                    Roma,
                    salvo sia previsto un foro inderogabile dalla normativa vigente. <br /><br />

                    Le presenti Condizioni d’uso sono regolate dalla legge italiana e dovranno essere interpretate ai
                    sensi di tale
                    legge.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    11) Risoluzione
                </div>
                <div>
                    In caso di violazioni da parte tua della normativa nazionale e/o di una o più delle presenti
                    Condizioni d’uso o
                    condizioni generali di contratto, Chiama Srl si riserva il diritto di risolvere a propria
                    discrezione il presente
                    accordo mediante comunicazione scritta (risoluzione automatica di diritto) a mezzo mail e/o
                    raccomandata
                    a/r e bloccare il tuo account (incluso il tuo accesso al sito www.supermercato.casa). <br /><br />
                    Nella già menzionata ipotesi ti impegni sin d’ora e ti obblighi a cessare l’utilizzo del sito e a
                    distruggere tutti i
                    materiali ottenuti dal sito e le loro copie, siano esse state fatte in virtù di queste Condizioni
                    d’uso o di altro. <br /><br />

                    Qualsiasi attività fraudolenta, offensiva o diversamente illegale/illecita/inadempiente o
                    illegittima da te
                    posta in essere in relazione alla normativa civilistica, penale fiscale e/o alla predette condizioni
                    d’uso
                    costituisce ragione di blocco del tuo account e potrà essere segnalata alle autorità
                    competenti. <br /><br />

                    La commissione da parte tua di una o più violazioni sopra indicate e l’inattività di Chiama Srl nei
                    tuoi
                    confronti, non comporterà acquiescenza di Chiama Srl al tuo comportamento né rinuncia da parte di
                    Chiama Srl a far valere i propri diritti e rimedi nella medesima o in altra situazione di violazione
                    (delle norme
                    indicate sopra), salvo il decorso della prescrizione.

                </div>
                <br />
                <div className="condition-title-sub-header">
                    12) Disposizioni finali
                </div>
                <div>
                    L’eventuale invalidità e/o inefficacia e/o nullità, anche sopravvenuta, di singole clausole delle
                    presenti
                    Condizioni d’uso non determina l’inefficacia né la invalidità delle stesse (o di alcune altre loro
                    clausole). <br /><br />

                    Ai sensi e per gli effetti degli articoli 1341 e 1342 del cod. civ. si approvano specificamente le
                    seguenti clausole:
                    <div><br />
                        1) Accettazione delle condizioni d’uso e condizioni generali di vendita, 2) Contenuti del sito e
                        servizi, 3)
                        Raccolta dati personali, 4) Proprietà del sito, 5) Account e responsabilità dell’utilizzatore
                        del sito, 6)
                        Contenuti e limitazione / esclusione di responsabilità, 7) Comunicazione di modificazioni /
                        integrazioni /
                        cancellazioni di contenuti del sito, 8) Aggiornamento dati da parte dell’utilizzatore del sito,
                        9) Sicurezza
                        nell’ambito del sito, 10) Foro competente, 11) Risoluzione, 12) Disposizioni finali.
                    </div><br />
                </div>
                <br />
                <div className="condition-title-header">
                    B) CONDIZIONI GENERALI DI VENDITA
                </div>
                <br />
                <div>
                    <div>
                        ai sensi dell’art. 1336 Codice Civile
                    </div>
                </div>
                <div className="condition-title-sub-header">
                    1) Definizioni
                </div>
                <div>
                    Con l'espressione “contratto di commercio elettronico” si intende il contratto avente ad oggetto
                    beni e/o servizi stipulato tra Chiama Srl, o tra il gestore del sito e-commerce, nel caso il sito
                    venga dato in gestione a terzi, o tra un Partner di Chiama Srl nel caso la sia il Partner ad
                    effettuare sia la fatturazione che l’incasso della transazione, ed il cliente anche consumatore
                    nell’ambito di un sistema di vendita a distanza, organizzato dal fornitore, che per tale contratto
                    impiega esclusivamente uno o più tecniche di comunicazione telematica. <br /><br />

                    Con l'espressione “acquirente” si intende il cliente (soggetto passivo Iva), consumatore persona
                    fisica così come definito dall'articolo 3 del decreto legislativo 206 del 2005 vale a dire la
                    persona fisica che compie l'acquisto per scopi non riferibili all'attività commerciale o
                    professionale eventualmente svolta. <br /><br />

                    Con l’espressione “fornitore” ovvero gestore del sito e-commerce si intende il soggetto, che cede i
                    beni e/o presta i servizi indicati nel sito &nbsp;
                    <a className="mail-to-links" href="https://supermercato.casa/selezione-area"
                        target="_blank">www.supermercato.casa.</a>&nbsp;
                    <br /><br />
                    Con l’espressione “Shopper” si intende il Driver che si occupa della consegna nel CAP di competenza,
                    mentre con “Corriere” si intende il consegnatario della spesa nel caso il Cliente scelga la consegna
                    oltre le 24h in quanto effettuata su una zona/CAP non ancora coperta dal servizio
                </div>
                <br />
                <div className="condition-title-sub-header">
                    2) Oggetto del contratto
                </div>
                <div>
                    Attraverso il presente contratto di commercio elettronico il fornitore vende e l'acquirente acquista
                    a distanza, tramite strumenti telematici, i beni mobili materiali e servizi che sono riportati sul
                    sito web <a className="mail-to-links" href="https://supermercato.casa/selezione-area"
                        target="_blank">www.supermercato.casa.</a>&nbsp;
                    <br /><br />

                    I citati beni e servizi di cui al punto che precede, sono disponibili sulla pagina Web
                    www.supermercato.casa che riporta il catalogo dei beni e servizi offerti. <br /><br />

                    Il presente contratto è valido esclusivamente per acquisti e consegne effettuate sul territorio
                    italiano.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    3) Fasi per la conclusione del contratto
                </div>
                <div>Il contratto di compravendita tra venditore acquirente si conclude esclusivamente in modalità
                telematica; l'acquirente dovrà formalizzare la propria accettazione per l’acquisto di beni/servizi
                    forniti dal sito nel seguente modo: <br /><br />

                    registrarsi sul sito, creando un proprio account (con nome utente e password); <br /><br />

                    compilare il form on-line in cui vengono richiesti i dati di fatturazione e consegna all’indirizzo
                    www.supermercato.casa fornendo accettazione e consenso all’acquisto, manifestato tramite
                    adesione; <br /><br />

                    inviare il form, previa visualizzazione di una pagina web di riepilogo dell’ordine, stampabile,
                    nella quale sono riportati gli estremi dell’ordinante e dell’ordinazione, il prezzo dei prodotti
                    acquistati, le spese di spedizione e gli eventuali ulteriori oneri accessori, le modalità e i
                    termini di pagamento, l’indirizzo ove il bene/i verrà/verranno consegnato/i, i tempi della consegna
                    e l’esistenza dell’eventuale diritto di recesso; <br /><br />

                    quando il fornitore riceve dall'acquirente l'accettazione dell’ordine, provvede all'invio di una
                    e-mail di conferma, stampabile, nella quale siano riportati anche i dati richiamati dal punto
                    precedente; br

                    il fornitore procederà ad emettere il relativo documento fiscale ai sensi del D.P.R. n. 633/1972 ove
                    previsto dalla normativa, al momento del completamento della preparazione dell’ordine, ovvero alla
                    completa definizione dei beni acquistati, sia nel caso di pagamento on-line, sia in caso di
                    pagamento alla consegna; <br /><br />


                    nel caso in cui l'acquirente voglia ricevere fattura, ne darà comunicazione al fornitore in fase di
                    compilazione dell’ordine, ivi fornendo i dati relativi al proprio Codice Fiscale e numero di Partita
                    Iva. Chiama Srl si riserva comunque il diritto di inviare fattura anche al consumatore persona
                    fisica, anche se non espressamente richiesto, in alternativa al documento fiscale menzionato
                    precedentemente; <br /><br />

                    Il presente contratto non si considererà perfezionato, quindi, privo di efficacia, se manca quanto
                    riportato ai punti precedenti. <br /><br />

                    Appena il tuo prodotto verrà consegnato al corriere (“Shopper”), Chiama Srl provvederà ad inviarti
                    una mail per comunicare la partenza della consegna ed ove previsto ed attivabile, il link per
                    tracciare on line la tua spedizione. <br /><br />

                    In caso di prodotto alimentare da trasportare a temperatura controllata (ad esempio alimenti
                    freschi, deperibili ecc…) sarà obbligo dell’acquirente verificare che il numero massimo di ore,
                    intercorso tra la consegna del prodotto alimentare allo Shopper (orario rilevabile dalla mail di
                    Supermercato.casa) e la consegna del medesimo bene alimentare all’acquirente (o a
                    persona/ente/società/struttura dallo stesso delegata alla ricezione), non sia superiore al numero di
                    ore certificato e indicato sempre tramite la suddetta email di Chiama Srl, idoneo mantenere
                    inalterata la conservazione dell’alimento durante il trasporto a temperatura controllata
                    (mantenimento catena del freddo).

                </div>
                <br />
                <div className="condition-title-sub-header">
                    4) Obbligo dell’acquirente di verifica dell’etichettatura e delle indicazioni presenti.
                </div>
                <div>
                    I prodotti sono raffigurati e resi visibili sul sito in modo accurato e corrispondente alle
                    caratteristiche realmente possedute; le immagini, le descrizioni e le informazioni obbligatorie per
                    ciascun prodotto sono costantemente aggiornate; tuttavia, per i tempi tecnici di aggiornamento delle
                    informazioni presenti sul sito, il fornitore non è in grado di garantire l'esatta corrispondenza
                    alla realtà in relazione alle immagini dei colori e al packaging come appaiono sul monitor del
                    cliente, potendovi essere una variazione, per scelta unilaterale del produttore del bene, delle
                    immagini, della grafica, del packaging (imballaggio e fasi del processo produttivo), degli
                    ingredienti e altro; ciò inoltre potrebbe determinare altresì la presenza sul mercato del prodotto
                    ante modifica (sino ad esaurimento scorte) nonché del prodotto nuovo, modificato appunto dal
                    produttore. <br /><br />

                    Pertanto, si invita e si obbliga l’acquirente ad esaminare con attenzione il bene fisico
                    materialmente ricevuto-consegnato, specialmente con riguardo alle indicazioni in materia di
                    etichettatura (ingredienti, allergeni, provenienza, data di scadenza, peso - formato ecc..) e ogni
                    altro elemento che si sia rivelato determinante nella scelta d’acquisto del bene, operata
                    dall’acquirente. <br /><br />

                    L’acquirente è invitato ad accettare la merce consegnata dallo Shopper con riserva, ossia
                    dichiarando testualmente al vettore “accetto i beni consegnati con riserva”, firmando la consegna
                    dei beni e pretendendo l’indicazione per iscritto di segnalazioni rivolte allo Shopper sulla
                    presenza di ammaccature e/o sulla parziale apertura del pacco o qualsiasi altro danneggiamento
                    palese ed evidente sul prodotto consegnato. <br /><br />

                    In mancanza l’acquirente perderà il diritto alla sostituzione e/o riparazione e/o rimborso e/o
                    risoluzione del contratto. <br /><br />

                    Ciò permetterà a Chiama Srl di richiedere i danni derivanti da un erroneo trasporto direttamente al
                    vettore (ad esempio in caso di incidente del vettore con danneggiamento dell’imballaggio del
                    prodotto consegnato). <br /><br />

                    I prodotti a peso variabile (a titolo esemplificativo frutta, verdura, carne, pesce, prodotti di
                    gastronomia e formaggi) vengono ordinati dal Cliente nel Sito in misure e pesi indicativi. Il peso
                    effettivo si determina solamente alla preparazione del prodotto e potrà variare fino ad un 10% in
                    più o in meno rispetto a quanto richiesto nell’ordine, rimanendo invariato il prezzo. <br /><br />

                    Quanto all’indicazione del paese di origine o di allevamento/macellazione dei prodotti, per alcune
                    tipologie di prodotti (es. deperibili come frutta e verdura) al momento dell'acquisto verrà indicato
                    il Paese di origine del prodotto, mentre per le carni verrà indicato in etichetta al momento della
                    consegna il Paese in cui l’animale è stato allevato e macellato, questo in quanto in considerazione
                    della tipologia dei prodotti e delle modalità di effettuazione dell'ordine - anticipato rispetto
                    alla consegna - non è sempre possibile garantire che il Paese di origine o di allevamento e
                    macellazione indicati al momento dell'ordine siano quelli disponibili il giorno della consegna. Nel
                    caso di “spesa assistita” con video chat con l’operatore, tali informazioni possono essere richieste
                    all’operatore al momento dell’ordine.

                </div>
                <br />
                <div className="condition-title-sub-header">
                    5) Bene fisico consegnato non conforme al bene ordinato-acquistato - Procedura di reso
                </div>
                <div>
                    Ove previsto da normativa inderogabile, qualora il consumatore rilevi un difetto di conformità del
                    bene
                    ricevuto, ex art. 129 codice del consumo, è pregato di darne immediata comunicazione a
                    Supermercato.casa
                    a mezzo email, scrivendo all’indirizzo info@supermercato.casa (entro e non oltre 15 gg dalla
                    scoperta
                    del difetto e comunque entro la data di scadenza del prodotto); l’azione per far valere la garanzia
                    legale del bene si prescrive in ogni caso entro 26 (ventisei) mesi dall’acquisto o in caso di
                    scadenza
                    del prodotto per mancata comunicazione da parte del Cliente. <br /><br />

                    Qualora Supermercato.casa rilevasse il vizio/difetto di conformità del prodotto, si scusa sin d’ora
                    per
                    l’equivoco accaduto e si adopererà senza indugio per porre rimedio alla situazione, secondo la
                    normativa
                    vigente. <br /><br />

                    Non costituisce difetto di conformità e/o vizio del prodotto la mera variazione della veste grafica
                    del
                    prodotto, essendo un diritto del produttore. <br /><br />

                    In linea generale, l’esiguo valore del bene (alimentare o non) non conforme, consentirà a
                    Supermercato.casa di proporre al consumatore in alternativa: 1) o il rimborso del prezzo sostenuto
                    per
                    l’acquisto del prodotto non conforme; 2) o il riconoscimento di un buono spesa, pari valore del bene
                    non
                    conforme, da utilizzare nei prossimi acquisti entro i seguenti trecentosessantacinque giorni dalla
                    data
                    di comunicazione del difetto; 3) o, qualora non sia eccessivamente oneroso per il venditore (anche
                    in
                    relazione alle spese di trasporto, al costo della manodopera ecc..) e fattibile in base al prodotto
                    acquistato, la riparazione o sostituzione del bene non conforme.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    6) Modalità di pagamento
                </div>
                <div>
                    Ogni pagamento per i beni servizi acquistati da parte dell'acquirente potrà avvenire unicamente
                    mediante le modalità indicate sull’apposita pagina del sito web del fornitore. <br /><br />

                    L’utilizzo delle modalità di pagamento indicate potrà comportare l’addebito di spese aggiuntive
                    all’acquirente nel solo caso e nella stessa misura in cui tali costi debbano essere sopportati dal
                    fornitore (in relazione al mezzo di pagamento utilizzato dall’acquirente). <br /><br />

                    Prima dell’inoltro dell’ordine d’acquisto, saranno in ogni caso resi disponibili i costi di cui al
                    comma precedente. <br /><br />

                    Per motivi di correttezza e trasparenza si precisa che, il pagamento del bene o servizio on-line,
                    comporta la pre-autorizzazione sulla carta credito/bancomat o quanto utilizzato dal cliente, di un
                    importo superiore a quello effettivo dell’ordine, questo per coprire, in caso di sostituzione
                    prodotto e vendita dei prodotti a peso variabile, l’eventuale importo che risultasse superiore a
                    quello indicato in fase di conclusione ordine. In ogni caso, l’indicazione dell’importo
                    pre-autorizzato, viene indicato prima della conferma del pagamento. <br /><br />

                    Quando è terminata la preparazione dell’ordine, verrà addebitato l’importo effettivamente speso,
                    importo che verrà poi riportato nel documento fiscale e/o fattura.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    7) Modalità di rimborso
                </div>
                <div>
                    Ogni eventuale rimborso all'acquirente verrà accreditato mediante la modalità proposte dal fornitore
                    e scelta dell’acquirente (ad eccezione del contrassegno) al momento del pagamento; i rimborsi
                    verranno effettuati in modo tempestivo e nell'ipotesi di esercizio del diritto di recesso, al
                    massimo entro 14 (quattordici) giorni dalla data in cui il fornitore è venuto a conoscenza del
                    recesso stesso. <br /><br />

                    Il fornitore potrà trattenere il rimborso fino al ricevimento del prodotto reso oppure finché
                    l'acquirente non avrà fornito prova di averlo restituito, a seconda di quale situazione si verifichi
                    prima.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    8) Spedizioni (Tempi e Costi)
                </div>
                <div>
                    I costi di consegna verranno indicati al Cliente prima dell’inoltro dell’ordine di acquisto. Il
                    fornitore provvederà a recapitare i beni acquistati dell’acquirente tramite Shopper o Corriere, con
                    tracciatura ove previsto. <br /><br />

                    Se il Cliente ha fatto gli acquisti su Supermercato.casa in un CAP coperto dal servizio, i tempi di
                    consegna sono quelli scelti dal cliente al momento della finalizzazione dell’ordine. L’orario di
                    consegna è scelto dal cliente tra una delle fasce orarie proposte da Supermercato.casa e disponibili
                    al momento della finalizzazione dell’ordine. <br /><br />

                    Nel caso di acquisti effettuati dal Cliente con consegna in CAP non coperti dal servizio, allora i
                    tempi saranno tendenzialmente entro 2 (due) giorni lavorativi dalla data di ricevimento ordine e
                    consegnati tramite un corriere nazionale scelto da Supermercato.casa di volta in volta. <br /><br />

                    La merce si considera correttamente consegnata da Supermercato.casa, sia nel caso venga spedita
                    direttamente al Cliente o a persona dallo stesso delegata, sia nel caso venga recapitata a una dei
                    nostri Punti di Ritiro indicati dall’acquirente per la ricezione al momento della finalizzazione
                    dell’ordine. Con la consegna della merce nelle modalità sopra indicate, il fornitore non è più
                    responsabile di danneggiamenti e/o deterioramenti e/o altri danni subiti dalla merce,
                    successivamente alla consegna.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    10) Prezzi
                </div>
                <div>
                    Tutti i prezzi di vendita dei prodotti esposti e indicati l'interno del sito www.supermercato.casa
                    sono espressi in euro e costituiscono offerta al pubblico ai sensi del 1336 codice
                    civile. <br /><br />

                    I prezzi sono già comprensivi di Iva e di ogni altra eventuale imposta; i costi di spedizione e gli
                    eventuali oneri accessori, se presenti, pur non ricompresi nei prezzi di acquisto saranno indicati e
                    calcolati dal fornitore nella procedura di acquisto prima dell'inoltro dell'ordine da parte
                    dell'acquirente e altresì contenuti nella pagina Web di riepilogo dell'ordine effettuato. <br /><br />

                    I prezzi indicati in corrispondenza di ciascuno dei beni offerti al pubblico sono costantemente
                    aggiornati sul sito web www.supermercato.casa al quale si rimanda per determinarne la validità o la
                    variazione.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    11) Disponibilità dei prodotti
                </div>
                <div>
                    Il fornitore assicura tramite il portale supermercato.casa, l’utilizzo, l’elaborazione e l’evasione
                    degli ordini nei tempi e modi precedentemente definiti. <br /><br />

                    A tale scopo propone nel proprio catalogo on-line i prodotti disponibili ma ci potranno, tuttavia,
                    essere dei tempi tecnici necessari per allineare/aggiornare il catalogo on-line con la disponibilità
                    oltre che il verificarsi di possibili rotture di stock sul Punto vendita con funzione di hub per
                    quel determinato CAP per cui, potrebbe verificarsi la mancata evasione di un determinato articolo.
                    Supermercato.casa propone al cliente come preferisce sopperire a tale mancanza, facendo scegliere al
                    Cliente la modalità di eventuale sostituzione dell’articolo. <br /><br />

                    Il sistema informatico del fornitore inoltre conferma l'avvenuta evasione dell’ordine inoltrando
                    all'utente mediante posta elettronica, la lista dei prodotti acquistati ed effettivamente messi in
                    consegna.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    12) Limitazione di responsabilità
                </div>
                <div>
                    Il fornitore non assume alcuna responsabilità per disservizi imputabili a cause di forza maggiore
                    nel caso non riesca a dare esecuzione all'ordine nei tempi previsti dal contratto. <br /><br />

                    Il fornitore non potrà ritenersi responsabile verso l’acquirente, salvo i casi di dolo o colpa
                    grave, per disservizio o malfunzionamento della rete Internet al di fuori del controllo proprio o
                    dei suoi sub fornitori. <br /><br />

                    Il fornitore non sarà inoltre responsabile in merito ai danni, perdite e costi subiti
                    dell'acquirente a seguito della mancata esecuzione del contratto per cause a lui non imputabili,
                    avendo l'acquirente diritto soltanto alla restituzione integrale del prezzo corrisposto e degli
                    eventuali oneri accessori sostenuti. <br /><br />

                    Il fornitore non assume una responsabilità per l’eventuale uso fraudolento e/o illecito che possa
                    essere fatto da parte di terzi delle carte di credito e altri mezzi di pagamento, all’atto del
                    pagamento dei prodotti acquistati, dal momento che sin d’ora dichiara che si impegna ad adottare
                    tutte le cautele possibili.
                </div>
                <br />
                <div className="condition-title-sub-header">
                    13) DIRITTO DI RECESSO
                </div>
                <div>
                    I diritti del Cliente sono tutelati dal D. Lgs 206/2005 s.m.i.. Il Cliente ha, pertanto, il diritto
                    di recedere dal contratto, anche parzialmente, senza spiegazioni e senza aggravio di spese, a
                    condizione che il recesso sia comunicato mediante raccomandata A/R a Chiama Srl, Via Monte Circeo,
                    12 00015 – Monterotondo (RM), o via posta elettronica certificata (pec) all’indirizzo
                    <a className="mail-to-links"
                        href="mailto: info@supermercato.casa"> chiama_srl@pec.it</a> entro 14 giorni lavorativi dal
                    giorno
                    del
                    ricevimento dei prodotti. La
                    comunicazione può essere anticipata, entro lo stesso termine, tramite e-mail (all'indirizzo
                    <a className="mail-to-links"
                        href="mailto: info@supermercato.casa"> servizioclienti@supermercato.casa</a>), a condizione che
                    sia
                    confermata
                    mediante raccomandata A/R o pec
                    entro le 48 ore successive. Tale comunicazione dovrà specificare la volontà di recedere
                    dall'acquisto ed il prodotto o i prodotti per i quali si intende esercitare il diritto di recesso,
                    allegando copia della fattura o documento fiscale con il quale la merce è stata precedentemente
                    consegnata dal fornitore. <br /><br />

                    La modalità di riconsegna dei prodotti sarà comunicata dal fornitore al Cliente a seguito del
                    ricevimento della richiesta di recesso, rimanendo le spese di trasporto a carico del Cliente, fermo
                    restando che il fornitore si impegna ad utilizzare il metodo di riconsegna più economico
                    possibile. <br /><br />

                    Se il recesso sarà stato esercitato conformemente alle modalità descritte, Chiama srl (o chi ha
                    beneficiato del pagamento) provvederà a rimborsare la somma versata per l'acquisto del prodotto
                    entro 14 giorni dalla data in cui Chiama Srl è venuta a conoscenza dell'esercizio del diritto di
                    recesso da parte del Cliente. L'importo rimborsato sarà al netto delle spese di consegna. Il Cliente
                    sarà responsabile della diminuzione di valore del bene restituito risultante da una manipolazione
                    diversa da quella necessaria per stabilire la natura, le caratteristiche e il funzionamento del bene
                    stesso. <br /><br />

                    Fermo quanto sopra, il diritto di recesso non è consentito nel caso di:
                    <br /><br />
                    - acquisto di prodotti confezionati sigillati, aperti dal Cliente;<br />
                    - di beni confezionati su misura o personalizzati (ad es. prodotti di gastronomia);<br />
                    - di beni che per loro natura non possono essere rispediti o rischiano di deteriorarsi o alterarsi
                    rapidamente (ad es. i prodotti alimentari freschi e deperibili come i prodotti di gastronomia);<br />
                    - abbigliamento intimo;<br />
                    - prodotti che non possono essere rimessi sul mercato;<br />
                    - carte prepagate e/o ricariche telefoniche che siano state attivate dal Cliente;<br />

                    Nel caso la vendita sia stata effettuata, attraverso il portale supermercato.casa, da un Partner di
                    Chiama Srl, che ha provveduto sia alla fatturazione che all’incasso, sarà lo stesso Partner a
                    fornire modalità di riconsegna del bene e del rimborso, sollevando Chiama Srl da qualsiasi onere in
                    merito.

                </div>
                <br />
                <div className="condition-title-sub-header">
                    14 ) SOSPENSIONE DEL SERVIZIO
                </div>
                <div>
                    In caso di gravi inadempimenti da parte del Cliente (a titolo esemplificativo ma non esaustivo:
                    mancato pagamento della spesa effettuata, assenza ripetuta del Cliente al momento della consegna
                    della spesa, non corretto utilizzo di modalità di effettuazione della spesa), Chiama Srl, previa
                    comunicazione scritta al Cliente, si riserva la facoltà di sospendere il servizio.
                </div>
                <br /><br />
                <div className="condition-title-sub-header">
                    15) RESPONSABILITÀ
                </div>
                <div>
                    Chiama Srl garantisce il rispetto della catena del freddo ed, in generale delle norme di qualità
                    relative ai prodotti (in particolare alimentari) esclusivamente fino al momento della consegna
                    presso il luogo indicato dal Cliente; è esclusa ogni responsabilità relativa al cattivo stato dei
                    prodotti dovuta ad impropria conservazione successiva al momento della consegna. Chiama Srl declina
                    ogni responsabilità per danni diretti o indiretti di qualunque natura essi siano o sotto qualunque
                    forma si manifestino, conseguenti all'utilizzo del servizio di videochat per la spesa assistita, in
                    quanto con l’utilizzo dello stesso il Cliente si impegna a non registrare e divulgare in alcun modo
                    i video effettuati durante l’uso del servizio stesso.

                </div>

            </div>
        );
    }
}

export default TermsAndCondition;