import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form'
import { Form } from "react-bootstrap";
import { Translation } from 'react-i18next';
import ValidateInput from "../../components/UI/Input/ValidateInput";
import storesSignup from "./Service";
import swal from "../../utils/swal";

import "../Recruiting/recruiting.styles.scss"

function StoresSignup(props) {

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const [hasDelivery, setHasDelivery] = useState(true);

    useEffect(() => {
        register(
            { name: "hasDelivery" },
        )
        setValue("hasDelivery", hasDelivery);
    }, [register, hasDelivery, setValue])

    // console.log(watch())

    const onSubmit = data => {
        console.log(data);
        storesSignup(data).then(response => {
            console.log(response);
            swal.success("Richiesta effettuata con successo");
        }).catch((error) => {
            swal.error("Errore durante l'elaborazione della richiesta");
        })
    }

    return (
        <Translation>
            {t =>
                <div className="recruiting">
                    <div className="recruiting-title">Hai un’attività?</div>
                    <div className="recruiting-subtitle">
                        <div>
                            Se hai un Punto vendita o una azienda che si occupa di vendita al dettaglio, noi di <span className="supermercato">SUPERMERCATO.CASA</span> siamo pronti a  condividere con te un nuovo modello di fare la spesa.
                        </div>
                        <div>
                            Vogliamo creare l’e-commerce  di quartiere dando la possibilità al cliente di vivere il Punto Vendita sia on line, sia come punto vendita fisico, creando un modo felice di fare la spesa.
                        </div>
                        <div>
                            Ti mettiamo a disposizione la tecnologia e se ne hai bisogno, i nostri personal shopper, il tutto condiviso senza nessun investimento.
                        </div>
                        Compila il form, sarà un piacere condividere  con te nuovi clienti.
                    </div>
                    <div className="recruiting-card">
                        <div className="recruiting-card-title">Compila il form</div>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                            className={`needs-validation ${errors ? "had-validation" : ""}`}>
                            <div className="row">
                                <div className="col-12 col-md-6 mb-4 mb-md-0">
                                    <ValidateInput
                                        name="companyName"
                                        label="Società*"
                                        type="text"
                                        watch={watch("companyName")}
                                        register={register({
                                            required: "Campo obbligatorio"
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.companyName && errors.companyName.message}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <ValidateInput
                                        name="registeredOfficeAddress"
                                        label="Sede legale*"
                                        type="text"
                                        watch={watch("registeredOfficeAddress")}
                                        register={register({
                                            required: "Campo obbligatorio"
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.registeredOfficeAddress && errors.registeredOfficeAddress.message}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-6 mb-4 mb-md-0">
                                    <ValidateInput
                                        name="vatNumber"
                                        label="P.iva*"
                                        type="text"
                                        watch={watch("vatNumber")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.vatNumber && errors.vatNumber.message}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <ValidateInput
                                        name="tenantName"
                                        label="Insegna punto vendita*"
                                        type="text"
                                        watch={watch("tenantName")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.tenantName && errors.tenantName.message}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 mb-4 mb-md-0">
                                    <ValidateInput
                                        name="storeAddress"
                                        label="Indirizzo punto vendita*"
                                        type="text"
                                        watch={watch("storeAddress")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.storeAddress && errors.storeAddress.message}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <ValidateInput
                                        name="storeSize"
                                        label="Metri quadrati punto vendita"
                                        type="text"
                                        watch={watch("storeSize")}
                                        register={register}
                                    />
                                    {/* <div className="form-control-invalid">
                                        {errors.city && errors.city.message}
                                    </div> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <ValidateInput
                                        name="referentName"
                                        label="Nome del referente*"
                                        type="text"
                                        watch={watch("referentName")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.referentName && errors.referentName.message}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 mb-4 mb-md-0">
                                    <ValidateInput
                                        name="phoneNumber"
                                        label="Telefono*"
                                        type="text"
                                        watch={watch("phoneNumber")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            pattern: {
                                                value: /^\d{10}$/i,
                                                message: "Numero di cellulare non valido"
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.phoneNumber && errors.phoneNumber.message}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <ValidateInput
                                        name="email"
                                        label="Email*"
                                        type="email"
                                        watch={watch("email")}
                                        register={register({
                                            required: "Campo obbligatorio",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Indirizzo email non valido"
                                            }
                                        })}
                                    />
                                    <div className="form-control-invalid">
                                        {errors.email && errors.email.message}
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginBottom: "unset" }} className="row">
                                <div className="col-12">
                                    <div className="radio-buttons-title">Il punto vendita ha la possibilità di effttuare consegne?*</div>
                                    <div className="radio-buttons">
                                        <div className="custom-control custom-radio">
                                            <input type="radio" className="custom-control-input"
                                                id="hasDelivery"
                                                name="hasDelivery"
                                                value={hasDelivery}
                                                checked={hasDelivery === true}
                                                onChange={() => setHasDelivery(true)}
                                            />
                                            <label className="custom-control-label" htmlFor="hasDelivery" />
                                            <span style={{ marginRight: "45px" }}>Si</span>
                                        </div>
                                        <div className="custom-control custom-radio">
                                            <input type="radio" className="custom-control-input"
                                                id="notHasDelivery"
                                                name="notHasDelivery"
                                                value={hasDelivery}
                                                checked={hasDelivery === false}
                                                onChange={() => setHasDelivery(false)}
                                            />
                                            <label className="custom-control-label" htmlFor="notHasDelivery" />
                                            <span>No</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="radio-buttons-title">Se il tuo punto vendita non è un Supermercato, scrivi qui sotto la tipologia:</div>
                            <div className="row">
                                <div className="col-12">
                                    <Form.Control
                                        as="textarea"
                                        id="storeType"
                                        name="storeType"
                                        ref={register}
                                        className="textarea w-100"
                                        rows="6"
                                        placeholder="Esempio: Negozio di quartiere, negozio specializzato in…"
                                    />
                                </div>
                            </div>
                            <div className="text-right">
                                <button type="submit" className="cta">
                                    <span>Invia</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </Translation>
    );
}

export default StoresSignup;
