import React, { useState, useEffect } from "react"
import { useForm } from 'react-hook-form'
import { Translation } from 'react-i18next';
import ValidateInput from "../../components/UI/Input/ValidateInput";
import SignupDatepicker from "../Signup/SignupDatepicker";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import recruite from "./Service";
import swal from "../../utils/swal";

import "./recruiting.styles.scss"
import { Link } from "react-router-dom";

function Recruiting(props) {

    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    const [birthDate, setBirthdate] = useState();
    const [smartphone, setSmartphone] = useState(true);
    const [platform, setPlatform] = useState("ANDROID");

    useEffect(() => {
        register(
            { name: "birthDate" },
            { required: "Campo obbligatorio" },
        )
        register(
            { name: "smartphone" },
        )
        register(
            { name: "platform" },
        )
        setValue("birthDate", birthDate);
        setValue("smartphone", smartphone);
        setValue("platform", platform);
    }, [register, birthDate, smartphone, platform, setValue])

    const handleChange = date => {
        setValue("birthDate", date);
        setBirthdate(date);
        if (date) {
            errors.birthDate = {};
            errors.birthDate.message = "";
        } else {
            errors.birthDate = {};
            errors.birthDate.message = "Campo obbligatorio";
        }
    }

    // console.log(watch())

    const onSubmit = data => {
        console.log(data);
        recruite(data).then(response => {
            console.log(response);
            swal.success("Richiesta effettuata con successo");
        }).catch((error) => {
            swal.error("Errore durante l'elaborazione della richiesta");
        })
    }

    return (
        <Translation>
            {t =>
                <>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link className="link" to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active><b>Lavora con noi</b></Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="recruiting">
                        <div className="recruiting-title">Lavora con noi</div>
                        <div className="recruiting-subtitle">
                            <div className="bold">
                                Stai cercando un lavoro con orari flessibili  e dove puoi dare il tuo valore aggiunto?
                            </div><br />
                            <div>
                                Potrai decidere tu quanto guadagnare, sia nel preparare la spesa, sia nel fare le consegne e tieni conto che le mance sono tutte tue.
                            </div>
                            <div>
                                Potrai diventare il punto di riferimento nella tua zona per la spesa e i servizi del tuo quartiere.
                            </div>
                            <div>
                                Potrai inserire tu stesso le attività che pensi possano essere di valore aggiunto per i clienti  traendo profitto.
                            </div>
                            Se tutto questo ti stimola siamo pronti a farti entrare nella  grande famiglia di <div className="supermercato">SUPERMERCATO.CASA</div>
                        </div>
                        <div className="recruiting-card">
                            <div className="recruiting-card-title">Compila il form</div>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                                className={`needs-validation ${errors ? "had-validation" : ""}`}>
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                                        <ValidateInput
                                            name="name"
                                            label="firstName"
                                            type="text"
                                            watch={watch("name")}
                                            register={register({
                                                required: "Campo obbligatorio"
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.name && errors.name.message}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <ValidateInput
                                            name="surname"
                                            label="lastName"
                                            type="text"
                                            watch={watch("surname")}
                                            register={register({
                                                required: "Campo obbligatorio"
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.surname && errors.surname.message}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                                        <ValidateInput
                                            name="phoneNumber"
                                            label="Numero di telefono*"
                                            type="text"
                                            watch={watch("phoneNumber")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                pattern: {
                                                    value: /^\d{10}$/i,
                                                    message: "Numero di cellulare non valido"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.phoneNumber && errors.phoneNumber.message}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <ValidateInput
                                            name="email"
                                            label="Indirizzo email*"
                                            type="email"
                                            watch={watch("email")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Indirizzo email non valido"
                                                }
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.email && errors.email.message}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 mb-4 mb-md-0">
                                        <SignupDatepicker
                                            label="Data di nascita*"
                                            onChange={handleChange}
                                            value={birthDate}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.birthDate && errors.birthDate.message}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <ValidateInput
                                            name="preferredCity"
                                            label="In quale città vorresti lavorare?*"
                                            type="text"
                                            watch={watch("preferredCity")}
                                            register={register}
                                        />
                                        {/* <div className="form-control-invalid">
                                    {errors.city && errors.city.message}
                                </div> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <ValidateInput
                                            name="availableTransport"
                                            label="Quale mezzo hai a disposizione?*"
                                            type="text"
                                            watch={watch("availableTransport")}
                                            register={register({
                                                required: "Campo obbligatorio",
                                            })}
                                        />
                                        <div className="form-control-invalid">
                                            {errors.availableTransport && errors.availableTransport.message}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="radio-buttons-title">Possiedi uno smartphone?*</div>
                                        <div className="radio-buttons">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input"
                                                    id="hadSmartphone"
                                                    name="hadSmartphone"
                                                    value={smartphone}
                                                    checked={smartphone === true}
                                                    onChange={() => setSmartphone(true)}
                                                />
                                                <label className="custom-control-label" htmlFor="hadSmartphone" />
                                                <span style={{ marginRight: "45px" }}>Si</span>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input"
                                                    id="notHadSmartphone"
                                                    name="notHadSmartphone"
                                                    value={smartphone}
                                                    checked={smartphone === false}
                                                    onChange={() => setSmartphone(false)}
                                                />
                                                <label className="custom-control-label" htmlFor="notHadSmartphone" />
                                                <span>No</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        {smartphone &&
                                            <>
                                                <div className="radio-buttons-title">
                                                    Quale sistema operativo?*
                                            </div>
                                                <div className="radio-buttons justify-content-between">
                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input"
                                                            id="Android"
                                                            name="Android"
                                                            value={platform}
                                                            checked={platform === "ANDROID"}
                                                            onChange={() => setPlatform("ANDROID")}
                                                        />
                                                        <label className="custom-control-label" htmlFor="Android" />
                                                        <span>Android</span>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input"
                                                            id="iOS"
                                                            name="iOS"
                                                            value={platform}
                                                            checked={platform === "IOS"}
                                                            onChange={() => setPlatform("IOS")}
                                                        />
                                                        <label className="custom-control-label" htmlFor="iOS" />
                                                        <span>iOS</span>
                                                    </div>
                                                    <div className="custom-control custom-radio">
                                                        <input type="radio" className="custom-control-input"
                                                            id="others"
                                                            name="others"
                                                            value={platform}
                                                            checked={platform === "OTHERS"}
                                                            onChange={() => setPlatform("OTHERS")}
                                                        />
                                                        <label className="custom-control-label" htmlFor="others" />
                                                        <span>Altro</span>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="text-right">
                                    <button type="submit" className="cta">
                                        <span>Invia</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            }
        </Translation>
    );
}

export default Recruiting;
