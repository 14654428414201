import React from "react";
import Row from "react-bootstrap/Row"
// import PaymentsLogo from "../../../../images/payment_circuits.png"
import payments from "./Service"
import { paymentsService } from "../../../Payments/Service"
import i18n from "../../../../i18n"
import { Translation } from 'react-i18next';
import { Link } from "react-router-dom";
import { connect } from "react-redux"

class PaymentsMode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentsMode: [],
            isBoarded: false
        };
    }

    componentDidMount() {
        const { deliveryMode, storeId } = this.props;
        payments(deliveryMode, storeId)
            .then(response => {
                this.setState({
                    paymentsMode: response.data,
                })
            }).catch((error) => {
                console.log(error);
            })

        paymentsService.paymenthMethods()
            .then(response => {
                if (response.data.length > 0) {
                    response.data.forEach(method => {
                        if (method.paymentMethodType === "STRIPE") {
                            this.setState({ isBoarded: true })
                        }
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    renderButtons = (paymentMode) => {
        const { paymentMethod, setPaymentMethod } = this.props;
        switch (paymentMode) {
            case "CASH":
                return (
                    <div className="d-flex">
                        <div className="radio-button mr-3">
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="CASH"
                                    name="CASH"
                                    value="CASH"
                                    checked={paymentMethod === "CASH"}
                                    onChange={() => setPaymentMethod("CASH")}
                                />
                                <label className="custom-control-label" style={{ textTransform: "uppercase" }} htmlFor="CASH">
                                    {i18n.t("paymentMode.cash.title")}
                                </label>
                            </div>
                        </div>
                    </div>
                );
            case "BANCOMAT":
                return (
                    <div className="d-flex">
                        <div className="radio-button mr-3">
                            <div className="custom-control custom-radio">
                                <input type="radio" className="custom-control-input"
                                    id="BANCOMAT"
                                    name="BANCOMAT"
                                    value="BANCOMAT"
                                    checked={paymentMethod === "BANCOMAT"}
                                    onChange={() => setPaymentMethod("BANCOMAT")}
                                />
                                <label className="custom-control-label" style={{ textTransform: "uppercase" }} htmlFor="BANCOMAT">
                                    {i18n.t("paymentMode.bancomat.title")}
                                </label>
                            </div>
                        </div>
                    </div>
                );
            case "STRIPE":
                if (this.state.isBoarded) {
                    return (
                        <div className="d-flex">
                            <div className="radio-button mr-3">
                                <div className="custom-control custom-radio">
                                    <input type="radio" className="custom-control-input"
                                        id="STRIPE"
                                        name="STRIPE"
                                        value="STRIPE"
                                        checked={paymentMethod === "STRIPE"}
                                        onChange={() => setPaymentMethod("STRIPE")}
                                    />
                                    <label className="custom-control-label" style={{ textTransform: "uppercase" }} htmlFor="STRIPE">
                                        {i18n.t("paymentMode.stripe.title")}
                                    </label>
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <Link style={{ width: "100%" }} to="/payments">
                            <div>
                                <span className="addMethod">
                                    Aggiungi metodo di pagamento online
                                </span>
                            </div>
                        </Link>
                    );
                }

            default: return null;
        }
    }

    render() {
        const { paymentsMode } = this.state;
        return (
            <Translation>
                {t =>
                    <div style={{ marginBottom: "40px" }} className="payments-mode w-100">
                        <div className="checkout-data-title">{t("checkout.paymentsMode.title")}</div>
                        <div className="container-fluid">
                            {paymentsMode && paymentsMode.map(paymentMode => (
                                <Row style={{ marginBottom: "15px" }} key={paymentMode}>
                                    {this.renderButtons(paymentMode)}
                                </Row>
                            ))}
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        storeId: state.user.storeId,
    };
}

export default connect(mapStateToProps)(PaymentsMode);


