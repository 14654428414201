import React from "react";
import { Translation } from 'react-i18next';
import { withRouter } from "react-router-dom"
import GoBackButton from "../../../components/UI/Buttons/GoBackButton";

class CheckoutHeader extends React.Component {
    render() {
        // define css classes depending on current path
        const currentPath = this.props?.history?.location?.pathname;
        const { isAssistedShop, isFullAssistedShop } = this.props;
        return (
            <Translation>
                {t =>
                    <>
                        <GoBackButton goBack={() => { this.props.history.goBack() }}>
                            {t("goBack")}
                        </GoBackButton>
                        <div className="checkout-header">
                            <div
                                className="d-flex justify-content-between checkout-controls"
                                style={(isAssistedShop || isFullAssistedShop) ? { maxWidth: "800px" } : null}
                            >
                                {isFullAssistedShop &&
                                    <span
                                        className={`${currentPath === "/checkout/create" ?
                                            "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                            checkout-controls-step`}>
                                        {t("assisted_create")}
                                    </span>
                                }
                                {(isAssistedShop || isFullAssistedShop) &&
                                    <span
                                        className={`${currentPath === "/checkout/assisted" ?
                                            "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                            checkout-controls-step`}>
                                        {t("assisted_availability")}
                                    </span>
                                }
                                <span
                                    className={`${currentPath === "/checkout" ?
                                        "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                    {t("availability")}
                                </span>
                                <span
                                    className={`${currentPath === "/checkout/data" ?
                                        "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                    {t("summary")}
                                </span>
                                <span
                                    className={`${currentPath === "/checkout/confirm" ?
                                        "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                    {t("confirm")}
                                </span>
                            </div>
                        </div>
                    </>
                }
            </Translation>
        );
    }
}

export default withRouter(CheckoutHeader);