import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
// import { checkResponse } from "../../utils/checkResponse";

export default function recruite(data) {
    return (
        fetch(`${config.baseUrl}/cli/recruiting/private`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            body: JSON.stringify({
                name: data.name,
                surname: data.surname,
                phoneNumber: data.phoneNumber,
                email: data.email,
                preferredCity: data.preferredCity,
                availableTransport: data.availableTransport,
                hasSmartphone: data.smartphone,
                smartphoneOs: data.platform,
                birthDate: data.birthDate
            })
        })
            .then(handleResponse)
            // .then(checkResponse)
    );
}
