import React from "react";
import StoresBanner from "../../images/stores-banner.png"
import Slider from "react-slick";
import StoreCard from "../LandingPage/LandingStores/StoreCard/StoreCard"
import FeaturedProducts from "../Home/FeaturedProducts/FeaturedProducts"
import { storesService } from "../../services/basis/StoresService";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions"
import { Redirect } from "react-router-dom";
import config from "../../config/config";

import "./search-stores.styles.scss"

class SearchStores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cap: this.props.match.params.cap,
            dragging: false,
            stores: [],
            isLoaded: false
        }
    }

    componentDidMount() {
        this.props.setStore(config.DEFAULT_STORE_ID, config.DEFAULT_TID, null);
        storesService.storesByZipcode(this.state.cap)
            .then(response => {
                this.setState({
                    stores: response.data,
                    isLoaded: true,
                })
            }).catch((error) => {
                console.log(error);
            })
    }

    setDragging = (isDragging) => {
        this.setState({ dragging: isDragging });
    }

    render() {
        const { cap, dragging, stores } = this.state;
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            beforeChange: () => this.setDragging(true),
            afterChange: () => this.setDragging(false),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        };

        if (cap === "null") {
            return <Redirect to='/' />
        }

        return (
            <div className="search-stores">
                <div className="search-stores-banner" style={{ backgroundImage: `url(${StoresBanner})` }} alt="">
                    <div className="text text-center">
                        <span> In base al tuo cap <span className="cap">{cap}</span> sono disponibili questi negozi</span>
                    </div>
                </div>
                <div className="search-stores-list">
                    <Slider {...settings}>
                        {stores && stores.map(store => (
                            <StoreCard key={store.id} store={store} dragging={dragging} setStore={this.props.setStore} />
                        ))}
                    </Slider>
                </div>
                <div className="search-stores-products">
                    <div className="text-center">
                        <span className="title">
                            Prodotti in offerta del supermercato
                        </span>
                    </div>
                    <FeaturedProducts forcedStore="001" />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setStore: (storeId, tid, enabledAidedShop) => dispatch(actionCreators.setStore(storeId, tid, enabledAidedShop)),
    };
}

export default connect(null, mapDispatchToProps)(SearchStores);


