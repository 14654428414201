import config from "../../config/config";
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";
import { reduxStore } from "../../redux/store";

export const productsService = { info, fetchByBarcode }

function info(barcode, ref, storeId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/products/barcode/${barcode}/info?storeId=${storeId}&ref=${ref}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchByBarcode(barcode) {
    const reducer = reduxStore.getState().user;
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/products/barcode/${barcode}?store=${reducer.storeId}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}