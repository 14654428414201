import React from "react";
import Slider from "react-slick";
import { ReactComponent as Step1 } from '../../../images/Step1.svg';
import { ReactComponent as Step2 } from '../../../images/Step2.svg';
import { ReactComponent as Step3 } from '../../../images/Step3.svg';
import { ReactComponent as Step4 } from '../../../images/Step4.svg';
import { ReactComponent as ArrowLeft } from "../../../images/arrow_left.svg"
import { ReactComponent as ArrowRight } from "../../../images/arrow_right.svg"

import "./tutorial.styles.scss"

export default class Tutorial extends React.Component {
    render() {
        return (
            <div className="tutorial">
                <h1 className="tutorial-title">Come funziona</h1>
                <Slider {...settings}>
                    <div className="tutorial-step outline">
                        <div className="tutorial-step-container">
                            <Step1 />
                            <div className="tutorial-step-title">
                                <span>Inserisci <br /> il tuo cap</span>
                            </div>
                        </div>
                        <div className="tutorial-step-description">
                            Inserisci il CAP e verifica i Punti Vendita nella tua zona
                        </div>
                    </div>
                    <div className="tutorial-step">
                        <Step2 />
                        <div className="tutorial-step-title">
                            <span>Registrati <br /> o accedi</span>
                        </div>
                        <div className="tutorial-step-description">
                            Registrati ed accedi per fare la tua spesa
                        </div>
                    </div>
                    <div className="tutorial-step">
                        <Step3 />
                        <div className="tutorial-step-title">
                            <span>Ricevi <br /> la spesa</span>
                        </div>
                        <div className="tutorial-step-description">
                            Ricevi la spesa dove vuoi tu all'orario più comodo
                        </div>
                    </div>
                    <div className="tutorial-step">
                        <Step4 />
                        <div className="tutorial-step-title">
                            <span>Paghi online <br />o al corriere</span>
                        </div>
                        <div className="tutorial-step-description">
                            Puoi pagare sia on-line che alla consegna, con carta o Bancomat nei Punti vendita dove è abilitato il servizio
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 0,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: true,
                nextArrow: <SampleArrow><ArrowRight /></SampleArrow>,
                prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>
            }
        },
    ]
};

function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
}

