import React from "react";
import StoreCard from "./StoreCard/StoreCard.js"
import { storesService } from "../../../services/basis/StoresService";
import { connect } from "react-redux"
import * as actionCreators from "../../../redux/store/actions"

import "./landing-stores.styles.scss"

class LandingStores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stores: [],
        }
    }

    componentDidMount() {
        storesService.storesByZipcode("00000")
            .then(response => {
                this.setState({
                    stores: response.data,
                })
            }).catch((error) => {
                console.log(error);
            })
    }


    clickStore = () => {

    }

    render() {
        const { stores } = this.state;
        return (
            <div className="landing-stores">
                <h1 className="landing-stores-title">Alcuni nostri negozi</h1>
                <div className="row justify-content-center">
                    {stores && stores.map(store => (
                        <div key={store.id} className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                            <StoreCard
                                key={store.id}
                                store={store}
                                setStore={this.props.setStore}
                                setCap={this.props.setCap}
                                cap={null}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setStore: (storeId, tid, enabledAidedShop) => dispatch(actionCreators.setStore(storeId, tid, enabledAidedShop)),
        setCap: (cap) => dispatch(actionCreators.setCap(cap)),
    };
}

export default connect(null, mapDispatchToProps)(LandingStores);

