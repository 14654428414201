// ecommerce gtag (not GA4)
const gtag = window.gtag;
const fbq = window.fbq;

export const productImpression = (products) => {
  window.dataLayer.push({
    ecommerce: {
      currencyCode: 'EUR',
      impressions: products && products.map((item, index) => {
        return {
          name: item.description,
          id: item.ref,
          price: item.netPrice,
          category: item.categoryId,
          position: index + 1,
          // brand: 'Google',
          // variant: 'Gray',
          // list: 'Search Results',
        }
      })
    }
  })
  gtag('event', 'view_item_list', {
    items: products && products.map((item, index) => {
      return {
        id: item.ref,
        name: item.description,
        price: item.netPrice,
        category: item.categoryId,
        position: index + 1,
      }
    })
  })
};

export const productClick = (item) => {
  window.dataLayer.push({
    event: 'productClick',
    ecommerce: {
      click: {
        products: [{
          name: item.description,
          id: item.ref,
          price: item.netPrice,
          category: item.categoryId,
        }]
      }
    },
    eventCallback: function () {
      console.log(item);
    }
  });
  fbq('track', 'ViewContent', {
    content_ids: item.ref,
    value: item.netPrice,
    currency: "EUR",
    content_name: item.description,
    content_category: item.categoryId
  });
};

export const addToCart = (item) => {
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [{
          name: item.description,
          id: item.ref,
          price: item.netPrice,
          category: item.categoryId,
          quantity: item.amount
        }]
      }
    }
  });
  gtag('event', 'add_to_cart', {
    items: [{
      id: item.ref,
      name: item.description,
      price: item.netPrice,
      category: item.categoryId,
      quantity: item.amount
    }]
  });
};

export const checkout = (cart) => {
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      checkout: {
        // actionField: { 'step': 1, 'option': 'Visa' },
        products: cart && cart.items && cart.items.map((item, index) => {
          return {
            name: item.description,
            id: item.ref,
            price: item.netPrice,
            category: item.categoryId,
            position: index + 1
          }
        })
      }
    },
    eventCallback: function () {
      // console.log(cart);
    }
  });
  gtag('event', 'begin_checkout', {
    items: cart && cart.items && cart.items.map((item, index) => {
      return {
        id: item.ref,
        name: item.description,
        price: item.netPrice,
        category: item.categoryId,
        position: index + 1
      }
    }),
    "coupon": ""
  });
}

export const transaction = (order) => {
  window.dataLayer.push({
    ecommerce: {
      purchase: {
        actionField: {
          id: order.cart && order.cart.payment && order.cart.payment.id,
          affiliation: order.cart && order.cart.storeDescription,
          revenue: order.cart && order.cart.totalToPay,
          shipping: order.cart && order.cart.feesTotal,
        },
        products: order.cart && order.cart.items && order.cart.items.map((item, index) => {
          return {
            name: item.description,
            id: item.ref,
            price: item.rowNetPrice,
            category: item.categoryId,
            position: index + 1
          }
        })
      }
    }
  });
}

export const transactionFreeze = (cart) => {
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: cart && cart.payment && cart.payment.id,
          affiliation: cart && cart.storeDescription,
          revenue: cart && cart.totalToPay,
          shipping: cart && cart.feesTotal,
        },
        products: cart && cart.items && cart.items.map((item, index) => {
          return {
            name: item.description,
            id: item.ref,
            price: item.rowNetPrice,
            category: item.categoryId,
            position: index + 1
          }
        })
      }
    }
  });
  // gtag('event', 'purchase', {
  //   "transaction_id": "24.031608523954162",
  //   "affiliation": "Google online store",
  //   "value": 23.07,
  //   "currency": "USD",
  //   "tax": 1.24,
  //   "shipping": 0,
  //   "items": [
  //     {
  //       "id": "P12345",
  //       "name": "Android Warhol T-Shirt",
  //       "list_name": "Search Results",
  //       "brand": "Google",
  //       "category": "Apparel/T-Shirts",
  //       "variant": "Black",
  //       "list_position": 1,
  //       "quantity": 2,
  //       "price": '2.0'
  //     },
  //     {
  //       "id": "P67890",
  //       "name": "Flame challenge TShirt",
  //       "list_name": "Search Results",
  //       "brand": "MyBrand",
  //       "category": "Apparel/T-Shirts",
  //       "variant": "Red",
  //       "list_position": 2,
  //       "quantity": 1,
  //       "price": '3.0'
  //     }
  //   ]
  // });
}

export const signup = (item) => {
  window.dataLayer.push({
    event: 'signup',
    profile: item,
    eventCallback: function () {
      console.log(item);
    }
  });
};

// export const ecommerceProductImpressions = (products) => {
//   gtag('event', 'view_item_list', {
//     items: products && products.map((item, index) => {
//       return {
//         id: item.ref,
//         name: item.description,
//         price: item.netPrice,
//         category: item.categoryId,
//         position: index + 1,
//       }
//     })
//   })
// }

// export const ecommerceProductClick = (item) => {
//   gtag('event', 'select_content', {
//     content_type: "product",
//     items: [
//       {
//         id: item.ref,
//         name: item.description,
//         price: item.netPrice,
//         category: item.categoryId,
//       }
//     ]
//   });
// };

// export const ecommerceAddToCart = (item) => {
//   gtag('event', 'add_to_cart', {
//     items: [
//       {
//         id: item.ref,
//         name: item.description,
//         price: item.netPrice,
//         category: item.categoryId,
//         quantity: item.amount
//       }
//     ]
//   });
// };