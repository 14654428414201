import React from "react"
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import config from "../../../config/config";

class Logged extends React.Component {
    render() {
        const { user, logout, closeAll } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="container-fluid h-100">
                        <Row className="g-padding">
                            <div style={{ paddingTop: "unset" }} className="navigation-view container-fluid">
                                <Row>
                                    <div className="container-fluid">
                                        <div className="text-center">
                                            <h1 className="modal-profile-welcome">{t("welcome")}</h1>
                                            <div className="modal-profile-name">{user.firstName} {user.lastName}</div>
                                        </div>
                                        <Row>
                                            <div className="modal-profile-wrapper w-100">
                                                <div className="container-fluid">
                                                    <Row>
                                                        <Col className="modal-profile-field">
                                                            <Link to="/store/profile" onClick={closeAll} className="modal-link">{t("link.profile")}</Link>
                                                        </Col>
                                                        <div className="w-100 d-block d-md-none"></div>
                                                        <Col className="modal-profile-field">
                                                            <Link to="/orders" onClick={closeAll} className="modal-link">{t("link.orders")}</Link>
                                                        </Col>
                                                        {config.ENABLED_SLIST &&
                                                            <>
                                                                <div className="w-100 d-block d-md-none"></div>
                                                                <Col className="modal-profile-field">
                                                                    <Link to="/smartlist" onClick={closeAll} className="modal-link">{t("link.smartlist")}</Link>
                                                                </Col>
                                                            </>
                                                        }
                                                        {config.ENABLED_PAYMENTS &&
                                                            <>
                                                                <div className="w-100 d-block d-md-none"></div>
                                                                <Col className="modal-profile-field">
                                                                    <Link to="/payments" onClick={closeAll} className="modal-link">{t("link.payments")}</Link>
                                                                </Col>
                                                            </>
                                                        }
                                                        <div className="w-100 d-block d-md-none"></div>
                                                        <Col className="modal-profile-field">
                                                            <button className="button-clean outline" onClick={logout}>
                                                                <span className="modal-link">{t("logout")}</span>
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                            </div>
                        </Row>
                    </div>
                }
            </Translation>
        );
    }
}

export default Logged;
