import React from "react";
import Header from "../Header/ResponsiveHeader";
import Footer from "../Footer/Footer";
import LoadingOverlay from 'react-loading-overlay';
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux"

import Home from '../../../features/Home/Home';
import Cart from "../../../features/Cart/Cart"
import StripeForm from '../../../features/Payments/Stripe/StripeForm';
import CartCreate from "../../../features/CartCreate/CartCreate"
import CartUpdate from "../../../features/CartUpdate/CartUpdate";
import Stores from "../../../features/Stores/Stores"
import Product from "../../../features/Product/Product"
import Checkout from "../../../features/Checkout/Checkout";
import Orders from "../../../features/Orders/Orders";
import Favorites from "../../../features/Favorites/Favorites";
import SearchCategory from "../../../features/SearchCategory/SearchCategory";
import Smartlist from "../../../features/Smartlist/Smartlist";
import NewSearch from "../../../features/NewSearch/NewSearch";
import Payments from "../../../features/Payments/Payments";
import StoreProfileContainer from "../../../features/ProfileContainer/StoreProfileContainer";
import AidedCall from "../../../features/AidedCall/AidedCall";

class MainTemplate extends React.Component {
    render() {
        return (
            <LoadingOverlay
                active={this.props.showOverlay}
                spinner
            >
                <div className="main-container">
                    <Header />
                    <div className="content">
                        <Switch>
                            <Route exact path='/store/profile' component={StoreProfileContainer} />
                            <Route exact path='/store/profile/addresses' component={StoreProfileContainer} />
                            <Route exact path='/store/profile/billing' component={StoreProfileContainer} />
                            <Route exact path='/store/profile/password' component={StoreProfileContainer} />
                            <Route exact path='/store/:storeId' component={Home} />

                            <Route exact path='/cart' component={Cart} />
                            <Route exact path='/cart/create' component={CartCreate} />
                            <Route exact path='/cart/update' component={CartUpdate} />
                            <Route exact path='/stores' component={Stores} />

                            <Route exact path='/search' component={NewSearch} />
                            <Route exact path='/orders' component={Orders} />
                            <Route exact path='/smartlist' component={Smartlist} />
                            <Route exact path='/favorites' component={Favorites} />
                            <Route exact path='/payments' component={Payments} />
                            <Route exact path='/payments/stripe' component={StripeForm} />
                            <Route exact path='/product/:barcode' component={Product} />
                            <Route exact path='/category' component={SearchCategory} />

                            <Route exact path='/checkout' component={Checkout} />
                            <Route exact path='/checkout/data' component={Checkout} />
                            <Route exact path='/checkout/summary' component={Checkout} />
                            <Route exact path='/checkout/confirm' component={Checkout} />

                            {/* Assisted shop routes */}
                            <Route exact path='/checkout/create' component={Checkout} />
                            <Route exact path='/checkout/assisted' component={Checkout} />
                            <Route exact path='/call' component={AidedCall} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = state => {
    return {
        showOverlay: state.user.showOverlay
    };
}

export default connect(mapStateToProps)(MainTemplate);