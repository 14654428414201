import React, { Component } from 'react';

import "./footer-attachments.styles.scss"

class PolicyCookie extends Component {
    render() {
        return (
            <div className="container mb-5 pb-5">
                <div className="terms-condition-header">
                    COOKIE POLICY
                </div>
                <div>
                    La raccolta dati avviene, oltre che tramite form on line, anche mediante cookies tecnici, analitici
                    e di profilazione di terze parti; i cookies di profilazione hanno il solo scopo di conoscere la tua
                    navigazione sul sito ed la raccolta dati ha natura facoltativa. Il rifiuto dell’installazione di
                    cookies di profilazione non impedisce la fruizione dei servizi del sito, tuttavia può determinare
                    difficoltà e rallentamenti nella navigazione, la comparsa di annunci pubblicitari generici, il non
                    completo sfruttamento delle potenzialità del sito nonché altre difficoltà temporanee o rallentamenti
                    nell’utilizzo del sito.

                </div>
                <br />
                <div className="condition-title-header">
                    Cookie
                </div>
                <div>
                    I Cookie permettono un funzionamento efficiente del sito e ne migliorano le prestazioni, danno anche
                    informazioni al proprietario di Supermercato.casa per fini statistici o pubblicitari, principalmente
                    per personalizzare l’esperienza di navigazione ricordando preferenze e modalità di
                    navigazione. <br />
                    Il Cookie è assimilabile ad una tessera identificativa esclusivamente personale, il cui compito è
                    comunicare a Supermercato.casa quando torni. Molti siti web, compreso quello di Supermercato.casa,
                    se ne avvalgono. I Cookie dicono con che frequenza visiti le pagine e questo aiuta a capire quali
                    informazioni possano interessare ai visitatori. In questo modo, possiamo offrire più contenuti che
                    interessano uno specifico comportamento e meno che non sono in linea con quanto potrebbe non
                    interessare all’utente. I Cookie aiutano ad essere più efficiente e a memorizzare preferenze e nomi
                    utente, registrare prodotti e servizi e personalizzare pagine. Se non ci si iscrive a
                    Supermercato.casa e non si inseriscono informazioni personali, l’unica cosa che il server sa è che
                    qualcuno con quel Cookie è tornato a visitarci. Nient’altro.

                </div>
                <br />
                <div className="condition-title-header">
                    Supermercato.casa quali Cookie utilizza e per quali finalità?
                </div>
                <div>
                    Supermercato.casa utilizza diverse tipologie di Cookie e tecnologie affini, ognuna delle quali ha
                    una specifica funzione. <br />
                    Ti forniamo queste informazioni per rispettare il nostro impegno a comunicare le modifiche recenti
                    alla legge e per garantire chiarezza rispetto alla privacy relativa al nostro sito web
                    www.Supermercato.casa.it (“website”). Per ottimizzare l’utilizzo del sito e la completa funzionalità
                    del processo di acquisto online e delle caratteristiche personalizzate, il computer, il tablet o il
                    dispositivo mobile devono accettare i Cookie. La nostra informativa sull’utilizzo dei Cookie
                    fornisce informazioni in merito alla tipologia di Cookie utilizzati e alla modalità di gestione,
                    controllo ed eliminazione.

                </div>
                <br />
                <div className="condition-title-header">
                    Tipologia di Cookie
                </div>
                <div>
                    In Supermercato.casa utilizziamo i Cookie per: tenere traccia degli articoli nel carrello, ricordare
                    i tuoi dati nelle visite successive e ottimizzare la tua esperienza on-line. I Cookie ci consentono,
                    per esempio, di personalizzare alcune sezioni del sito in base alle tue esigenze, presentare offerte
                    mirate, identificare e risolvere eventuali errori. Vorremmo specificare che i Cookie non danneggiano
                    il tuo dispositivo. Normalmente non conserviamo dati identificativi riservati o personali nei Cookie
                    che creiamo. Quando lo facciamo, otteniamo questi dati direttamente da te, ad esempio attraverso
                    informazioni che hai inserito sul nostro sito, e li usiamo unicamente agli scopi indicati
                    nell'informativa sui Cookie e la privacy. Tutte le informazioni raccolte attraverso i Cookie sono
                    conservate in modo sicuro. Collaboriamo, inoltre, con aziende selezionate che possono a loro volta
                    inviare Cookie durante la visita al nostro sito web. Esse utilizzano tali informazioni per
                    presentare offerte di prodotti e servizi di potenziale interesse. È importante tenere presente che
                    non abbiamo alcun accesso o controllo sui Cookie utilizzati dalle nostre aziende selezionate. Non
                    condividiamo alcuna delle informazioni personali raccolte, quali nome, numero di telefono o
                    indirizzo, con tali aziende se non in conformità con quanto descritto dalla nostra informativa sulla
                    privacy.
                </div>
                <br />
                <div className="condition-title-header">
                    Cookie tecnici di funzionamento.
                </div>
                <div>
                    Fin dal primo accesso questi Cookie permettono al sito di funzionare correttamente e ti consentono
                    di visualizzare i contenuti sul tuo dispositivo riconoscendo la lingua e il mercato del Paese dal
                    quale hai scelto di connetterti. Se sei un utente registrato, permetteranno di riconoscerti e di
                    accedere ai servizi offerti delle aree dedicate. I Cookie di navigazione sono Cookie tecnici e sono
                    necessari al funzionamento del sito.

                </div>
                <br />
                <div className="condition-title-header">
                    Cookie tecnici per la navigazione

                </div>
                <div>
                    Questi Cookie permettono, in base alla tua richiesta espressa, di riconoscerti agli accessi
                    successivi in modo da non dover inserire i tuoi dati a ogni visita (ad esempio: “Riconoscimi la
                    prossima volta”). <br />
                    Se hai aggiunto articoli alla tua “Carrello” e chiuso la sessione senza completare l'acquisto e
                    senza eliminarli, questi Cookie ti consentono di continuare lo shopping la volta successiva che
                    accedi al sito (entro un periodo limitato) ritrovando gli articoli selezionati. I Cookie funzionali
                    non sono indispensabili al funzionamento del sito, ma migliorano la qualità e l'esperienza di
                    navigazione, Supermercato.casa li ritiene indispensabili per un'esperienza utente corretta. Un
                    sottoinsieme di questi cookie sono i Cookie tecnici e di statistica aggregata, cookie necessari per
                    attività strettamente necessarie al funzionamento. Questa Applicazione utilizza Cookie per
                    effettuare salvataggi automatici durante la sessione dell'Utente e per svolgere altre attività
                    strettamente necessarie al funzionamento del Sito. Attività di salvataggio delle preferenze,
                    ottimizzazione e statistica. Questa Applicazione utilizza Cookie per salvare le preferenze di
                    navigazione ed ottimizzare l'esperienza dell'Utente durante la visita al Sito. Tra questi Cookie
                    rientrano, ad esempio, quelli per impostare la lingua e la valuta o per la gestione di statistiche
                    da parte del Titolare del sito.

                </div>
                <br />
                <div className="condition-title-header">
                    Cookie tecnici di social networking e condivisione
                </div>
                <div>
                    Possono essere necessari per permettere al tuo account social di interagire con il nostro sito.
                    Servono ad esempio per farti esprimere il tuo apprezzamento e per condividerlo con i tuoi amici
                    social. I Cookie di social network non sono necessari alla navigazione e vengono utilizzati solo nel
                    caso in cui decidessi di condividere un contenuto attraverso i social sharing button o decidessi di
                    iscriverti attraverso i dati salvati nei tuoi social. Supermercato.casa non utilizza questo tipo di
                    cookie in modo diretto, e comunque vengono utilizzati su successiva conferma all'azione nella
                    maggior parte dei social networks. Normalmente viene disposta un'informativa dal social recante il
                    messaggio che "Supermercato.casa desidera accedere alle tue informazioni salvate sul social network"
                </div>
                <br />
                <div className="condition-title-header">
                    Cookie Analitici
                </div>
                <div>
                    Questi Cookie sono utilizzati per elaborare analisi statistiche sulle modalità di navigazione degli
                    utenti sul nostro sito. <br />
                    Supermercato.casa tratta i risultati di queste analisi in maniera anonima ed esclusivamente per
                    finalità statistiche solo se il fornitore di servizi utilizza i Cookie in connessione al browser
                    utilizzato o su altri dispositivi utilizzati per navigare sul sito. Il sito utilizza alcuni servizi
                    di terzi che, in modo del tutto indipendente, installano Cookie propri di natura anonima.

                </div>
                <br />
                <div className="condition-title-header">
                    Cookie di Marketing e profilazione di prima e terza parte
                </div>
                <div>
                    Questi Cookie sono volti a creare profili relativi all'utente al fine di inviare messaggi
                    commerciali che incontrano le preferenze manifestate durante la visita o per migliorare la tua
                    esperienza di navigazione: mentre navighi il nostro sito questi Cookie sono utili per mostrarti
                    prodotti di tuo interesse o simili a quelli che hai visualizzato. Sono Cookie di terza parte quelli
                    inviati da società terze di nostra fiducia (Es. Google remarketing). Questi Cookie permettono di
                    offrirti la nostra proposta commerciale su altri siti web affiliati (retargeting). Sui Cookie di
                    terza parte non abbiamo il controllo delle informazioni fornite dal Cookie e non abbiamo accesso a
                    tali dati. Queste informazioni sono controllate totalmente dalle società terze secondo quanto
                    descritto nelle rispettive privacy policy. In questa categoria rientrano i cookie legati a
                    Remarketing e Behavioral Targeting: Questi servizi consentono all'Applicazione ed ai suoi partner di
                    ottimizzare e mostrare annunci pubblicitari basati sull'utilizzo passato di questa Applicazione da
                    parte dell'Utente. Questa attività viene effettuata tramite il tracciamento dei Dati di Utilizzo e
                    l'uso di Cookie, informazioni che vengono trasferite ai partner a cui l'attività di remarketing e
                    behavioral targeting è collegata.
                    Statistica: I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di
                    monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento
                    dell’Utente. <br /><br />

                    I cookie di marketing ed eventuali cookie di profilazione sono stabiliti da terze parti è pertanto
                    possibile limitarne l'utilizzo in modo semplice, diretto e veloce aprendo il sito in una finestra di
                    navigazione anonima. <br /> <br />

                    In questo caso qualsiasi tipologia di cookie stabilito da terze parti sia esso di marketing, di
                    analisi o di profilazione viene immediatamente bloccato o limitato, puoi anche stabilire eccezioni
                    in base alla lista dei cookie fornita nell'impostazione del browser. In questo modo puoi essere
                    sicuro che nessuna terza parte avrà accesso a dati potenzialmente privati anche in caso di
                    illecito. <br /><br />

                    Per aprire una finestra di navigazione anonima con Firefox: fai click su <span
                        style={{ fontStyle: "italic" }}>{"File->"}</span> Nuova finestra
                    anonima" <br /><br />

                    Per aprire una finestra di navigazione anonima con Google Chrome: fai click su <span
                        style={{ fontStyle: "italic" }}>{"Archivio->"}</span> Nuova
                    finestra di navigazione in incognito" <br /><br />

                    Per aprire una finestra di navigazione anonima con Safari: fai click su <span
                        style={{ fontStyle: "italic" }}>{"File->"}</span> Nuova finestra
                    privata" <br /><br />

                    La maggior parte dei browser è configurata per accettare, controllare o eventualmente disabilitare i
                    Cookie attraverso le impostazioni. <br /><br />

                    Ti ricordiamo tuttavia che disabilitare i Cookie di navigazione o quelli funzionali può causare il
                    malfunzionamento del sito e/o limitare il servizio che offriamo.


                </div>
            </div>
        );
    }
}

export default PolicyCookie;