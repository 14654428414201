import React from "react";
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import AssistedDays from "./AssistedDays/AssistedDays"
import AssistedTimeslots from "./AssistedTimeslots/AssistedTimeslots"
import { Translation } from 'react-i18next';
import * as actionCreators from "../../../redux/store/actions/index"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

class AssistedDisponibility extends React.Component {
  changePage = () => {
    this.props.history.push("/checkout");
  }

  render() {
    const { serviceDay, selectServiceDay, serviceTimeSlotId, selectServiceTimeslot, storeId } = this.props;
    return (
      <Translation>
        {t =>
          <>
            <div className="container-fluid">
              <Col className="text-center disponibilityInfo">

                <span>{t("assisted.availability.title")}</span>
              </Col>
            </div>
            <div className="container-fluid">
              <Row className="justify-content-center">
                <AssistedDays serviceDay={serviceDay} selectDay={selectServiceDay} storeId={storeId} />
              </Row>
            </div>
            {serviceDay &&
              <AssistedTimeslots
                day={serviceDay}
                serviceTimeSlotId={serviceTimeSlotId}
                selectTimeslot={selectServiceTimeslot}
                storeId={storeId}
              />}
            <div className="justify-content-center d-flex" style={{ paddingBottom: "30px" }}>
              <button style={{ marginBottom: "unset" }}
                onClick={this.changePage}
                className={`cta ${serviceDay && serviceTimeSlotId ? "" : "custom-button-disabled"} `}>
                <span>{t("next")}</span>
              </button>
            </div>
          </>
        }
      </Translation>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveCheckoutInfo: (data) => dispatch(actionCreators.saveCheckoutInfo(data))
  };
}

export default withRouter(connect(null, mapDispatchToProps)(AssistedDisponibility));