import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
// import { checkResponse } from "../../utils/checkResponse";

export default function storesSignup(data) {
    return (
        fetch(`${config.baseUrl}/cli/recruiting/corporate`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            body: JSON.stringify({
                companyName: data.companyName,
                registeredOfficeAddress: data.registeredOfficeAddress,
                vatNumber: data.vatNumber,
                tenantName: data.tenantName,
                storeAddress: data.storeAddress,
                storeSize: data.storeSize,
                storeType: data.storeType,
                referentName: data.referentName,
                phoneNumber: data.phoneNumber,
                email: data.email,
                hasDelivery: data.hasDelivery
            })
        })
            .then(handleResponse)
            // .then(checkResponse)
    );
}
