import React from "react"
import Dropdown from "../../components/UI/Dropdown/Dropdown"
import AddressSelect from "./AddressSelect/AddressSelect"
import config from "../../config/config"
import { Translation } from "react-i18next"
import { Redirect } from 'react-router'
import { ecommerceService } from "../../services/ecommerce/EcommerceService"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"

class CartCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryModes: [],
            selectedDeliveryMode: "",
            addressId: null
        }
    }

    componentDidMount() {
        ecommerceService.deliveryModeByStore()
            .then(response => {
                this.setState({
                    deliveryModes: response.data
                })
            });
    }

    selectDeliveryMode = (item) => {
        this.setState({ selectedDeliveryMode: item });
    }

    selectAddress = (item) => {
        this.setState({ addressId: item.id });
    }

    render() {
        const { deliveryModes, selectedDeliveryMode, addressId } = this.state;
        const { open, cart, storeId } = this.props;
        if (cart) return <Redirect to={`/store/${storeId}`} />
        else return (
            <Translation>
                {t =>
                    <div className="bg">
                        <div className="container">
                            <div className="col-xs-12 col-sm-5 offset-md-7">
                                <div className="wrapper">
                                    <div className="wrapper-box">
                                        <h1 className="cart-create-title">{t("create.cart.title")}</h1>
                                        <div>
                                            <div className="cart-create-select">
                                                <Dropdown
                                                    items={deliveryModes}
                                                    select={this.selectDeliveryMode}
                                                    label={t("create.cart.service")}
                                                    isDeliveryMode={true}
                                                />
                                                {(selectedDeliveryMode === "AT_HOME" ||
                                                    selectedDeliveryMode === "SHIPPED") &&
                                                    <AddressSelect
                                                        select={this.selectAddress}
                                                        label="Indirizzo di spedizione"
                                                    />
                                                }
                                                <button className="cta"
                                                    disabled={selectedDeliveryMode === "" ||
                                                        ((selectedDeliveryMode === "AT_HOME" || selectedDeliveryMode === "SHIPPED") && !addressId)}
                                                    onClick={() => { open(selectedDeliveryMode, storeId, addressId) }}>
                                                    <span>{t("create.cart")}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        open: (deliveryMode, storeId, addressId) => dispatch(actionCreators.open(deliveryMode, storeId, addressId)),
    };
}

const mapStateToProps = state => {
    return {
        cart: state.cart.cart,
        storeId: state.user.storeId
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartCreate));