import React from "react";
import { Route, Switch, Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ProfileAddresses from "./ProfileAddresses/ProfileAddresses";
import BillingAddresses from "./ProfileAddresses/BillingAddresses";
import Profile from "./Profile/Profile";
import PasswordChange from "./PasswordChange/PasswordChange";
import * as actionCreators from "../../redux/store/actions/index"

import "./profile-container.styles.scss";

class ProfileContainer extends React.Component {
    render() {
        const { user } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="profile-container">
                        <div className="row no-gutters">
                            <div className="col-lg-4 col-sm-12">
                                <div className="profile-menu">
                                    <div className="profile-menu-title">
                                        Le tue informazioni
                                    </div>
                                    <div className="profile-menu-name">
                                        {user &&
                                            <span>{user.firstName} {user.lastName}</span>
                                        }
                                    </div>
                                    <hr />
                                    <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile" ? "profile-link-active" : ""}`}>
                                        <Link className="link" to="/profile">
                                            I tuoi dati
                                    </Link>
                                    </div>
                                    <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile/addresses" ? "profile-link-active" : ""}`}>
                                        <Link className="link" to="/profile/addresses">
                                            Indirizzi di spedizione
                                    </Link>
                                    </div>
                                    <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile/billing" ? "profile-link-active" : ""}`}>
                                        <Link className="link" to="/profile/billing">
                                            Indirizzi di fatturazione
                                    </Link>
                                    </div>
                                    <div className={`profile-link 
                                    ${this.props.history.location.pathname === "/profile/password" ? "profile-link-active" : ""}`}>
                                        <Link className="link" to="/profile/password">
                                            Cambia password
                                    </Link>
                                    </div>
                                    <button className="cta abort" onClick={this.props.logout}>
                                        <span>Esci</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-8 col-sm-12">
                                <div className="profile-content">
                                    <Switch>
                                        <Route exact path='/profile' render={() => <Profile />} />
                                        <Route exact path='/profile/addresses' render={() => <ProfileAddresses />} />
                                        <Route exact path='/profile/billing' render={() => <BillingAddresses />} />
                                        <Route exact path='/profile/password' render={() => <PasswordChange />} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actionCreators.logout()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileContainer));


