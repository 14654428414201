/* eslint-disable */

import * as actionTypes from "../actions/actionTypes";
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    user: JSON.parse(localStorage.getItem("user")),
    latitude: null,
    longitude: null,
    showOverlay: false,
    showLogin: false,
    cap: null,
    storeId: null,
    tid: null,

    enabledAidedShop: null,
    forItemReplacement: false,
    forFreshService: false,
    partialServiceMode: false,
    serviceType: null,
    isFullAssistedShop: false
}

const userReducer = createReducer(initialState, {
    [actionTypes.LOGIN]: (state, action) => { state.user = action.user, state.showLogin = action.showLogin },
    [actionTypes.LOGOUT]: (state, action) => { state.user = action.user },
    [actionTypes.USER_UPDATE]: (state, action) => { state.user = action.user },
    [actionTypes.IS_LOGGED]: (state, action) => { state.user = action.user },
    [actionTypes.COORDINATE]: (state, action) => {
        state.latitude = action.latitude,
        state.longitude = action.longitude
    },
    [actionTypes.OVERLAY]: (state, action) => { state.showOverlay = action.showOverlay },
    [actionTypes.SHOW_LOGIN]: (state, action) => { state.showLogin = action.showLogin },
    [actionTypes.SET_CAP]: (state, action) => { state.cap = action.cap },
    [actionTypes.SET_STORE]: (state, action) => {
        state.storeId = action.storeId, 
        state.tid = action.tid,
        state.enabledAidedShop = action.enabledAidedShop,

        state.forItemReplacement = false,
        state.forFreshService = false,
        state.partialServiceMode = false,
        state.serviceType = null,
        state.isFullAssistedShop = false
     },
     [actionTypes.AIDED_SHOP]: (state, action) => {
        state.forItemReplacement = action.forItemReplacement,
        state.forFreshService = action.forFreshService,
        state.partialServiceMode = action.partialServiceMode,
        state.serviceType = action.serviceType
    },
    [actionTypes.FULL_AIDED_SHOP]: (state, action) => {
        state.isFullAssistedShop = action.isFullAssistedShop,
        state.serviceType = action.serviceType
    },
    [actionTypes.RESET_AIDED_SHOP]: (state, action) => {
        state.forItemReplacement = action.forItemReplacement,
        state.forFreshService = action.forFreshService,
        state.partialServiceMode = action.partialServiceMode,
        state.serviceType = action.serviceType,
        state.isFullAssistedShop = action.isFullAssistedShop
    },
})

export default userReducer;

