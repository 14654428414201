import React from "react";
import HeaderCartItem from "../HeaderItems/HeaderCartItem";
import HeaderItem from "../HeaderItems/HeaderItem";
import { useEffect, useState } from "react";
import { ReactComponent as LandingLogo } from '../../../../images/landing-logo.svg';
import { ReactComponent as LandingLogoSticky } from '../../../../images/landing-logo-sticky.svg';
import { ReactComponent as LandingCart } from '../../../../images/landing-cart.svg';
import { ReactComponent as LandingCartSticky } from '../../../../images/landing-cart-sticky.svg';
import { ReactComponent as LandingUser } from '../../../../images/landing-user.svg';
import { ReactComponent as LandingUserSticky } from '../../../../images/landing-user-sticky.svg';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux"
import * as actionCreators from "../../../../redux/store/actions"

import "./landing-header.styles.scss"

function LandingHeader(props) {
    const [isSticky, setIsSticky] = useState(false);
    const [isLanding, setIsLanding] = useState(true);
    useEffect(() => {
        setIsLanding(props.location.pathname === "/")
        const header = document.getElementById("landing-header");
        const sticky = header.offsetTop;
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
                setIsSticky(true);
            } else {
                header.classList.remove("sticky");
                setIsSticky(false)
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, [props.location.pathname]);

    if (isLanding) {
        return (
            <div id="landing-header" className="landing-header">
                <Link to="/">
                    {isSticky ?
                        <LandingLogoSticky className="landing-logo" />
                        :
                        <LandingLogo className="landing-logo" />
                    }
                </Link>
                <div className="landing-icons">
                    {isSticky ?
                        props.user ?
                            <div className="landing-user-container" onClick={() => props.history.push("/profile")}>
                                <div className="landing-user">
                                    {props.user.firstName.charAt(0)}{props.user.lastName.charAt(0)}
                                </div>
                            </div>
                            :
                            <LandingUserSticky onClick={props.user ? () => { props.history.push("/profile") } : props.showLogin} />
                        :
                        props.user ?
                            <div className="landing-user-container" onClick={() => props.history.push("/profile")}>
                                <div className="landing-user">
                                    {props.user.firstName.charAt(0)}{props.user.lastName.charAt(0)}
                                </div>
                            </div>
                            :
                            <LandingUser onClick={props.showLogin} />
                    }

                    {isSticky ?
                        props.user ?
                            <HeaderCartItem
                                path="/carts"
                                items={props.carts && props.carts.length}
                                closeAll={props.closeAll}>
                                <LandingCartSticky />
                            </HeaderCartItem>
                            :
                            <HeaderItem onClick={props.showLogin}>
                                <LandingCartSticky />
                            </HeaderItem>
                        :
                        props.user ?
                            <HeaderCartItem
                                path="/carts"
                                items={props.carts && props.carts.length}
                                closeAll={props.closeAll}>
                                <LandingCart />
                            </HeaderCartItem>
                            :
                            <HeaderItem onClick={props.showLogin}>
                                <LandingCart />
                            </HeaderItem>
                    }
                </div>
            </div>
        );
    } else {
        return (
            <div id="landing-header" className="landing-header-sticky">
                <Link to="/">
                    <LandingLogoSticky className="landing-logo" />
                </Link>
                <div className="landing-icons">
                    {props.user ?
                        <div className="landing-user-container" onClick={() => props.history.push("/profile")}>
                            <div className="landing-user">
                                {props.user.firstName.charAt(0)}{props.user.lastName.charAt(0)}
                            </div>
                        </div>
                        :
                        <LandingUserSticky onClick={props.showLogin} />
                    }
                    {props.user ?
                        <HeaderCartItem
                            path="/carts"
                            items={props.carts && props.carts.length}
                            closeAll={props.closeAll}>
                            <LandingCartSticky />
                        </HeaderCartItem>
                        :
                        <HeaderItem onClick={props.showLogin}>
                            <LandingCartSticky />
                        </HeaderItem>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        carts: state.cart.carts,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        showLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingHeader));
