import React from "react";
import Placeholder from "../../images/product-placeholder.png"
import { Translation } from "react-i18next";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom";
import * as actionCreators from "../../redux/store/actions/index"
import { ReactComponent as ArrowLeft } from "../../images/arrow_left.svg"
import { ReactComponent as ArrowRight } from "../../images/arrow_right.svg"

import "./carts-styles.scss"
import Slider from "react-slick";
import { storesService } from "../../services/basis/StoresService";

class Carts extends React.Component {
    componentDidMount() {
        this.props.getCarts();
    }

    renderTotal = (cart) => {
        if (cart) {
            if (cart.totalToPay) {
                return <span>{`\u20AC ${cart.totalToPay.toFixed(2)}`}</span>
            } else if (cart.netTotal) {
                return <span>{`\u20AC ${cart.netTotal.toFixed(2)}`}</span>;
            } else {
                return <span>{`\u20AC 0.00`}</span>;
            }
        }
    }

    changeStore = (item) => {
        let enabledAidedShop = false
        storesService.storeDetails(item.storeId)
            .then(response => {
                enabledAidedShop = response.data.enabledAidedShop;
                this.props.setStore(item.storeId, item.tid, enabledAidedShop);
                this.props.history.push(`/cart`);
            })
            .catch(error => {
                console.log(error)
                this.props.setStore(item.storeId, item.tid, enabledAidedShop);
                this.props.history.push(`/cart`);
            })
    }

    render() {
        const { carts } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="carts-container">
                        <div style={{ minHeight: "400px" }} className="carts">
                            <div className="carts-title">
                                {carts && carts.length > 0 ?
                                    t("cart.lists") : "Non hai carrelli attivi al momento"
                                }
                            </div>
                            <Slider {...settings}>
                                {carts && carts.map(item => (
                                    <div className="carts-card" key={item.id}>
                                        <div className="carts-card-content">
                                            <div className="carts-card-title">{item.storeDescription}</div>
                                            {item.items && item.items.map((cartItem, index) => (
                                                <div key={cartItem.id}>
                                                    <div className="carts-item">
                                                        <div className="carts-item-image">
                                                            <img src={cartItem.imageUrl ? cartItem.imageUrl : Placeholder} alt=""
                                                                onError={(e) => { e.target.src = Placeholder }}
                                                            />
                                                        </div>
                                                        <div className="carts-item-info">
                                                            <div className="description">{cartItem.description}</div>
                                                            <div className="totals">
                                                                <div className="label">Quantità: <b>{cartItem.amount}</b></div>
                                                                <div className="label">Prezzo Totale: <b>{cartItem.rowNetPrice}</b></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {item.items.length !== index + 1 &&
                                                        <hr />
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                        <div className="carts-footer">
                                            <div className="carts-footer-total">
                                                <span>Totale:</span> <b>{this.renderTotal(item)}</b>
                                            </div>
                                            <button className="cta" onClick={() => this.changeStore(item)}>
                                                <span>Acquista</span>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </Slider>

                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                nextArrow: <SampleArrow><ArrowRight /></SampleArrow>,
                prevArrow: <SampleArrow><ArrowLeft /></SampleArrow>
            }
        },
        {
            breakpoint: 567,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            }
        }
    ]
};

function SampleArrow(props) {
    const { className, style, onClick, children } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            {children}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        carts: state.cart.carts,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setStore: (storeId, tid, enabledAidedShop) => dispatch(actionCreators.setStore(storeId, tid, enabledAidedShop)),
        setCap: (cap) => dispatch(actionCreators.setCap(cap)),
        getCarts: () => dispatch(actionCreators.getCarts()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Carts));