import React from "react"
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { connect } from "react-redux"
import * as actionCreators from "../../redux/store/actions/index"

class AssistedShopMenu extends React.Component {
    render() {
        const { closeAll, fullAidedShop } = this.props;
        return (
            <Translation>
                {t =>
                    <div className="container-fluid h-100">
                        <Row className="g-padding">
                            <div style={{ paddingTop: "unset" }} className="navigation-view container-fluid">
                                <Row>
                                    <div className="container-fluid">
                                        <Row>
                                            <div className="modal-profile-wrapper w-100">
                                                <div className="container-fluid">
                                                    <Row>
                                                        <Col className="modal-profile-field">
                                                            <Link to="/checkout/create" onClick={() => {
                                                                fullAidedShop();
                                                                closeAll();
                                                            }} 
                                                                className="modal-link">
                                                                {t("link.assisted.shop")}
                                                            </Link>
                                                        </Col>
                                                        <div className="w-100 d-block d-md-none"></div>
                                                        <Col className="modal-profile-field">
                                                            <Link to="/call" onClick={closeAll} className="modal-link">{t("link.your.assisted.shop")}</Link>
                                                        </Col>
                                                        <div className="w-100 d-block d-md-none"></div>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                            </div>
                        </Row>
                    </div>
                }
            </Translation>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fullAidedShop: () => dispatch(actionCreators.fullAidedShop(true)),
    };
}

export default connect(null, mapDispatchToProps)(AssistedShopMenu);

