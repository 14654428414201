import React from "react"
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Placeholder from "../../images/product-placeholder.png"
import CounterInput from "../../components/UI/Counter/CounterInput";
import ProductInfo from "./ProductInfo/ProductInfo";
import GoBackButton from "../../components/UI/Buttons/GoBackButton";
import { Translation } from "react-i18next";
import { connect } from "react-redux"
import { productsService } from "./Service"
import * as actionCreators from "../../redux/store/actions/index"
import config from "../../config/config";
import { withRouter } from 'react-router-dom'
import { format, parseISO } from "date-fns";
import renderPrice from "../../utils/renderPrice";
import ImageGallery from 'react-image-gallery';
import { productClick } from "../../components/GoogleTagManager"

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: null,
            umStep: null,
            info: null,
            isFavorite: false,
            available: true,
            product: null,
            productImages: null,
        }
    }

    componentDidMount() {
        const barcode = this.props.match.params.barcode;
        const isDetail = this.props.location.state ? this.props.location.state.isDetail : null;
        const detailProduct = this.props.location.state ? this.props.location.state.product : null;
        productsService.fetchByBarcode(barcode).then(response => {
            const product = response.data;
            console.log(product);
            productClick(product);
            let initialAmount = null;
            // check if is a cart item 
            if (detailProduct) {
                if (isDetail) {
                    // TODO usare il product che arriva dal location state
                    if (detailProduct.um === "PZ") {
                        initialAmount = detailProduct.amount;
                    } else {
                        initialAmount = detailProduct.weight;
                    }
                } else {
                    initialAmount = detailProduct.umStep;
                }
            } else {
                initialAmount = product.umStep;
            }

            // check if is a favorite item 
            let isFavorite = false;
            if (this.props.favorites) {
                this.props.favorites.forEach(item => {
                    if (this.props.match.params.barcode === item.barcode) {
                        isFavorite = true;
                    }
                });
            }

            // get product info
            productsService.info(barcode, product?.ref, this.props.storeId)
                .then(resp => {
                    if (resp.data) {
                        this.setState({
                            product: product,
                            amount: initialAmount,
                            umStep: product.umStep,
                            isFavorite: isFavorite,
                            available: isDetail ? true : product.available,
                            info: resp.data,
                            productImages: resp.data.images
                                ?
                                resp.data.images.map(item => {
                                    return { original: item }
                                })
                                : null
                        })
                    } else {
                        this.setState({
                            product: product,
                            amount: initialAmount,
                            umStep: product.umStep,
                            isFavorite: isFavorite,
                            available: isDetail ? true : product.available,
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                // const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <div className="product-detail-promotion" >
                        Prodotto in promozione fino al {format(parseISO(item.promotions[0].validTo), "dd/MM")}
                    </div>
                );
            } else {
                return null;
            }
        }
    }

    incrementAmount = () => {
        let newAmount = this.state.amount + this.state.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (this.state.product && this.state.product.maxAmount) {
            if (newAmount <= this.state.product.maxAmount) {
                this.setState({ amount: newAmount })
            }
        } else {
            this.setState({ amount: newAmount })
        }
    }

    decrementAmount = () => {
        let newAmount = this.state.amount - this.state.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (this.state.amount > this.state.umStep) {
            this.setState({ amount: newAmount })
        }
    }

    renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <>
                    <span className="mr-1 text-strike product-price">
                        {`${renderPrice((item.price * this.state.amount).toFixed(2))} \u20AC`}
                    </span>
                    <span className="primary-color product-price ml-4">
                        {`${renderPrice((item.netPrice * this.state.amount).toFixed(2))} \u20AC`}
                    </span>
                </>
        } else {
            result = <span className="product-price">{`${renderPrice((item.netPrice * this.state.amount).toFixed(2))} \u20AC`}</span>
        }
        return result
    }

    setFavorite = () => {
        const barcode = this.props.match.params.barcode;
        if (this.state.isFavorite) {
            this.props.deleteFavorite(barcode);
        } else {
            this.props.addFavorite(barcode);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.favorites.length - this.props.favorites.length === 1) {
            this.setState({
                isFavorite: true
            })
        }
        if (this.props.favorites.length - nextProps.favorites.length === 1) {
            this.setState({
                isFavorite: false
            })
        }
    }

    goPrevPath = () => {
        if (this.props.location.state) {
            const { from, product, category, mainCategory, categoryTitle, subCategory, activeKey } = this.props.location.state;
            if (from) this.props.history.push({
                pathname: `/category`,
                state: { activeKey, categoryId: product.categoryId, category, mainCategory, categoryTitle, subCategory, from: this.props.location.pathname }
            })
            else this.props.history.goBack()
        } else {
            this.props.history.goBack();
        }
    }

    render() {
        const { product, info, productImages } = this.state;
        const { user, cart, store, setProductToAdd, showLogin } = this.props;
        if (product) {
            return (
                <Translation>
                    {t =>
                        <>
                            <div className="text-left container-fluid mobile-view-search">
                                <Row>
                                    <Col className="p-0" sm={12} md={12} lg={7} style={{ backgroundColor: "#ffffff" }}>
                                        <div>
                                            <GoBackButton
                                                goBack={() => this.goPrevPath()}>
                                                {t("goBack")}
                                            </GoBackButton>
                                            <div style={{ marginBottom: "49px", paddingTop: "unset" }}
                                                className="view-container">
                                                {!productImages &&
                                                    <div className="product-detail">
                                                        <img src={product.imageUrl ? product.imageUrl : Placeholder} alt=""
                                                            onError={(e) => { e.target.src = Placeholder }}
                                                        />
                                                    </div>
                                                }
                                                {productImages &&
                                                    <ImageGallery
                                                        items={productImages}
                                                        showFullscreenButton={false}
                                                        autoPlay={false}
                                                        // showBullets={productImages.length > 1 ? true : false}
                                                        showBullets={true}
                                                        showPlayButton={false}
                                                        showThumbnails={false}
                                                        onErrorImageURL={Placeholder}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="p-0" style={{ backgroundColor: "#fbfbfb" }} sm={12} md={12} lg={5}>
                                        <div className="product-container">
                                            <div className="product-detail-ref">C. EAN: {product.barcode}</div>
                                            <h1 className="product-detail-name cart-product-description">{product.description}</h1>
                                            {product.pricePerUm && product.umPerUm &&
                                                <h4 className="product-detail-netPrice">
                                                    {`${renderPrice(product.pricePerUm.toFixed(2))} \u20AC / ${product.umPerUm} `}
                                                </h4>
                                            }
                                            {this.renderPromo(product)}
                                            {product.price &&
                                                <div style={{ marginBottom: "25px" }}>
                                                    <div className="product-detail-heading">{t("price")}</div>
                                                    {this.renderPrices(product)}
                                                </div>
                                            }
                                            <CounterInput
                                                value={this.state.amount}
                                                um={product.purchaseUm}
                                                increment={this.incrementAmount}
                                                decrement={this.decrementAmount}
                                            />
                                            {user && cart &&
                                                <div className="mb-3">
                                                    <button className="cta"
                                                        onClick={() => { cart && store(cart.id, product.barcode, this.state.amount, product.um) }}>
                                                        <span>{t("buy")}</span>
                                                    </button>
                                                </div>
                                            }
                                            {user && !cart &&
                                                <div className="mb-3">
                                                    <button className="cta"
                                                        onClick={() => {
                                                            setProductToAdd(product, this.state.amount);
                                                            this.props.history.push("/cart")
                                                        }}>
                                                        <span>{t("buy")}</span>
                                                    </button>
                                                </div>
                                            }
                                            {!user &&
                                                <div className="mb-3">
                                                    <button className="cta"
                                                        onClick={() => {
                                                            setProductToAdd(product, this.state.amount);
                                                            showLogin();
                                                        }}>
                                                        <span>{t("access")}</span>
                                                    </button>
                                                </div>
                                            }
                                            {config.ENABLED_SLIST &&
                                                <div>
                                                    {user &&
                                                        <div className="mb-3">
                                                            <button
                                                                className="custom-slist-button outline"
                                                                onClick={() => { this.props.itemCreate(this.props.currentSlist.id, product.barcode, 1) }}>
                                                                {t("smartlist.add")}
                                                            </button>
                                                        </div>
                                                    }
                                                    {!user &&
                                                        <div className="mb-3">
                                                            <button
                                                                className="custom-slist-button outline"
                                                                onClick={() => {
                                                                    // setProductToAdd(product, this.state.amount);
                                                                    document.getElementById('user').click();
                                                                }}>
                                                                {t("smartlist.add")}
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <p className="small">NOTA: Si prega di leggere sempre l'etichetta, gli avvertimenti e le istruzioni
                                            fornite sul prodotto prima di utilizzarlo o consumarlo, in quanto i produttori possono modificare
                                            la composizione dei loro prodotti e quindi la confezione del prodotto può contenere informazioni
                                                diverse rispetto a quelle mostrate sul nostro sito.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div >
                            <ProductInfo info={info} />
                        </>
                    }
                </Translation>
            );
        }
        else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        favorites: state.slists.favorites,
        currentSlist: state.slists.currentSlist,
        storeId: state.user.storeId
    };
}

const mapDispatchToProps = dispatch => {
    return {
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        getFavorites: () => dispatch(actionCreators.getFavorites()),
        addFavorite: (barcode) => dispatch(actionCreators.addFavorite(barcode)),
        deleteFavorite: (barcode) => dispatch(actionCreators.deleteFavorite(barcode)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
        showLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product))