import CustomError from "./customError";

export function checkResponse(response) {
    if (response.responseCode !== 0) {
        throw new CustomError(response.responseCode, errorMessage(response));
    }

    return response;
}

function errorMessage(response) {
    console.log(response);
    switch (response.responseCode) {
        case 2:
            return "Utente non trovato"

        case 4:
            return "Username non valido"

        case 28:
            return "Devi essere maggiorenne per poterti registrare al servizio"

        case 30:
            return "Numero di telefono non valido"

        case 42:
            return "Il servizio di consegna non è attualmente attivo per l'indirizzo inserito."

        case 43:
            return "Username già in uso"

        case 2035:
            return "Non puoi eliminare l'ultima lista della spesa"

        case 2222:
            return "Attualmente il servizio di consegna a domicilio non è attivo per la città selezionata"

        case 4000:
            return "Errore generico"

        case 4004:
            return "Carrello non trovato"

        case 4029:
            return "Hai superato il limite di orario per prenotare l'ordine"

        case 4030:
            return "Prodotto non trovato"

        case 4031:
            return "Prodotto non valido"

        case 4034:
            return "Massima quantità acquistabile raggiunta"

        case 4035:
            return "Impossibile generale la consegna per la tua città"

        case 4037:
            return "Indirizzo di fatturazione mancante"

        case 4038:
            return "E' stato superato il numero massimo di fardelli nell'ordine"

        case 4039:
            return "Indirizzo di fatturazione incompleto"

        case 4205:
            return "Stato dell'ordine errato"

        case 6001:
            return "Coupon già applicato"

        case 6002:
            return "Coupon scaduto"

        case 6003:
            return "Coupon già utilizzato"

        case 6004:
            return "Coupon non valido"

        case 6005:
            return "Il valore del codice coupon inserito non può essere maggiore del totale della spesa"

        case 7002:
            return "Nessun servizio di spesa assistita disponibile"

        case 7005:
            return "La fascia per il servizio di spesa assistita deve essere antecedente rispetto alla fascia di consegna"

        default:
            return response.errorMessage;
    }
}