import React, { useState } from "react";
import Popup from "reactjs-popup";
import AssistedPopupImg from "../../../../images/assisted-popup.jpg"
import { Translation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'

import "./assisted-popup.styles.scss";

function AssistedPopup(props) {
    const isDesktopOrTablet = useMediaQuery({ query: '(min-width: 1024px)' })
    const [open, setOpen] = useState(isDesktopOrTablet ? true : false)

    const goBack = () => {
        setOpen(false);
        props.onClose();
    }

    return (
        <Translation>
            {t =>
                <Popup
                    lockScroll={true}
                    offsetY={25}
                    contentStyle={popupStyle}
                    closeOnDocumentClick
                    open={open}
                    onClose={() => props.closeAction ? goBack() : setOpen(false)}
                    modal
                >
                    {close => (
                        <div className="assisted-popup">
                            <div id="close-popup" className="close" onClick={close}>
                                &times;
                            </div>
                            <div className="assisted-popup-header">
                                <img src={AssistedPopupImg} alt="" />
                                <div className="header-title">
                                    {props.title}
                                </div>
                            </div>
                            <div className="assisted-popup-content">
                                {props.content}
                            </div>
                            {props.subContent &&
                                <div className="assisted-popup-content">
                                    {props.subContent}
                                </div>
                            }
                            <div className="assisted-popup-actions">
                                <button className={`cta ${props.closeAction ? "" : "abort"}`} onClick={close}>
                                    <span>{props.closeAction ? "Torna alla home" : "No grazie"}</span>
                                </button>
                                {!props.closeAction &&
                                    <button className="cta" onClick={() => {
                                        props.action();
                                    }}>
                                        <span>Si grazie</span>
                                    </button>
                                }
                            </div>
                        </div>
                    )}
                </Popup>
            }
        </Translation >
    );
}

const popupStyle = {
    borderRadius: "8px",
    boxShadow: "0 0 23px 10px rgba(0, 0, 0, 0.24)",
    border: "none",
    padding: "20px"
}

export default AssistedPopup;