import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import ValidateInput from "../../../../components/UI/Input/ValidateInput";

import { useForm } from 'react-hook-form'
import { Translation } from "react-i18next";

import "./address-popup.styles.scss";

function AddressPopup(props) {

    const [customerType, setCustomerType] = useState("INDIVIDUAL");
    const { register, handleSubmit, errors, watch, setValue } = useForm({
        mode: 'onBlur',
    });

    useEffect(() => {
        register(
            { name: "customerType" },
        )
        setValue("customerType", customerType);
    }, [register, customerType, setValue])

    const onSubmit = data => {
        console.log(data);
        props.action(data);
        document.getElementById("close-popup").click();
    }

    const isCompany = customerType === "COMPANY" ? true : false;
    return (
        <Translation>
            {t =>
                <Popup lockScroll={true} offsetY={25} contentStyle={popupStyle} trigger={props.trigger} modal>
                    {close => (
                        <div className="address-popup">
                            <div id="close-popup" className="close" onClick={close}>
                                &times;
                            </div>
                            <div className="address-popup-header">
                                {props.title}
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"
                                className={`address-popup-form needs-validation ${errors ? "had-validation" : ""}`}>
                                <div className="address-popup-content">
                                    <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <div className="radio-buttons">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input"
                                                        id="isIndividual"
                                                        name="isIndividual"
                                                        value={customerType}
                                                        checked={customerType === "INDIVIDUAL"}
                                                        onChange={() => setCustomerType("INDIVIDUAL")}
                                                    />
                                                    <label className="custom-control-label" htmlFor="isIndividual" />
                                                    <span className={`${customerType === "INDIVIDUAL" ? "selected" : ""}`}>Persona fisica</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <div className="radio-buttons">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" className="custom-control-input"
                                                        id="isCompany"
                                                        name="isCompany"
                                                        value={customerType}
                                                        checked={customerType === "COMPANY"}
                                                        onChange={() => setCustomerType("COMPANY")}
                                                    />
                                                    <label className="custom-control-label" htmlFor="isCompany" />
                                                    <span className={`${customerType === "COMPANY" ? "selected" : ""}`}>Persona giuridica</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="firstName"
                                                label={isCompany ? "Nome" : "Nome*"}
                                                pattern="[A-Za-z\s]"
                                                type="text"
                                                value={props.address && props.address.firstName}
                                                watch={watch("firstName")}
                                                errors={errors.firstName}
                                                register={register({
                                                    required: isCompany ? false : true
                                                })}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="lastName"
                                                pattern="[A-Za-z\s]"
                                                label={isCompany ? "Cognome" : "Cognome*"}
                                                type="text"
                                                value={props.address && props.address.lastName}
                                                watch={watch("lastName")}
                                                errors={errors.lastName}
                                                register={register({
                                                    required: isCompany ? false : true
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="vatCode"
                                                label={isCompany ? "Partita iva*" : "Partita iva"}
                                                type="text"
                                                pattern="[0-9]"
                                                uppercase={true}
                                                value={props.address && props.address.vatCode}
                                                watch={watch("vatCode")}
                                                errors={errors.vatCode}
                                                register={register({
                                                    required: isCompany ? true : false
                                                })}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="ssnCode"
                                                label={isCompany ? "Codice fiscale" : "Codice fiscale*"}
                                                uppercase={true}
                                                type="text"
                                                maxLength={16}
                                                value={props.address && props.address.ssnCode}
                                                watch={watch("ssnCode")}
                                                errors={errors.ssnCode}
                                                register={register({
                                                    required: isCompany ? false : true,
                                                    maxLength: 16, minLength: 16
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="companyName"
                                                label={isCompany ? "Ragione sociale*" : "Ragione sociale"}
                                                type="text"
                                                value={props.address && props.address.companyName}
                                                watch={watch("companyName")}
                                                errors={errors.companyName}
                                                register={register({
                                                    required: isCompany ? true : false
                                                })}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="phone"
                                                label="Telefono"
                                                type="text"
                                                pattern="[0-9]"
                                                value={props.address && props.address.phone}
                                                watch={watch("phone")}
                                                errors={errors.phone}
                                                register={register({
                                                    required: false
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="address"
                                                label="Indirizzo*"
                                                type="text"
                                                value={props.address && props.address.address}
                                                watch={watch("address")}
                                                errors={errors.address}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-2 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="streetNumber"
                                                label="Civico*"
                                                type="text"
                                                pattern="[0-9a-zA-Z]"
                                                uppercase={true}
                                                value={props.address && props.address.streetNumber}
                                                watch={watch("streetNumber")}
                                                errors={errors.streetNumber}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="zip"
                                                label="Cap*"
                                                type="text"
                                                pattern="[0-9]"
                                                value={props.address && props.address.zip}
                                                maxLength={5}
                                                watch={watch("zip")}
                                                errors={errors.zip}
                                                register={register({
                                                    required: "true",
                                                    maxLength: 5, minLength: 5
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="city"
                                                label="Città*"
                                                pattern="[A-Za-z\s]"
                                                type="text"
                                                value={props.address && props.address.city}
                                                watch={watch("city")}
                                                errors={errors.city}
                                                register={register({
                                                    required: "true"
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-2 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="county"
                                                label="Provincia*"
                                                pattern="[A-Za-z\s]"
                                                uppercase={true}
                                                type="text"
                                                value={props.address && props.address.county}
                                                maxLength={2}
                                                watch={watch("county")}
                                                errors={errors.county}
                                                register={register({
                                                    required: "true",
                                                    maxLength: 2, minLength: 2
                                                })}
                                            />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="fax"
                                                label="Fax"
                                                pattern="[0-9]"
                                                type="text"
                                                value={props.address && props.address.fax}
                                                maxLength={5}
                                                watch={watch("fax")}
                                                errors={errors.fax}
                                                register={register}
                                            />
                                        </div>
                                        {props.isUpdate &&
                                            <div className="d-none">
                                                <ValidateInput
                                                    name="id"
                                                    type="text"
                                                    value={props.address && props.address.id}
                                                    watch={watch("id")}
                                                    errors={errors.id}
                                                    register={register({
                                                        required: "true"
                                                    })}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="row popup-row">
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="pec"
                                                label={isCompany ?
                                                    ((watch("recipientCode") === "" || watch("recipientCode") === undefined)
                                                        ? "PEC*" : "PEC"
                                                    )
                                                    : "PEC"
                                                }
                                                type="text"
                                                value={props.address && props.address.pec}
                                                watch={watch("pec")}
                                                errors={errors.pec}
                                                register={register({
                                                    required: isCompany ?
                                                        ((watch("recipientCode") === "" || watch("recipientCode") === undefined)
                                                            ? true : false
                                                        )
                                                        : false
                                                })}
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-3 mb-sm-0">
                                            <ValidateInput
                                                name="recipientCode"
                                                label={isCompany ?
                                                    ((watch("pec") === "" || watch("pec") === undefined)
                                                        ? "Codice destinatario*" : "Codice destinatario"
                                                    )
                                                    : "Codice destinatario"}
                                                type="text"
                                                pattern="[0-9a-zA-Z]"
                                                uppercase={true}
                                                maxLength={7}
                                                value={props.address && props.address.recipientCode}
                                                watch={watch("recipientCode")}
                                                errors={errors.recipientCode}
                                                // register={register}
                                                register={register({
                                                    required: isCompany ?
                                                        (
                                                            (watch("pec") === "" || watch("pec") === undefined)
                                                                ? true : false
                                                        ) : false,
                                                        maxLength: 7, minLength: 7
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="address-popup-actions">
                                    {props.isUpdate &&
                                        <button className="cta delete" onClick={() => {
                                            props.deleteAddress(props.address && props.address.id)
                                            close();
                                        }}>
                                            <span>{t("addresses.delete")}</span>
                                        </button>
                                    }
                                    <button type="submit" className="cta">
                                        <span>{props.button}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </Popup>
            }
        </Translation >
    );
}

const popupStyle = {
    borderRadius: "15px"
}

export default AddressPopup;