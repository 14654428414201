import React from "react";
import { Translation } from 'react-i18next';
import { withRouter } from "react-router-dom"
import { ecommerceService } from "../../../services/ecommerce/EcommerceService";
import { dayNumber } from "../../../utils/dateUtils";
import renderPrice from "../../../utils/renderPrice";
import { ReactComponent as AssistedShop } from '../../../images/occhiali_outline.svg';

import "./confirm.styles.scss"

class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: null
        }
    }

    componentDidMount() {
        ecommerceService.orders(0)
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        order: response.data[0],
                    })
                }
            }).catch((error) => {
                console.log(error);
            })
        // setTimeout(() => {
        //     this.props.history.push("");
        // }, 3000);
    }

    render() {
        const { order } = this.state;
        console.log(order);
        return (
            <Translation>
                {t =>
                    <div className="checkout-confirm">
                        <div className="checkout-confirm-title text-center">
                            {t('freeze.success')}
                        </div>
                        {order && order.cart && order.cart.payment && order.cart.payment.mode === "STRIPE" &&
                            <div className="checkout-confirm-subtitle">
                                <div>
                                    Riceverai una richiesta di pre-autorizzazione temporanea sulla tua carta di pagamento
                                    con l’importo della spesa ordinata e una <b>maggiorazione del 10% per eventuali
                                    prodotti a peso variabile.</b><br /> Ti ricordiamo che non si tratta del pagamento reale
                                    ma è una prenotazione del pagamento prima che l’operazione venga effettivamente
                                    effettuata.
                                </div>
                                <div style={{ marginBottom: "20px" }}>
                                    Non appena la tua spesa sarà pronta riceverai una email con l’importo esatto, compreso
                                    i prodotti a peso variabile, e del reale totale acquistato.
                                </div>
                            </div>
                        }
                        <div className="checkout-confirm-resume">
                            <div className="checkout-confirm-resume-title">
                                Numero ordine: <span>{order && order.code}</span>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 label">Totale prodotti:</div>
                                <div className="col-12 col-md-6 text">
                                    {order && order.cart && order.cart.itemsCount}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 label">Importo:</div>
                                <div className="col-12 col-md-6 text">
                                    {order && order.cart && `${renderPrice(order.cart.totalToPay.toFixed(2))} \u20AC `}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 label">Modalità di consegna:</div>
                                <div className="col-12 col-md-6 text">
                                    {order && order.cart && t(`cart.deliveryMode.${order.cart.deliveryMode}`)}
                                </div>
                            </div>
                            {order && order.cart && order.cart.delivery &&
                                (order.cart.deliveryMode === "AT_HOME" || (order.cart.deliveryMode === "SHIPPED")) &&
                                <div className="row">
                                    <div className="col-12 col-md-6 label">Indirizzo:</div>
                                    <div className="col-12 col-md-6 text">
                                        {order.cart.delivery.addressDescription}
                                    </div>
                                </div>
                            }
                            {order && order.cart && order.cart.delivery &&
                                (order.cart.deliveryMode === "IN_STORE" || (order.cart.deliveryMode === "DRIVE")) &&
                                <div className="row">
                                    <div className="col-12 col-md-6 label">Punto vendita:</div>
                                    <div className="col-12 col-md-6 text">
                                        {order.cart.storeDescription}
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-12 col-md-6 label">Data e ora di consegna:</div>
                                <div className="col-12 col-md-6 text">
                                    {order && order.cart && order.cart.delivery && order.cart.delivery.timeSlot &&
                                        <>
                                            <span>{dayNumber(order.cart.delivery.timeSlot.weekDay)}</span>
                                            <span> ({order.cart.delivery.timeSlot.validFrom} - {order.cart.delivery.timeSlot.validTo})</span>
                                        </>
                                    }
                                </div>
                            </div>
                            {order?.cart?.service &&
                                <>
                                    <div className="row" style={{ marginBottom: "5px" }}>
                                        <div className="col-12 col-md-6 label">Fascia oraria spesa assistita:</div>
                                        <div className="col-12 col-md-6 text">
                                            {order.cart.service.serviceTimeSlot &&
                                                <>
                                                    <span>{dayNumber(order.cart.service.serviceTimeSlot.weekDay)}</span>
                                                    <span> ({order.cart.service.serviceTimeSlot.validFrom} - {order.cart.service.serviceTimeSlot.validTo})</span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="checkout-confirm-resume-aided-title">
                                        All’orario prenotato clicca sull'icona <AssistedShop /> in alto a destra e poi su “LA TUA SPESA ASSISTITA PRENOTATA
                                    </div>
                                </>
                            }
                        </div>
                        <div className="text-center">
                            <button style={{ width: "293px" }} className="cta"
                                onClick={() => {
                                    this.props.history.push(`/store/${order.storeId}`);
                                }}>
                                <span>Torna alla home</span>
                            </button>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}

export default withRouter(Confirm);

