const config = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    maxSize: 5,
    DEFAULT_STORE_ID: "001",
    DEFAULT_TID: "01",
    DEFAULT_STORE_DESCRIPTION: "Supermercato casa",
    DEFAULT_STATE: "",
    DEFAULT_STATE_ID: "",
    SHOW_STATE_DROPDOWN: false,
    SHOW_CART_LISTS: false,

    ENABLED_SLIST: false,
    ENABLED_PAYMENTS: true,
    STRIPE_PKEY: process.env.REACT_APP_STRIPE_PKEY,
    // API_RTC_KEY: "61cd57ff2387540498d8af277de480b0",
    API_RTC_KEY: "36de25129290e40c5d596d231a7ecf4a",
    rabbitmq_password: "Sq3WkwzMpkFKQU4L",
    rabbitmq_virtualHost: "V1",
    rabbitmq_ws_user: "smartbip-web",
    rabbitmq_ws_url: "wss://supermercato-casa.smartbip.it:15675/ws"
        //rabbitmq_ws_url: "wss://supermercato-casa-as-stage.smartbip.it:15675/ws"
}

export default config;