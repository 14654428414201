import React from "react"
import { ReactComponent as CouponIcon } from "../../../../images/coupon.svg"
import { ReactComponent as RemoveCoupon } from "../../../../images/remove_coupon.svg"

import "./coupon.styles.scss";

const Coupon = ({ coupon, handleCoupon }) => {
    return (
        <div className="checkout-coupons-item">
            <div className="description">
                <CouponIcon />
                <span>{coupon.description}</span>
            </div>
            <RemoveCoupon className="remove" onClick={() => handleCoupon("DELETE", coupon.code)} />
        </div>
    );
}

export default Coupon;