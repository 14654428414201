import React from "react";
import ShopImage from '../../../../images/Shop.jpg';
import { withRouter } from "react-router-dom";
import { ReactComponent as AssistedShop } from "../../../../images/occhiali_full.svg"

import "./store-card.styles.scss";

class StoreCard extends React.Component {
    render() {
        const { store, dragging, setStore, setCap, cap } = this.props;
        return (
            <div className="store-card" onClick={() => {
                if (!dragging) {
                    if (store) {
                        setStore(store.id, store.tid, store.enabledAidedShop)
                    }
                    if (setCap) {
                        setCap(cap)
                    }
                    this.props.history.push({
                        pathname: `/store/${store ? store.id : "undefined"}`,
                        state: { enabledAidedShop: store.enabledAidedShop }
                    })
                }
            }}>
                <div className="store-card-container">
                    <div className="image"
                        style={{ backgroundImage: `url(${store.imageUrl}), url(${ShopImage})` }}
                        alt="" onError={() => console.log("errore")}>
                        {store && store.enabledAidedShop &&
                            <div className="assisted-shop">
                                <AssistedShop />
                                <span>Spesa assistita</span>
                            </div>
                        }
                    </div>
                    <div className="description">
                        <span>{store ? store.name : "Negozio"}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(StoreCard);

