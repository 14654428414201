import config from "../../../config/config"
import { handleResponse } from "../../../utils/handleResponse";
import { checkResponse } from "../../../utils/checkResponse";
import { reduxStore } from "../../../redux/store";

export default function banners() {
    const tid = reduxStore.getState().user.tid;
    const storeId = reduxStore.getState().user.storeId;
    return (
        fetch(`${config.baseUrl}/cli/cms/banners?category=WEB&storeId=${storeId}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json", "sb-tid" : `${tid}` },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}