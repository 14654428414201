import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import LandingTemplate from './components/MainComponent/Template/LandingTemplate';
import MainTemplate from './components/MainComponent/Template/MainTemplate';
import LoginPopup from './features/Login/LoginPopup/LoginPopup';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { authenticationService } from "./services/basis/AuthenticationService"

import './App.scss';

// custom router
import { Router } from 'react-router';
import createBrowserHistory from 'history/createBrowserHistory'

// redux
import * as actionCreators from "./redux/store/actions/index"
import { connect } from "react-redux"

// stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faPlus, faClipboardList, faCopy, faCreditCard,
  faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faChevronRight, faChevronDown
} from '@fortawesome/free-solid-svg-icons'
import config from './config/config';

library.add(fab, faCalendarAlt, faSearch, faUser, faHeart, faShoppingCart, faBars, faUnlock, faStoreAlt, faClipboardList, faCopy, faChevronRight, faChevronDown,
  faPlus, faMinus, faTimesCircle, faThumbsUp, faClipboardCheck, faLeaf, faSeedling, faPaw, faAngleLeft, faTrashAlt, faPlusCircle, faEdit, faCreditCard, fab.faFacebookSquare)

const stripePromise = loadStripe(config.STRIPE_PKEY);

// custom history
export const history = createBrowserHistory({ basename: "/app" });

class App extends Component {
  componentDidMount() {
    if (this.props.user) {
      authenticationService.isLogged()
        .then(response => {
          this.props.getCarts();
        })
        .catch((error) => {
          this.props.logout();
        })
    }
  }

  render() {
    return (
      <Elements stripe={stripePromise}>
        <Router history={history}>
          <ScrollToTop>
            <Switch>
              <Route exact path='/' component={LandingTemplate} />
              <Route exact path='/signup' component={LandingTemplate} />
              <Route exact path='/stores/:cap' component={LandingTemplate} />

              <Route exact path='/profile' component={LandingTemplate} />
              <Route exact path='/profile/addresses' component={LandingTemplate} />
              <Route exact path='/profile/billing' component={LandingTemplate} />
              <Route exact path='/profile/password' component={LandingTemplate} />
              <Route exact path='/reset' component={LandingTemplate} />
              <Route exact path='/carts' component={LandingTemplate} />
              <Route exact path='/conditions' component={LandingTemplate} />
              <Route exact path='/cookies' component={LandingTemplate} />
              <Route exact path='/privacy' component={LandingTemplate} />
              <Route exact path='/recruiting' component={LandingTemplate} />
              <Route exact path='/registration' component={LandingTemplate} />

              <Route exact path='/store/:storeId' component={MainTemplate} />
              <Route exact path='/store/profile' component={MainTemplate} />
              <Route exact path='/store/profile/addresses' component={MainTemplate} />
              <Route exact path='/store/profile/billing' component={MainTemplate} />
              <Route exact path='/store/profile/password' component={MainTemplate} />

              <Route exact path='/cart' component={MainTemplate} />
              <Route exact path='/cart/create' component={MainTemplate} />
              <Route exact path='/cart/update' component={MainTemplate} />
              <Route exact path='/stores' component={MainTemplate} />

              <Route exact path='/search' component={MainTemplate} />
              <Route exact path='/orders' component={MainTemplate} />
              <Route exact path='/smartlist' component={MainTemplate} />
              <Route exact path='/favorites' component={MainTemplate} />
              <Route exact path='/payments' component={MainTemplate} />
              <Route exact path='/payments/stripe' component={MainTemplate} />
              <Route exact path='/product/:barcode' component={MainTemplate} />
              <Route exact path='/category' component={MainTemplate} />

              <Route exact path='/checkout' component={MainTemplate} />
              <Route exact path='/checkout/data' component={MainTemplate} />
              <Route exact path='/checkout/summary' component={MainTemplate} />
              <Route exact path='/checkout/confirm' component={MainTemplate} />

              {/* Assisted shop routes */}
              <Route exact path='/checkout/create' component={MainTemplate} />
              <Route exact path='/checkout/assisted' component={MainTemplate} />
              <Route exact path='/call' component={MainTemplate} />

            </Switch>
            <LoginPopup
              open={this.props.showLogin}
              closePopup={this.props.closePopup}
            />
          </ScrollToTop>
        </Router>
      </Elements>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    showLogin: state.user.showLogin
  };
}

const mapDispatchToProps = dispatch => {
  return {
    current: () => dispatch(actionCreators.current()),
    availability: () => dispatch(actionCreators.availability()),
    logout: () => dispatch(actionCreators.logout()),
    getFavorites: () => dispatch(actionCreators.getFavorites()),
    getSlists: () => dispatch(actionCreators.getSlists()),
    closePopup: () => dispatch(actionCreators.showLogin(false)),
    getCarts: () => dispatch(actionCreators.getCarts()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
