import config from '../../config/config';
import { handleResponse } from '../../utils/handleResponse';
import { checkResponse } from '../../utils/checkResponse';
import { reduxStore } from "../../redux/store";

export const ecommerceService = {
    deliveryModes, deliveryModeByStore, searchProducts, orders,
    fetchOrder, availability, featuredProducts, abortOrder, ordersService, fetchOperatorCart
};

function deliveryModes() {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/deliveries`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
        })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function deliveryModeByStore() {
    const storeId = reduxStore.getState().user.storeId;
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/deliveryModes?store=${storeId}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
        // .then(checkResponse)
    );
}

function searchProducts(input, storeId, page, category, onlyPromotions, onlyBranded, tag) {
    const tid = reduxStore.getState().user.tid;
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/products/${storeId}?q=${input}&p=${page}&category=${category}&onlyPromotions=${onlyPromotions}&onlyBranded=${onlyBranded}&tag=${tag}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json", "sb-tid": `${tid}` },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function orders(page) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/orders?s=${config.maxSize}&p=${page}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchOrder(orderId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/orders/${orderId}/fetch`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function availability() {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/slots/first/available`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function featuredProducts(storeId) {
    const tid = reduxStore.getState().user.tid;
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/products/featured?store=${storeId}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json", "sb-tid": `${tid}` },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function abortOrder(orderId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/cart/abort`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                orderId: orderId,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}

function ordersService() {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/orders/services/available`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function fetchOperatorCart(orderId) {
    return (
        fetch(`${config.baseUrl}/cli/ecommerce/ope/cart/review`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                orderId: orderId,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}