import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";
import { reduxStore } from "../../redux/store";


export default function categories() {
    const reducer = reduxStore.getState().user;
    return (
        fetch(`${config.baseUrl}/cli/categories`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json", "sb-tid": `${reducer.tid}`
            },
            mode: "cors",
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}