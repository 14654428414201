import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from 'react-bootstrap/Form'
import CustomInput from "../../../components/UI/Input/CustomInput";
import { Translation } from 'react-i18next';
import { Redirect } from "react-router-dom";
import BillingAddressSelect from "./BillingAddressSelect/BillingAddressSelect";
import { addressesService } from "../../ProfileContainer/ProfileAddresses/Service"
import Summary from "../Summary/Summary";
import toaster from "../../../utils/toaster";
import BillingAddressPopup from "../../ProfileContainer/ProfileAddresses/AddressPopup/BillingAddressPopup"
import { ReactComponent as Alert } from "../../../images/icn_Allert.svg";

export default class UserData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: [],
    };
  }

  componentDidMount() {
    addressesService.getBillingAddresses()
      .then(response => {
        if (response.data.length > 0) {
          this.setState({
            addresses: response.data,
          })
          this.props.selectAddress(response.data[0]);
        }
      }).catch((error) => {
        console.log(error);
      })
  }

  newAddress = (address) => {
    addressesService.newBillingAddress(address)
      .then(response => {
        this.setState({
          addresses: response.data,
        })
        this.props.selectAddress(response.data[0]);
        toaster.standard("Indirizzo di fatturazione aggiunto con successo!")
      }).catch(error => {
        console.log(error);
      })
  }

  render() {
    const { timeSlotId, invoice, selectInvoice, cart, freeze, billingAddress, billingAddressId,
      enableProductsReplacement, enableReplacement, paymentMethod,
      setPaymentMethod, isFullAssistedShop, deliveryMode, addressId } = this.props;
    const { addresses } = this.state;

    if (!timeSlotId) {
      return <Redirect to='/checkout' />;
    }

    return (
      <Translation>
        {t =>
          <div className="checkout-data">
            <div className="checkout-data-title">{t('signup.confirmData')}</div>
            <Row className="signup-row">
              <Col className="pb-3" md={6}>
                <CustomInput
                  label={"profileEmail"}
                  value={this.props.profileEmail}
                  onChange={this.props.selectProfileInfo}
                  type="email"
                />
              </Col>
              <Col className="pb-3" md={6}>
                <CustomInput
                  label={"profileMobilePhone"}
                  value={this.props.profileMobilePhone}
                  onChange={this.props.selectProfileInfo}
                  type="tel"
                />
              </Col>
            </Row>
            <Row className="signup-row">
              <Col className="pb-3" md={6}>
                <CustomInput
                  label={"profileFirstName"}
                  value={this.props.profileFirstName}
                  onChange={this.props.selectProfileInfo}
                  type="text"
                />
              </Col>
              <Col className="pb-3" md={6}>
                <CustomInput
                  label={"profileLastName"}
                  value={this.props.profileLastName}
                  onChange={this.props.selectProfileInfo}
                  type="text"
                />
              </Col>
            </Row>
            {cart && cart.deliveryMode === "AT_HOME" &&
              <Row className="signup-row">
                <Col className="pb-3">
                  <CustomInput
                    label="Indirizzo di consegna"
                    disabled={true}
                    value={cart && cart.delivery && cart.delivery.addressDescription}
                    type="email"
                  />
                </Col>
              </Row>
            }

            <hr />

            <div className="checkout-data-title">Note per la consegna</div>

            <Form.Control
              as="textarea"
              name="notes"
              className="signup-textarea checkout-textarea w-100"
              rows="4"
              placeholder="Es. “Citofofonare al 202”, “Scala B - 3°piano”"
              onChange={this.props.selectProfileInfo}
              value={this.props.notes}
            />

            <p className="small">In caso di esigenze specifiche relative all'origine del prodotto, allergeni o altro,
            vi invitiamo a scriverle nel campo Note affinché il nostro Preparatore possa scegliere
              il prodotto con le caratteristiche richieste.</p>

            <hr />

            <div className="address-container">
              <div style={{ marginBottom: "unset" }} className="checkout-data-title">Indirizzi di fatturazione</div>
              <BillingAddressPopup
                action={this.newAddress}
                title={t("addresses.new")}
                button={t("save")}
                isUpdate={false}
                trigger={
                  <span className="add-link">Aggiungi indirizzo di fatturazione</span>
                }
              />
            </div>

            {addresses && addresses.map(address => (
              <BillingAddressSelect
                key={address.id}
                address={address}
                billingAddressId={this.props.billingAddressId}
                selectAddress={this.props.selectAddress}
              />
            ))}

            {(!addresses || addresses.length === 0) &&
              <div className="checkout-data-missing">
                <Alert />
                Aggiungi indirizzo di fatturazione per poter procedere
              </div>
            }

            <div style={{ marginBottom: "unset", fontSize: "14px" }} className="checkout-data-title">
              Desideri ricevere una copia della fattura?*
            </div>

            <div className="d-flex mt-3">
              <div className="radio-button mr-3">
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input"
                    id="isEnabledInvoice"
                    name="isEnabledInvoice"
                    value={true}
                    checked={invoice === true}
                    onChange={() => selectInvoice(true)}
                  />
                  <label className="custom-control-label" htmlFor="isEnabledInvoice">
                    Si
                  </label>
                </div>
              </div>
              <div className="radio-button">
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input"
                    id="isDisableInvoice"
                    name="isDisableInvoice"
                    value={false}
                    checked={invoice === false}
                    onChange={() => selectInvoice(false)}
                  />
                  <label className="custom-control-label" htmlFor="isDisableInvoice">
                    No
                  </label>
                </div>
              </div>
            </div>

            <hr />

            <Summary
              cart={cart}
              freeze={freeze}
              timeSlotId={timeSlotId}
              billingAddress={billingAddress}
              enableProductsReplacement={enableProductsReplacement}
              enableReplacement={enableReplacement}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              billingAddressId={billingAddressId}
              invoice={invoice}

              deliveryMode={deliveryMode}
              addressId={addressId}
              isFullAssistedShop={isFullAssistedShop}
            />

          </div>
        }
      </Translation>
    );
  }
}
