import React from "react";
import { useEffect, useState } from "react";
import { ecommerceService } from "../../services/ecommerce/EcommerceService";
import { withRouter } from "react-router-dom";
import config from "../../config/config";
import AssistedPopup from "../../components/UI/Popup/AssistedPopup/AssistedPopup";
import OperatorCartItem from "./OperatorCartItem/OperatorCartItem";

import "./aided-call.styles.scss";
import toaster from "../../utils/toaster";

function AidedCall(props) {

    const [order, setOrder] = useState(null)
    const [service, setService] = useState(null)
    const [showPopup, setShowPopup] = useState(false)
    const [popupTitle, setPopupTitle] = useState("Torna più tardi")
    const [popupContent, setPopupContent] = useState("Ti ricordiamo che potrai collegarti per iniziare la tua Spesa Assistita all’orario della tua prenotazione, cliccando sull'icona degli occhialini in alto a destra e poi su LA TUA SPESA ASSISTITA PRENOTATA")
    const [operatorCart, setOperatorCart] = useState(null)
    const [isOperatorLogged, setIsOperatorLogged] = useState(false);

    useEffect(() => {
        ecommerceService.ordersService()
            .then(response => {
                if (response.data && response.data.length > 0) {
                    const order = response.data[0];
                    setOrder(order)
                    console.log(order);
                    const service = order?.cart?.service
                    setService(service);
                    // Check if service date is correct!
                    if (service.available) {
                        // get operatorCart
                        operatorUpdate();

                        // API RTC
                        const apiRTC = window.apiRTC;
                        var userAgent = new apiRTC.UserAgent({ uri: `apzkey:${config.API_RTC_KEY}` });
                        var conversation;
                        var localStream;
                        userAgent.register().then(function (session) {
                            conversation = session.getConversation(service.serviceBookingId);
                            var streamOptions = {
                                constraints: {
                                    audio: true,
                                    video: false
                                }
                            };
                            userAgent.createStream(streamOptions).then(function (stream) {
                                localStream = stream;
                                // stream.addInDiv('local', 'local-media', {}, true);
                                conversation.join().then(function () {
                                    conversation.publish(stream);
                                });
                            });

                            conversation.on('streamListChanged', function (streamInfo) {
                                if (streamInfo.listEventType === 'added' && streamInfo.isRemote === true) {
                                    conversation.subscribeToMedia(streamInfo.streamId).then(function (stream) {
                                        console.log('Successfully subscribed to remote stream: ', stream);
                                    }).catch(function (err) {
                                        console.error('Failed to subscribe to remote stream: ', err);
                                    });
                                }
                            });
                            conversation.on('streamAdded', function (stream) {
                                stream.addInDiv('remote', 'remote-media', {}, false);
                                console.log("Operatore connesso");
                                setIsOperatorLogged(true)
                            });
                            conversation.on('streamRemoved', function (stream) {
                                stream.removeFromDiv('remote', 'remote-media');
                                console.log("Operatore disconnesso");
                                setIsOperatorLogged(false)
                            });
                        });

                        // RABBIT MQ
                        var ws = new WebSocket(config.rabbitmq_ws_url);
                        const Stomp = window.Stomp;
                        var client = Stomp.over(ws);
                        console.log(client);
                        client.reconnect_delay = 1000;
                        // RabbitMQ SockJS does not support heartbeats so disable them
                        client.heartbeat.outgoing = 1;
                        client.heartbeat.incoming = 0;

                        var bindingKey = `ORDERS.${order.cart.tid}.${order.id}`

                        client.debug = onDebug;

                        client.connect(config.rabbitmq_ws_user, config.rabbitmq_password, onConnect, onError, config.rabbitmq_virtualHost);

                        function onConnect() {
                            client.subscribe("/exchange/as_preparation/" + bindingKey, onMessage);
                        }

                        function onMessage(d) {
                            console.log(new Date() + "CONNECTED:", d);
                            switch (d.body) {
                                case 'SERVICE_EXPIRED':
                                    closeSession();
                                    ws.close();
                                    setPopupTitle("Spesa assistita completata!")
                                    setPopupContent("")
                                    setShowPopup(true);

                                    break;
                                case 'OPERATOR_CART_UPDATED':
                                    operatorUpdate();
                                    break;

                                default: return null;
                            }
                        }

                        function onError(e) {
                            console.log(new Date() + "STOMP ERROR:", e);
                        }

                        function onDebug(m) {
                            // console.log(new Date() + "STOMP DEBUG:", m);
                        }

                        function operatorUpdate() {
                            ecommerceService.fetchOperatorCart(order.id)
                                .then(response => {
                                    setOperatorCart(response.data.operatorCart);
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        }

                        function closeSession() {
                            userAgent.unregister()
                                .then(() => {
                                    localStream.release();
                                    localStream.removeFromDiv("remote", "remote-media");
                                    console.log("Sessione terminata");
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        }
                    } else {
                        setShowPopup(true);
                    }
                }
            }).catch(error => {
                toaster.error(error.message)
                console.log(error);
            })
    }, []);

    return (
        <div className="aided-call">
            <div className="aided-call-title">
                Fai la tua spesa assistita
            </div>
            {service && service.available &&
                <>
                    <div id="remote" className={`${isOperatorLogged ? "remote-reverse" : ""}`}>
                        {!isOperatorLogged &&
                            <div className="remote-placeholder">
                                BENVENUTO IN QUESTA NUOVA ESPERIENZA DI SPESA. UN COMMESSO SARA’ A TUA DISPOSIZONE TRA BREVE. TU VEDRAI CON I SUOI OCCHI MA LUI NON VEDRA’ TE. BUONA SPESA
                            </div>
                        }
                        <div className="aided-call-cart">
                            <div className="aided-call-cart-title">Carrello</div>
                            <div className="aided-cart-scrollable">
                                {operatorCart && operatorCart.items && operatorCart.items.map(item => (
                                    <div key={item.id}>
                                        <OperatorCartItem item={item} />
                                    </div>
                                ))}
                            </div>
                            {operatorCart &&
                                <div className="aided-call-cart-subtotal">
                                    <span>Totale</span>
                                    <span>{`${operatorCart.total && operatorCart.total.toFixed(2)} \u20AC`}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <button className="cta" onClick={() => window.location.reload()}>
                            <span>Ricarica pagina</span>
                        </button>
                    </div>
                </>
            }
            {showPopup &&
                <AssistedPopup
                    action={() => props.history.goBack()}
                    title={popupTitle}
                    onClose={() => props.history.goBack()}
                    closeAction={true}
                    content={popupContent}
                />
            }
        </div>
    );
}

export default withRouter(AidedCall);
