export const CART = "CART"
export const CARTS = "CARTS"
export const AVAILABILITY = "AVAILABILITY"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const IS_LOGGED = "IS_LOGGED"
export const USER_UPDATE = "USER_UPDATE"
export const USER_DELETE = "USER_DELETE"
export const FAVORITES = "FAVORITES"
export const ADD_FAVORITE = "ADD_FAVORITE"
export const DELETE_FAVORITE = "DELETE_FAVORITE"
export const COORDINATE = "COORDINATE"
export const SLISTS = "SLISTS"
export const SLISTS_ITEM_CREATE = "SLISTS_ITEM_CREATE"
export const SLISTS_ITEM_CREATE_CUSTOM = "SLISTS_ITEM_CREATE_CUSTOM"
export const SLISTS_ITEM_DELETE = "SLISTS_ITEM_DELETE"
export const SLISTS_ITEM_UPDATE = "SLISTS_ITEM_UPDATE"
export const FETCH_SLIST = "FETCH_SLIST"
export const CREATE_SLIST = "CREATE_SLIST"
export const DELETE_SLIST = "DELETE_SLIST"
export const RENAME_SLIST = "RENAME_SLIST"
export const COPY_SLIST = "COPY_SLIST"
export const SET_PRODUCT_TO_ADD = "SET_PRODUCT_TO_ADD"
export const ADD_PRODUCT_TO_ADD = "ADD_PRODUCT_TO_ADD"
export const OVERLAY = "OVERLAY"
export const CHECKOUT_DAY_INFO = "CHECKOUT_DAY_INFO"
export const FREEZE_CHECK = "FREEZE_CHECK"
export const CLOSE_FREEZE_POPUP = "CLOSE_FREEZE_POPUP"
export const SHOW_LOGIN = "SHOW_LOGIN"
export const SET_CAP = "SET_CAP"
export const SET_STORE = "SET_STORE"
export const AIDED_SHOP = "AIDED_SHOP"
export const FULL_AIDED_SHOP = "FULL_AIDED_SHOP"
export const RESET_AIDED_SHOP = "RESET_AIDED_SHOP"

