import React from "react";
import { Route, Switch } from 'react-router-dom';
import Footer from "../Footer/Footer";
import LandingHeader from "../Header/LandingHeader/LandingHeader";
import LandingPage from "../../../features/LandingPage/LandingPage";
import Signup from "../../../features/Signup/Signup";
import SearchStores from "../../../features/SearchStores/SearchStores";
import PasswordReset from "../../../features/PasswordReset/PasswordReset";
import ProfileContainer from "../../../features/ProfileContainer/ProfileContainer";
import Carts from "../../../features/Carts/Carts";
import TermsAndCondition from "../Footer/FooterAttachments/TermsAndCondition";
import PolicyCookie from "../Footer/FooterAttachments/PolicyCookie";
import PrivacyPolicy from "../Footer/FooterAttachments/PrivacyPolicy";
import Recruiting from "../../../features/Recruiting/Recruiting";
import StoresSignup from "../../../features/StoresSignup/StoresSignup";
class LandingTemplate extends React.Component {
    render() {
        return (
            <div className="main-container">
                <LandingHeader />
                <div className="content">
                    <Switch>
                        <Route exact path='/' component={LandingPage} />
                        <Route exact path='/signup' component={Signup} />
                        <Route exact path='/stores/:cap' component={SearchStores} />
                        
                        <Route exact path='/profile' component={ProfileContainer} />
                        <Route exact path='/profile/addresses' component={ProfileContainer} />
                        <Route exact path='/profile/billing' component={ProfileContainer} />
                        <Route exact path='/profile/password' component={ProfileContainer} />
                        <Route exact path='/reset' component={PasswordReset} />
                        <Route exact path='/carts' component={Carts} />
                        <Route exact path='/conditions' component={TermsAndCondition} />
                        <Route exact path='/cookies' component={PolicyCookie} />
                        <Route exact path='/privacy' component={PrivacyPolicy} />
                        <Route exact path='/recruiting' component={Recruiting} />
                        <Route exact path='/registration' component={StoresSignup} />
                    </Switch>
                </div>
                <Footer />
            </div>
        );
    }
}

export default LandingTemplate;